import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    IconButton,
    Tooltip,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import ModalPrompt from '../../../CommonComponents/ModalPrompt.js'
import asset_inf from '../../../services/assets_inf.js'

const DeleteDispensePoint = ({accountId, assetId, disabled, selectedData, fetchData = ()=>{} }) => {
    const { t, i18n } = useTranslation();

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDeleteDispensePoint = () => {
        setOpenDialog(true);
        //console.log(selectedData.name);
    };

    const handleClose = async () => {
        setOpenDialog(false);
    };

    const handleAction = async () => {
        try{
            const { status, data } = await asset_inf.deleteDispensePoint(
                accountId,
                assetId,
                selectedData.id
            );
            
            const { error } = data;

            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            fetchData();
            handleClose();
        }catch (error) {
            console.error(error);
        }
    };
    //console.log(openDialog ? "open"  + selectedData.name : "close" + selectedData.name);

    return (
    <>
        <Tooltip title={t('deleteDispensePoint')}>
            <IconButton disabled={disabled} edge="end" aria-label={"delete"+selectedData.id} onClick={() => handleOpenDeleteDispensePoint()}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
        {openDialog && <ModalPrompt title={t('delete') + ' ' + t('asset') + ' ' + selectedData.name + '?'}
            content={t("deleteWarning")}
            action={t("delete")}
            handleAction={handleAction}
            handleCancel={handleClose}
            openModal={openDialog}
            useConfirm={true} />  }
    </>
    );
}

export default DeleteDispensePoint;
