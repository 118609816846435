import React, { useState, useRef, useEffect } from 'react';
import {
    useHistory,
    Link as RouterLink,
    useParams
  } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Skeleton,
    Stack,
    TextField,
    Toolbar,
    Tooltip,
    FormControl,
    Typography,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid } from '@mui/x-data-grid';

import useSessionstorage from "@rooks/use-sessionstorage";

import { assets_inf } from "../../services/assets_inf";
import { UNIT_SETTINGS } from '../../data';
import { StyledPageTitle, StyledPageLabel, GrowDiv, StyledLink } from '../../styledComponentsCommon';
import { set } from 'date-fns';


const DeviceHistory = (props) => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const history = useHistory();

    const paramAssetName = params.assetName === undefined ? null : params.assetName;
    const paramDpName = params.dpName === undefined ? null : params.dpName;
    const {value: accountInfo} = useSessionstorage("accountInfo",{})

    const [dataReady, setDataReady] = useState(false);
    
    const [assetName, setAssetName] = useState(paramAssetName);
    const [assetId, setAssetId] = useState();
    const [dpName, setDpName] = useState(paramDpName);
    const [dpId, setDpId] = useState();
    
    const [dpHistoryData, setDpHistoryData] = useState({})
    const [rowData, setRowData] = useState({});
    const [displayUnits, setDisplayUnits] = useState('cu_in');
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'ReadingTime_UTC',
          sort: 'desc',
        },
      ]);    
    const dpHistoryColumns = [
        {
            field: 'ReadingTime_UTC', 
            headerName: t('lastReading'),
            type: 'dateTime', 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 190
        },
        {
            field: 'ServerTime_UTC', 
            headerName: t('lastReported'),
            type: 'dateTime', 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 190
        },
        {
            field: 'amount_disp', 
            headerName: t('dispensed'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'fluid_name', 
            headerName: t('fluid'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 190
        },
        {
            field: 'zerk_uid', 
            headerName: t('zerk'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 190
        },
    ];

    const getDpHistory = async () => {
        let monthInterval = 3;
        try{
            const { status, error, dpHistory } = await assets_inf.getDpHistory(
                accountInfo.accountId,
                assetId,
                dpId,
                monthInterval
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("Device already assigned")){
                    alert(t("deviceIsAlreadyAssigned"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }
            setDpHistoryData(dpHistory);
        }catch (error) {
                console.error(error);
        }
        setDataReady(true);
    };

    const getDpId = async () => {
        try {
            const { status, error, asset_id, dp_id } = await assets_inf.getDpId(
                accountInfo.accountId,
                assetName,
                dpName
            );

            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("Dispense Point does not exist")){
                    alert(t("dispensePointDoesNoteExist"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }

            setAssetId(asset_id);
            setDpId(dp_id);

        } catch (error) {
            console.error(error);
        }
    };

    const handleExit = () => {
        console.log("handleExit");
        //let url = { pathname: ('/accounts/').concat(accountInfo.accountName)};    
        let url = { pathname: '/' };    
        history.push(url);
    };

    useEffect(() => {
        getDpId();
    }, [assetName, dpName]);

    useEffect(() => {
        if (dpId && assetId) {
            getDpHistory();
        }
    }, [dpId, assetId]);

    useEffect (() => {
        var i;
        var l_rows = dpHistoryData;
        for (i=0; i<l_rows.length; i++){
            if (l_rows[i].reading_time){
                l_rows[i].ReadingTime_UTC = new Date(l_rows[i].reading_time);
            }
            if (l_rows[i].server_time){
                l_rows[i].ServerTime_UTC = new Date(l_rows[i].server_time);
            }
            if (l_rows[i].amount){
                l_rows[i].amount_disp = l_rows[i].amount + ' ' + t(UNIT_SETTINGS[displayUnits].label);
            }
        }
        setRowData(l_rows);
    }, [dpHistoryData]);

    return <>
        <Grid item container 
            direction='column'
            justifyContent='flex-start'
            //alignItems='stretch'
            >
            <Grid item xs>
                <Toolbar>
                    <Tooltip title={t("goBack")}>
                        <IconButton color='primary' onClick={handleExit} size="large">
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>

                    <Button onClick={handleExit} color="primary">
                        {t("goBack")}
                    </Button>
                    <StyledPageTitle variant="h5"> {t('dispensePointHistory')}</StyledPageTitle>
                    <Tooltip title={'refresh'}>
                        <IconButton color='primary' onClick={getDpHistory}>
                            <RefreshIcon/>
                        </IconButton>
                    </Tooltip>
                    <GrowDiv/>
                </Toolbar>
            </Grid>
            <Grid item xs>
                
            </Grid>
            <Divider />
            <Toolbar>
                <StyledPageLabel>{"{title}:".replace("{title}",t("dispensePoint"))}</StyledPageLabel>
                <Box sx={{marginRight: "6px"}}>
                    <StyledLink
                        component={RouterLink}
                        to={'/assets/' + assetName + '/edit/'}>
                        {assetName}
                    </StyledLink>
                </Box>
                <Typography sx={{ fontWeight: 'bold'}}>{" / {dpName}".replace("{dpName}",dpName)}</Typography>
            </Toolbar>
            <Grid item xs >
                <Box 
                    sx={{
                            padding: "10px",
                            width: '100%',
                            margin: '0 auto',
                            maxWidth: '1200px',
                            height: '75vh',
                            '& .default-table-theme--header': {
                                backgroundColor: '#555555',
                                color: '#FFFFFF'
                            },
                        }}
                    >
                    {dataReady ? <DataGrid  sx={{
                            '& .MuiDataGrid-sortIcon':{
                                color: '#fff'
                            },}}
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        pageSize={100}
                        rows={rowData}
                        columns={dpHistoryColumns} />
                    :
                    <Stack spacing={1}><Skeleton variant="rectangular" width={'100%'}/><Skeleton variant="rectangular" width={'100%'} height={500}/></Stack>}
                </Box>
            </Grid>
        </Grid>
    </>;
}

export default DeviceHistory;