/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useParams
} from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify';
import { CssBaseline, Typography } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import useSessionstorage from '@rooks/use-sessionstorage';
// Graco dependencies
import authConfig from './auth-config.js';
import GAuth from './GAuth/GAuth.jsx'
import Header from './Header/Header.jsx';
import HelpPage from './HelpPage/HelpPage.jsx';
import AccountCreate from './AccountPage/AccountCreate/AccountCreate';
import NoAccountPage from './AccountPage/NoAccountPage/NoAccountPage';
import ProfilePage from './ProfilePage/ProfilePage.jsx';
import AccountPage from './AccountPage/AccountPage.jsx';
import ManageAsset from './MainPage/ManageAsset/ManageAsset.jsx';
import AccountFluids from './AccountPage/AccountFluids/AccountFluids.jsx';
import DeviceHistory from './MainPage/DeviceHistory/DeviceHistory.jsx';
import DispensePointHistory from './MainPage/DispensePointHistory/DispensePointHistory.jsx';
//import DemoInvite from './DemoInvite/DemoInvite.jsx';
import Theme from './Theme.js';
import MainPage from './MainPage/MainPage.jsx';
import SessionTimeout from './SessionTimeout/SessionTimeout.js';
import { host } from "./data";
//import logoPulse from './Header/assets/new_logo_black.png';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { accounts } from './services/accounts';
import { LaptopWindows } from '@mui/icons-material';

//const logo = logoPulse;
const logo = undefined;

// Set AWS Cognito user pool credentials
Amplify.configure({
  Auth: {
    region: authConfig.region,
    userPoolId: authConfig.userPoolId,
    userPoolWebClientId: authConfig.userPoolWebClientId
  }
});

const useStyles = makeStyles((theme) => ({
  root: { height: '100vh', display: 'block' },
  content: { minHeight: 'calc(100vh - 64px)' },
}));

const RootDiv = styled.div(props => ({
  height: '100vh', display: 'block'
}));

const ContentDiv = styled.div(props => ({
  minHeight: 'calc(100vh - 64px)'
}));

// Page router directs the app to the correct page and defines path parameters.
const PageRouter = ({ accountInfo, fetchAccountInformation, changeLanguage }) => {
  let data = useLocation();
  //console.log(accountInfo);
  return (
    <Switch>
      <Route exact path='/devices/:deviceId/'>
        <DeviceHistory/>
      </Route>
      <Route exact path='/assets/:assetName/dps/:dpName'>
        <DispensePointHistory/>
      </Route>
      <Route exact path='/assets/:assetName/edit'>
        <ManageAsset />
      </Route>
      <Route exact path='/assets/new'>
        <ManageAsset />
      </Route>
      <Route exact path='/accounts//'><NoAccountPage/></Route>
      <Route exact path='/accounts/:accountName/fluids'> <AccountFluids  /></Route>
      <Route path='/accounts/'> <AccountPage fetchAccountInformation={fetchAccountInformation}/> </Route>
      <Route path='/profile'> <ProfilePage fetchAccountInformation={fetchAccountInformation} changeLanguage={changeLanguage} /> </Route>
      <Route path='/help' component={HelpPage} changeLanguage={changeLanguage} />
      <Route path='/'> {accountInfo.accountName ?  <MainPage accountInfo={accountInfo}/>  : <NoAccountPage/>} </Route>
    </Switch>
  );
}

// The main App. Loaded by index.js, the whole app trees from here.
export default function App() {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  const cookies = new Cookies();
  const { set: setSessionstorageAccountInfo } = useSessionstorage("accountInfo", {});
  // Create state for the current account and account list.
  const [signedIn, setSignedIn] = React.useState(false);
  const [account, setAccount] = React.useState({
    accountName: '',
    isAccountEditor: true,
    accountId: ''
  });
  const [accountsList, setAccountsList] = React.useState([]);
  const [accountsNavDict, setAccountsNavDict] = React.useState({});
  const [accountsFetched, setAccountsfetched] = React.useState(false);
  const authTimeoutMinutes = 30; //auto sign out timeout
  const authWarningMinutes = 5; //Minutes remaining that popup occurs.

  const changeLanguage = (lng) => {
    console.log('Changing application language: ' + lng);
    i18n.changeLanguage(lng);
  }
  
  // Handle app events
  const handleAuthStateChange = (state) => {
    if (state === 'signedIn') { setSignedIn(true); }
    else { setSignedIn(false) };
  };

  // Handle account change event. Passed to the nav menu in Header.
  const handleAccountChange = (event) => {
    setAccount({
      accountName: event,
      isAccountEditor: accountsNavDict[event][1],
      accountId: accountsNavDict[event][0]
    });
    let url = '/';
    history.push(url);
  };
  
  // Get the account info. Runs once when the component mounts.
  const fetchAccountInformation = async (targetAccount, navigate, navUrl) => {
    setAccountsfetched(false);
    try {

      // response.getSession((err, session) => {
      //   if (err) {
      //     console.log(err);
      //     return;
      //   }

      console.log("Fetching acounts...")

      var { status, data } = await accounts.getAccounts();
      var { error, user_accounts } = data;
      
      if (error !== 'OK' && (status !== 200 || status !== 404)) {
          throw new Error(error);
      }

      if(user_accounts.default.accountName.trim() === "" && user_accounts.accountsList.length === 0) {

      }
      else{
        setAccountsList(user_accounts.accountsList);
        setAccountsNavDict(user_accounts.accountsNavDict);

        if (targetAccount === "default") {
          setAccount({
            accountName: user_accounts.default.accountName,
            isAccountEditor: user_accounts.default.isAccountEditor,
            accountId: user_accounts.default.accountId
          });
          let url = "/";
          history.push(url);
        }
        else if (targetAccount !== undefined && user_accounts.accountsNavDict[targetAccount] !== undefined){
          setAccount({
              accountName: targetAccount,
              isAccountEditor: user_accounts.accountsNavDict[targetAccount][1],
              accountId: user_accounts.accountsNavDict[targetAccount][0]
            });

            if (navigate === true || navigate === undefined) {
              let url = "/";
              if (navUrl !== undefined) {
                url = navUrl;
              }
              history.push(url);
          }
        }
        else if (params.accountName !== undefined &&
          params.accountName !== data.default.accountName &&
          user_accounts.accountsNavDict[params.accountName] !== undefined) {
          // Todo: Redirect to a "page does not exist or no access page" on an improper accountName in the url.
          setAccount({
            accountName: params.accountName,
            isAccountEditor: user_accounts.accountsNavDict[params.accountName][1],
            accountId: user_accounts.accountsNavDict[params.accountName][0]
          });
        }
        else {
          if (user_accounts.default.accountId === "") {
            var lAccountName = user_accounts.accountList[0];
            setAccount({
              accountName: lAccountName,
              isAccountEditor: user_accounts.accountsNavDict[lAccountName][1],
              accountId: user_accounts.accountsNavDict[lAccountName][0]
            });
          }
          else {
            setAccount({
              accountName: user_accounts.default.accountName,
              isAccountEditor: user_accounts.default.isAccountEditor,
              accountId: user_accounts.default.accountId
            });
          }
        }
      }
      setAccountsfetched(true);
      console.log("Accounts fetched.")     
    } catch (e) {
      console.log(e);
      setAccountsfetched(true);
    }
  };

  function fetchUserLanguageSetting() {
    Auth.currentAuthenticatedUser()
      .then(response => {
        try {
          // response.getSession((err, session) => {
          //   if (err) {
          //     console.log(err);
          //     return;
          //   }

          //   console.log("Fetching user language setting...");

          //   fetch(host + '/user/settings', {
          //     method: 'GET',
          //     headers: {
          //       'Content-Type': 'application/json',
          //       'Authorization': session.getIdToken().jwtToken
          //     }
          //   }).then(response => response.json())
          //     .then(data => {
          //       if (data['lang'].lang !== undefined) {
          //         changeLanguage(data['lang'].lang)
          //         console.log("User language setting fetched.");
          //       } else {
          //         if (cookies.get('login-lang') !== undefined) {
          //           changeLanguage(cookies.get('login-lang'));
          //         }
          //       }
          //     });
          // });
        } catch (e) {
          console.log(e);
        }
      })
  };

  React.useEffect(() => {
    if (signedIn) {
      fetchUserLanguageSetting();
      fetchAccountInformation();
    }
  }, [signedIn]);

  React.useEffect(() => {
    setSessionstorageAccountInfo(account);    
  }, [account])

  //DISABLE MOUSE WHEEL FROM EFFECTING NUMBER TYPE
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });
  //console.log(accountsList);
  // Setup the header and space for the page content through page router.
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        {signedIn ?
          <>
            <Header accountInfo={account} accounts={accountsList} accountNavigationHandler={(event) => handleAccountChange(event)} changeLanguage={changeLanguage} />
            <ContentDiv>
              {accountsFetched ?
                <PageRouter accountInfo={account} fetchAccountInformation={fetchAccountInformation} changeLanguage={changeLanguage} /> :
                <Typography align="center">{t("loadingAccountData")}...</Typography>
              }
            </ContentDiv>
          </>
          : <RootDiv>
            <GAuth appTitle='Pulse Greaser'
              onAuthStateChange={handleAuthStateChange}
              logo={logo}
              changeLanguage={changeLanguage} />
          </RootDiv>
        }
        <div>
          <SessionTimeout signedIn={signedIn} maxTimeoutMinutes={authTimeoutMinutes} warningTimeoutMinutes={authWarningMinutes} />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}