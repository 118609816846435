import React, { useRef, useState } from "react";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import useSessionstorage from '@rooks/use-sessionstorage';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormGroup,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack } from '@mui/icons-material';
import { host } from "../../data";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { countries } from "../../data";
import { isValidEmail } from '../../helpers/isValidEmail';
import { useTranslation } from 'react-i18next';
import { accounts } from '../../services/accounts';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
    },
    textInput: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    clickButtons: {
        marginLeft: theme.spacing(1),
    }
}));

export default function AccountCreate(props) {
    const { t } = useTranslation();
    let history = useHistory();
    const classes = useStyles();
    const [accountName, setAccountName] = useState({ name: '' });
    const account_name = useRef("");
    const account_address_line_1 = useRef("");
    const [accountAddressLine1, setAccountAddressLine1] = useState({ address: '' });
    const account_contact = useRef("");
    const [accountContactName, setAccountContactName] = useState({ name: "" });
    const account_phone = useRef("");
    const [accountContactPhone, setAccountContactPhone] = useState({ phone: "" });
    const account_city = useRef("");
    const [accountCity, setAccountCity] = useState({ city: '' });
    const account_postal = useRef("");
    const [accountPostal, setAccountPostal] = useState({ postal: '' });
    const account_email = useRef("");
    const [accountContactEmail, setAccountContactEmail] = useState({ email: "" });
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [defCheck, setDefCheck] = useState(false);
    const { value: accountInfo } = useSessionstorage('accountInfo', {});

    function isValidEnteredEmail(value) {
        if (isValidEmail(value))
            return (true);

        alert(t("youHaveEnteredAnInvalidEmail"));
        return (false);
    };

    function isValidPhoneNumber(value) {
        if (((((((((((((((((((((((((((((((((((((((((/^\d{7,}$/)))))))))))))))))))))))))))))))))))))))).test(value.replace(/[\s()+\-\.]|ext/gi, ''))) {
            return (true);
        }

        alert(t("youHaveEnteredAnInvalidPhone"));
        return (false);
    }

    const handleCheck = (event) => {
        setDefCheck(event.target.checked);
    };

    React.useEffect(() => {
        if (!props.accountEdit) {
            setAccountName({ name: "" });
            setAccountAddressLine1({ address: "" });
            setAccountCity({ city: "" });
            setAccountPostal({ postal: "" });
            setAccountContactEmail({ email: "" });
            setAccountContactName({ name: "" });
            setAccountContactPhone({ phone: "" });
            setCountry("");
            setRegion("");

            try {
                (async () => {
                    let user = await Auth.currentAuthenticatedUser();
                    setAccountContactEmail({ email: user.attributes.email });
                })();
            } catch (e) {
                console.log(e);
            }
        } else {
            setAccountName({ name: accountInfo.accountName });
            try {
                const { status, data } = accounts.getAccountInfo(
                    accountInfo.accountId
                );

                const { error, accountDetails } = data;

                if (status === 404) {
                    return;
                }

                if (error !== 'OK' && status !== 200) {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }

                setAccountAddressLine1({ address: accountDetails.address });
                setAccountCity({ city: accountDetails.city });
                setAccountPostal({ postal: accountDetails.postal });
                setAccountContactEmail({ email: accountDetails.contact_email });
                setAccountContactName({ name: accountDetails.contact_name });
                setAccountContactPhone({ phone: accountDetails.contact_phone });
                setCountry(accountDetails.country);
                setRegion(accountDetails.state);
            } catch (error) {
                console.error(error);
            }
        }
    }, [props.accountEdit]);

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
    };

    const handleChangeRegion = (event) => {
        setRegion(event.target.value);
    };

    function validateForm() {
        var ret = 1;

        if (country === "") {
            console.log("country is empty");
            alert(t("countryInvalid"));
            ret = 0;
        }

        if (region === "") {
            alert(t("stateRegionInvalid"));
            ret = 0;
        }

        if (account_name.current.value === "") {
            alert(t("nameInvalid"));
            ret = 0;
        }

        if (account_contact.current.value === "") {
            alert("Contact Invalid");
            ret = 0;
        }

        if (account_address_line_1.current.value === "") {
            alert(t("addressInvalid"));
            ret = 0;
        }

        if (account_city.current.value === "") {
            alert(t("cityInvalid"));
            ret = 0;
        }

        if (account_postal.current.value === "") {
            alert("Zip/Postal Code Invalid");
            ret = 0;
        }

        return ret;
    };

    const handleClick = async () => {
        if (isValidEnteredEmail(account_email.current.value) && isValidPhoneNumber(account_phone.current.value) && validateForm() === 1) {
            try {
                var isDef = 0;
                    if (defCheck) {
                        isDef = 1;
                    }
                var temp_account_name = account_name.current.value;
                var body = 
                    {
                        "Name": account_name.current.value,
                        "ContactName": account_contact.current.value,
                        "Email": account_email.current.value,
                        "ContactPhone": account_phone.current.value,
                        "AddressLine1": account_address_line_1.current.value,
                        "Country": country,
                        "City": account_city.current.value,
                        "State": region,
                        "PostalCode": account_postal.current.value,
                        "isDefault": isDef
                    };
                var temp_account_name = account_name.current.value;
                if (!props.accountEdit) {
                    try {
                        const { status, data } = await accounts.createAccount(
                            body.Name,
                            body.ContactName,
                            body.Email,
                            body.ContactPhone,
                            body.AddressLine1,
                            body.Country,
                            body.City,
                            body.State,
                            body.PostalCode,
                            body.isDefault
                        );
        
                        const { error, accountDetails } = data;
        
                        if (status === 404) {
                            return;
                        }
        
                        if (error !== 'OK' && status !== 200) {
                            alert(t("sorryUnableToRetrieve"));
                            throw new Error(error);
                        }
        
                        props.fetchAccountInformation(temp_account_name, true); //Fetch information and navigate
                    } catch (error) {
                        console.error(error);
                    }
                    
                } else {
                    try {
                        const { status, data } = await accounts.updateAccount(
                            accountInfo.accountId,
                            body.Name,
                            body.ContactName,
                            body.Email,
                            body.ContactPhone,
                            body.AddressLine1,
                            body.Country,
                            body.City,
                            body.State,
                            body.PostalCode,
                            body.isDefault  
                        );
        
                        const { error, accountDetails } = data;
        
                        if (status === 404) {
                            return;
                        }
        
                        if (error !== 'OK' && status !== 200) {
                            alert(t("sorryUnableToRetrieve"));
                            throw new Error(error);
                        }
                        props.fetchAccountInformation(temp_account_name, true); //navigate after fetching information
                        
                    } catch (error) {
                        console.error(error);
                    }
                }
            } catch (e) {
                console.log(e);
            };
        }
    };

    return (
        <Grid
            container
            alignItems='stretch'
            direction='column'
            justifyContent='flex-start'
            className={classes.content}>
            {!props.accountEdit
                ? <div>
                    {props.firstAccount
                        ? <Grid item xs>
                            <Box border={2} borderColor='error.main'>
                                <Typography align='center' color='error'>
                                    {t("youAreNotCurrentlyConnected")}
                                </Typography>
                            </Box>
                        </Grid>
                        : <React.Fragment>
                            <Grid item xs>
                                <Toolbar>
                                    <Tooltip title={t("goHome")}>
                                        <IconButton color='primary' component={RouterLink} to='/' size="large">
                                            <ArrowBack />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography variant='h5'
                                        color='textPrimary'>
                                        {t("back")}
                                    </Typography>
                                </Toolbar>
                            </Grid>
                            <Grid item xs><Divider /></Grid>
                            {false && <Grid item xs>
                                <Box border={2} borderColor='error.main'>
                                    <Typography align='center' color='error'>
                                        {t("warningByCreatingANewAccount")}
                                    </Typography>
                                </Box>
                            </Grid>}
                        </React.Fragment>}
                </div>
                : null
            }

            <Grid item xs>
                <form>
                    {!props.accountEdit
                        ?
                        <Typography>
                            {t("createAccount")}
                        </Typography>
                        : <Typography>
                            {t("editAccount")}
                        </Typography>}
                    <FormGroup>
                        <TextField id='account-name'
                            onChange={(e) => {
                                const regex = /^([ a-zA-Z0-9\-]){0,40}$/i;
                                if (regex.test(e.target.value)) {
                                    setAccountName({ name: e.target.value });
                                }
                            }}
                            value={accountName.name}
                            inputRef={account_name}
                            label={t("accountName")}
                            variant='filled'
                            className={classes.textInput} />

                        <TextField id='account-address-line-1'
                            onChange={e => setAccountAddressLine1({ address: e.target.value })}
                            value={accountAddressLine1.address}
                            inputRef={account_address_line_1}
                            label={t("addressLine")}
                            variant='filled'
                            className={classes.textInput} />

                        <TextField id='account-city'
                            onChange={e => setAccountCity({ city: e.target.value })}
                            value={accountCity.city}
                            inputRef={account_city}
                            label={t("city")}
                            variant='filled'
                            className={classes.textInput} />

                        <TextField id='account-zip'
                            onChange={e => setAccountPostal({ postal: e.target.value })}
                            value={accountPostal.postal}
                            inputRef={account_postal}
                            label={t("zipPostalCode")}
                            variant='filled'
                            className={classes.textInput} />

                        <FormControl className={classes.formControl}>
                            <InputLabel id="country-select-label">{t("country")}</InputLabel>
                            <Select
                                labelId="country-select-label"
                                id="country-select"
                                value={country}
                                onChange={handleChangeCountry} >
                                {countries.map((country) => (
                                    <MenuItem
                                        value={country.countryName}
                                        key={country.countryShortCode}
                                    >
                                        {t(country.translationKey)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel id="region-select-label">{t("stateRegionInvalid")}</InputLabel>
                            <Select
                                labelId="region-select-label"
                                id="region-select"
                                value={region}
                                onChange={handleChangeRegion}
                                disabled={!country} >
                                {country
                                    ? countries
                                        .find(({ countryName }) => countryName === country)
                                        .regions.map((region) => (
                                            <MenuItem value={region.name} key={region.shortCode}>
                                                {t(region.translationKey)}
                                            </MenuItem>
                                        )) : []}
                            </Select>
                        </FormControl>
                    </FormGroup>
                    <Typography>
                        {t("accountContact")}
                    </Typography>
                    <FormGroup>
                        <TextField id='account-contact'
                            onChange={e => setAccountContactName({ name: e.target.value })}
                            value={accountContactName.name}
                            inputRef={account_contact}
                            label={t("name")}
                            variant='filled'
                            className={classes.textInput} />

                        <TextField id='account-phone'
                            onChange={e => setAccountContactPhone({ phone: e.target.value })}
                            value={accountContactPhone.phone}
                            inputRef={account_phone}
                            label={t("phoneNumber")}
                            variant='filled'
                            className={classes.textInput} />

                        <TextField id='account-email'
                            onChange={e => setAccountContactEmail({ email: e.target.value })}
                            value={accountContactEmail.email}
                            inputRef={account_email}
                            label={t("email")}
                            variant='filled'
                            className={classes.textInput} />
                        {(!props.firstAccount) && <FormControlLabel
                            control={
                                <Checkbox className={classes.clickButtons}
                                    checked={defCheck}
                                    onChange={handleCheck}
                                    name="defIndicator"
                                    color="primary"
                                />
                            }
                            label="default account"
                        />}
                    </FormGroup>
                </form>
            </Grid>

            <Grid className={classes.clickButtons} item xs>
                <Button variant="contained" color="primary" onClick={handleClick}>
                    {t("save")}
                </Button>

                <Button color='primary' component={RouterLink} to='/'>
                    {t("cancel")}
                </Button>
            </Grid>
        </Grid>
    );
}