import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './i18n';
import App from './App.jsx';

// Page router is required to pass the orgName value to App as a param
const PageRouter = () => {
  return (
    <Switch>
      <Route path='/orgs/:orgName'> <App /></Route>
      <Route path='/'> <App /></Route>
    </Switch>
  );
}

// Start of the app. Browser router allows navigation by url.
const rootElement = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <PageRouter />
    </BrowserRouter>
  </React.StrictMode>,
  rootElement
);

// Service worker can be registered to allow faster loading and offline content
serviceWorker.unregister();
