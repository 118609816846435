import React, {useState} from 'react';
import {
  Link as RouterLink,
  useHistory
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useTheme } from '@emotion/react';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HistoryIcon from '@mui/icons-material/History';
import { DataGrid } from '@mui/x-data-grid';
import { visuallyHidden } from '@mui/utils';



import { convertDispenseUnits } from '../../helpers/convertDispenseUnits';
import { DISPENSE_TOLERANCE, STATUSES, UNIT_SETTINGS } from '../../data';
import { StyledLink } from '../../styledComponentsCommon';
import { calculateGreaseError, calculateIntervalError, calculateDispenseAmountWarning } from '../../helpers/calculateErrors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(4n+4)': {
    backgroundColor: theme.palette.background.dark,
  },
  '&:nth-of-type(4n+3)': {
    backgroundColor: theme.palette.background.dark,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '&.MuiTableSortLabel-root': {
    color: theme.palette.primary.contrastText,
    '& .MuiTableSortLabel-icon': {
      color: theme.palette.primary.contrastText,
  },
  },
  '&.MuiTableSortLabel-root:hover': {
      color: theme.palette.primary.contrastText,
  },
  '&.Mui-active': {
      color: theme.palette.primary.contrastText,
  },
}));

const FluidWithStatus = (params) => {
  const { t } = useTranslation();
  const row = params.row;
  const status = calculateGreaseError(row.fluid_id, row.dispense_fluid_id);
  return(
    <Tooltip title={t("greaseExpect").replace("{fluid_name}", row.fluid_name)}>
      {(STATUSES.wrongGrease === status) ? <Chip sx={{
          width:"120px"
        }}
        color='warning'
        label={row.dispense_fluid_name}/>
        : <Typography>{row.fluid_name}</Typography>}
    </Tooltip>
  );
};

const RenderHistoryLink = (params) => {
  const row = params.row ? params.row : params;
  const { t } = useTranslation();
  //const history = useHistory();
  //let url = {pathname: '/assets/{assetName}/dps/{dpName}/'.replace("{assetName}", params.row.asset_name).replace("dpName", params.row.name)};
  return(
    <Tooltip title={t('showHistory')}>
      <Link component={RouterLink}
        to={'/assets/{assetName}/dps/{dpName}/'.replace("{assetName}", row.asset_name).replace("{dpName}", row.dp_name)}>
        <HistoryIcon/>
      </Link>
    </Tooltip>
  )
};

const DaysWithStatus = (params) => {
  const row = params.row ? params.row : params;
  const { t } = useTranslation();
  const minimumInterval = 0;
  const maximumInterval = row.interval ? (row.interval/24) : undefined;
  var status=calculateIntervalError(maximumInterval, row.daysSince);
  
  //"Expected between {minimumInterval} and {maximumInterval} days."
  return(
    <Tooltip title={t("daysIntervalMaximumExpectation").replace("{minimumInterval}", minimumInterval.toFixed(1)).replace("{maximumInterval}", maximumInterval.toFixed(1))}>
      {status === STATUSES.overdue ? <Chip sx={{
        width:"80px"
      }}
        color='error'
          label={row.daysSince}/>
        : <Typography>{row.daysSince}</Typography>
      }
    </Tooltip>
  )
};

const DispensedWithStatus = (params) => {
  const { t } = useTranslation();
  const targetDispense = parseFloat(params.row.dispense);
  //const l_amount = params.row.amount ? parseFloat(params.row.amount) : undefined;
  const l_amount = params.row.amount_converted ? params.row.amount_converted : undefined;
  const displayAmount = l_amount ? "{amt} {unit}".replace("{amt}",l_amount).replace("{unit}", t(UNIT_SETTINGS[params.row.volumeUnit].label)) : undefined;
  var status = STATUSES.ok;
  if (undefined === l_amount){
    status = STATUSES.ok;
  }
  else {
    status = calculateDispenseAmountWarning(targetDispense, l_amount);
  }
  //Target: {targetDispense}
  return(
    <Tooltip title={t("targetDispenseInfo").replace("{targetDispense}", params.row.dispense).replace("{units}",t(UNIT_SETTINGS[params.row.volumeUnit].label))}>
      {status === STATUSES.flow ? <Chip sx={{
            width:"100px"
          }}
          color='warning'
          label={displayAmount}/>
        : <Typography>{displayAmount}</Typography>
      }
    </Tooltip>
  )
};

const renderAssetLink = (assetName) => {
  //const assetName = (params.row.asset_name);
  return (
      <StyledLink 
          component={RouterLink}
          to={'/assets/' + assetName + '/edit/'}>
          {assetName}
      </StyledLink>
  );
};

const renderAssetStatus = (assetStatus_list, t) => {
  var l_status = "OK";
  var severity = 'success'
  if (assetStatus_list.find((status) => status.includes(STATUSES.overdue))) {
    l_status = t(STATUSES.overdue);
    //l_status = STATUSES.overdue;
    severity = 'error';
  }
  else if (assetStatus_list.find((status) => status.includes(STATUSES.flow))){
    l_status = t(STATUSES.flow);
    //l_status = STATUSES.flow;
    severity = 'warning';
  }
  else if (assetStatus_list.find((status) => status.includes(STATUSES.wrongGrease))){
    l_status = t(STATUSES.wrongGrease);
    //l_status = STATUSES.overdue;
    severity = 'warning';
  }
  return (
    <Chip sx={{
      width:"120px"
    }}
    color={severity}
    label={l_status}/>
  );
};

const renderAssetStatusCount = (param, bError, t) => {
  var severity = "success";
  if (param > 0){
    severity = bError ? 'error' : 'warning'
  }
  return (
    <Chip sx={{
      width:"120px"
    }}
    color={severity}
    label={param}/>
  );
};

function AssetTableRow(props) {
  const { row, tableColumns, subTableColumns, t } = props;
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  return (
    <React.Fragment>
      <StyledTableRow sx={{"& td": { borderBottom: 0 }}}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell >{renderAssetLink(row.asset_name)}</StyledTableCell>
        <StyledTableCell >{row.maxDaysSince}</StyledTableCell>
        <StyledTableCell >{renderAssetStatusCount(row.overdueCount, true, props.t)} </StyledTableCell>
        <StyledTableCell >{renderAssetStatusCount(row.dispenseAlertCount, false, props.t)} </StyledTableCell>
        <StyledTableCell >{renderAssetStatusCount(row.wrongFluidCount, false, props.t)} </StyledTableCell>
        {false && <StyledTableCell >{renderAssetStatus(row.status_list, props.t)}</StyledTableCell>}
        <StyledTableCell >{row.numberOfDispensePoints}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow sx={{ '& > *': { borderTop: 0 } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ border: 0, margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {t("dispensePoints")}
              </Typography>
              {true && <Box 
                  sx={{
                          width: '100%',
                          margin: '0 auto',
                          maxWidth: '1200px',
                          height: '55vh',
                          '& .default-table-theme--header': {
                              backgroundColor: '#007ac1',
                              color: '#FFFFFF'
                            },
                      }}
                  >
              <DataGrid 
                      sx={{
                          '& .MuiDataGrid-sortIcon':{
                              //color: 'primary.contrastText'
                              color: '#fff'
                          },
                      }}
                      hideFooterSelectedRowCount
                      pagination
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rows={row.dispensePoints}
                      columns={subTableColumns} />
              </Box>}
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

function descendingComparator(a, b, orderBy) {
  var ret_order = 0;
  if (b[orderBy] < a[orderBy]) {
    ret_order = -1;
  }
  if (b[orderBy] > a[orderBy]) {
    ret_order = 1;
  }
  return ret_order;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, field, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.key}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            {<StyledTableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : 'asc'}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.label}
              {orderBy === headCell.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </StyledTableSortLabel>}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired
};

export default function AssetTableCollapsible({assetData}) {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('maxDaysSince');
  
  const assetColumns = [
    { 
      key: 99,
      numeric: false,
      disablePadding: false,
      disableSort: true,
      field: '',
      label: ''
    },
    { 
      key: 1,
      numeric: false,
      disablePadding: false,
      field: 'asset_name',
      label: t('asset')
    },
    {
      key: 2,
      numeric: true,
      disablePadding: false,
      field: 'maxDaysSince',
      label: t('daysSince')
    },
    {
      key: 3,
      numeric: true,
      disablePadding: false,
      field: 'overdueCount',
      label: t('greaseOverdue')
    },
    {
      key: 4,
      numeric: true,
      disablePadding: false,
      field: 'dispenseAlertCount',
      label: t('dispenseWarning')
    },
    {
      key: 5,
      numeric: true,
      disablePadding: false,
      field: 'wrongFluidCount',
      label: t('fluidWarning')
    },
    {
      key: 6,
      numeric: true,
      disablePadding: false,
      field: 'numberOfDispensePoints',
      label: t('dispensePoints')
    },
  ];

  const dpdColumns = [
    {
       field: 'history',
       headerName: t('history'),
       disableClickEventBubbling: true,
       headerClassName: 'default-table-theme--header',
       renderCell: RenderHistoryLink,
       align: 'center',
       sortable: false,
       width: 80
    },
    {
        field: 'dp_name', 
        headerName: t('dispensePoint'), 
        disableClickEventBubbling: true,
        headerClassName: 'default-table-theme--header',
        width: 200
    },
    {
        field: 'daysSince', 
        headerName: t('daysSince'), 
        disableClickEventBubbling: true,
        headerClassName: 'default-table-theme--header',
        renderCell: DaysWithStatus,
        width: 120
    },
    //{
    //    field: 'interval', 
    //    headerName: t('dpInterval'), 
    //    disableClickEventBubbling: true,
    //    headerClassName: 'default-table-theme--header',
    //    width: 120
    //},
    //{
    //    field: 'dispense', 
    //    headerName: t('targetDispense'), 
    //    disableClickEventBubbling: true,
    //    headerClassName: 'default-table-theme--header',
    //    width: 120
    //},
    {
        field: 'amount', 
        headerName: t('dispensed'), 
        disableClickEventBubbling: true,
        headerClassName: 'default-table-theme--header',
        renderCell: DispensedWithStatus,
        width: 120
    },
    {
        field: 'fluid_name', 
        headerName: t('fluid'), 
        disableClickEventBubbling: true,
        headerClassName: 'default-table-theme--header',
        renderCell: FluidWithStatus,
        width: 120
    },
    {
        field: 'ReadingTime_UTC', 
        headerName: t('lastReading'),
        type: 'dateTime', 
        disableClickEventBubbling: true,
        headerClassName: 'default-table-theme--header',
        width: 190
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    var l_tableData = assetData;
    var i, k;
    var temp_MaxDays;
    var temp_status;
    var temp_overdueCount;
    var temp_wrongFluidCount;
    var temp_dispenseAlertCount;
    
    for (i=0; i<l_tableData.length; i++){
      l_tableData[i].numberOfDispensePoints = l_tableData[i].dispensePoints.length;
      temp_MaxDays=0;
      temp_status = [];
      temp_overdueCount = 0;
      for (k=0; k<l_tableData[i].dispensePoints.length; k++){
        if (l_tableData[i].dispensePoints[k].daysSince > temp_MaxDays){
          temp_MaxDays = l_tableData[i].dispensePoints[k].daysSince;
        }
        temp_status.push(calculateIntervalError(l_tableData[i].dispensePoints[k].interval/24,l_tableData[i].dispensePoints[k].daysSince));
        temp_status.push(calculateDispenseAmountWarning(l_tableData[i].dispensePoints[k].dispense,l_tableData[i].dispensePoints[k].amount));
        temp_status.push(calculateGreaseError(l_tableData[i].dispensePoints[k].fluid_id, l_tableData[i].dispensePoints[k].dispense_fluid_id));
        if(l_tableData[i].dispensePoints[k].amount){
          l_tableData[i].dispensePoints[k].amount_converted = convertDispenseUnits(l_tableData[i].dispensePoints[k].amount, UNIT_SETTINGS.cu_in.name, l_tableData[i].dispensePoints[k].volumeUnit);
        }
        else{
          l_tableData[i].dispensePoints[k].amount = 0;
        }
      }
      
      l_tableData[i].maxDaysSince = temp_MaxDays;
      l_tableData[i].status_list = temp_status;
      //l_tableData[i].status = "OK";
      temp_overdueCount = 0;
      if (temp_status.find((status) => status.includes(STATUSES.overdue))) {
        //l_tableData[i].status = t(STATUSES.overdue);
        for (k=0; k < temp_status.length; k++) {
          if (temp_status[k] === (STATUSES.overdue)) {
            ++temp_overdueCount;
          }
        }
      }
      l_tableData[i].overdueCount = temp_overdueCount;
      temp_dispenseAlertCount = 0;
      if (temp_status.find((status) => status.includes(STATUSES.flow))){
        //l_tableData[i].status = t(STATUSES.flow);
        for (k=0; k < temp_status.length; k++) {
          if (temp_status[k]  === (STATUSES.flow)) {
            ++temp_dispenseAlertCount;
          }
        }
      }
      l_tableData[i].dispenseAlertCount = temp_dispenseAlertCount;
      temp_wrongFluidCount = 0;
      if (temp_status.find((status) => status.includes(STATUSES.wrongGrease))){
        for (k=0; k < temp_status.length; k++) {
          if (temp_status[k] == STATUSES.wrongGrease) {
            ++temp_wrongFluidCount;
          }
        }
      }
      l_tableData[i].wrongFluidCount = temp_wrongFluidCount;
    }
    //console.log(l_tableData);
    setTableData(l_tableData);
    setDataReady(true);
  }, [assetData]);

  return (
      <TableContainer component={Paper} >
      {dataReady && <Table aria-label="collapsible asset table">
          <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={assetColumns}
          />
          <TableBody>
          {stableSort(tableData, order, getComparator(order, orderBy)).map((row, index) => {
              return(
                <AssetTableRow row={row} tableColumns={assetColumns} subTableColumns={dpdColumns} t={t} />
              )
          })}
          </TableBody>
      </Table>}
      </TableContainer>
  );
}