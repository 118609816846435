/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
    Button,
    Input,
    InputAdornment,
    InputLabel,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { host } from "../data";
import makeStyles from '@mui/styles/makeStyles';
import {
    ArrowBack,
    Edit
} from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import UserReportScheduleContent from "./UserReportScheduleContent.js";
import UserOrganizationContent from "./UserOrganizationContent.js";
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';

import UserInfoContent from './UserInfoContent';

const useStyles = makeStyles((theme) => ({
    indented: {
        marginLeft: theme.spacing(10)
    },
    content: {
        padding: theme.spacing(2)
    },
    settings: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(1),
        width: 800,
        marginBottom: '10px',
    },
    settingsUnedit: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(1),
        width: 700,
        height: '35px',
        marginBottom: '10px',
    },
    passwordSettings: {
        marginLeft: theme.spacing(3),
        marginTop: '10px',
        marginBottom: '10px',
        width: 700,
        height: '37px'
    },
    button: {
        margin: theme.spacing(1),
        width: '25ch',
    },
    saveButton: {
        margin: theme.spacing(1),
        width: '25ch',
    },
    submitButton: {
        marginTop: '10px',
        marginBottom: '10px',
        width: '25ch',
    },
    formControl: {
        display: 'inline-block',
        float: 'left',
        marginTop: '5px',
        marginBottom: '20px',
    },
    recurrMenuText: {
        display: 'inline-block',
        marginLeft: '10px',
        marginRight: '10px'
    },
    recurrMenuText_1: {
        display: 'inline-block',
        marginRight: '10px'
    },
    headerText: {
        marginBottom: '20px'
    },
    passwordButton: {
        width: '30ch'
    },
    passwordButtonEdit: {
        marginLeft: theme.spacing(4),
        width: '30ch'
    },
    emailTextField: {
        marginLeft: '15px',
        textTransform: 'lowercase'
    },
    emailTextFieldEdit: {
        marginLeft: '0',
        textTransform: 'lowercase'
    },
    textField: {
        width: '25ch'
    },
    languageSelect: {
        marginTop: '10px',
        width: '25ch'
    }
}));


export default function ProfilePage(props) {
    const { t, i18n } = useTranslation();
    const tab1 = useRef(null);
    const tab2 = useRef(null);
    const tab3 = useRef(null);
    const tab4 = useRef(null);
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const history = useHistory();
    const [editActive, setEditActive] = React.useState(false);
    const [userInfoFetched, setUserInfoFetched] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState(undefined);
    const [changePWord, setChangePWord] = React.useState(false);
    const [ref1Focus, setRef1Focus] = React.useState(false);
    const [ref2Focus, setRef2Focus] = React.useState(false);
    const [ref3Focus, setRef3Focus] = React.useState(false);
    const [ref4Focus, setRef4Focus] = React.useState(false);
    const [ref5Focus, setRef5Focus] = React.useState(false);
    const [criticalHelpOpen, setCriticalHelpOpen] = useState(false);
    const [dailyStatusReportOpen, setDailyStatusReportHelpOpen] = useState(false);
    const [values, setValues] = React.useState({
        given_name: '',
        family_name: '',
        phone_number: '',
        old_password: '',
        new_password: '',
        showPassword: false,
    });
    const [valuesOld, setValuesOld] = React.useState({
        given_name: '',
        family_name: '',
        phone_number: '',
        old_password: '',
        new_password: '',
        showPassword: false,
    });
    const [formError, setFormError] = React.useState('none');
    const [state, setState] = React.useState({
        daily_status_report_email: false,
        daily_status_report_text: false,
        critical_level_notification_email: false,
        critical_level_notification_text: false,
    });
    const [statePrev, setStatePrev] = React.useState(state);
    const [recurrTime, setRecurrTime] = React.useState("8:00");
    const [recurringInput, setRecurringInput] = React.useState("Monday");
    const [userLanguage, setUserLanguage] = React.useState("English");
    const [userLanguagePrev, setUserLanguagePrev] = React.useState("English");
    const [lngAbbrv, setLngAbbrv] = React.useState("en");
    const [lngAbbrvPrev, setLngAbbrvPrev] = React.useState("en");
    const [position, setPosition] = React.useState(0);

    const handleRecurrTimeSelect = (event) => {
        setRecurrTime(event.target.value);

        setState({
            daily_status_report_email: state.daily_status_report_email,
            daily_status_report_text: state.daily_status_report_text,
            critical_level_notification_email: state.critical_level_notification_email,
            critical_level_notification_text: state.critical_level_notification_text,
            notification_recurr_time: event.target.value
        });

    };

    const handleCriticalHelpOpen = () => {
        setCriticalHelpOpen(true);
    };

    const handleCriticalHelpClose = () => {
        setCriticalHelpOpen(false);
    };

    const handleDailyStatusReportHelpOpen = () => {
        setDailyStatusReportHelpOpen(true);
    };

    const handleDailyStatusReportHelpClose = () => {
        setDailyStatusReportHelpOpen(false);
    };

    const handleChange = (prop) => (event) => {
        setPosition(event.target.value.slice(0, event.target.selectionStart).length);
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChange1 = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickChangePWord = () => {
        if (changePWord) { setChangePWord(false) }
        else { setChangePWord(true) };
    };

    const handleClickEdit = () => {
        if (editActive) {
            setEditActive(false)
        } else {
            setEditActive(true)
        };

        setChangePWord(false);
        setValues(valuesOld);
        saveUserLanguageSetting(lngAbbrvPrev);
        props.changeLanguage(lngAbbrvPrev);
    };

    const handleClickSubmitChangePWord = async () => {
        if (values.old_password === ''
            || values.new_password === '') {
            setFormError(t("youCannotChangeYourPassword"));
            return;
        }
        try {
            let user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, values.old_password, values.new_password);
            setChangePWord(false);
            setFormError(t("passwordUpdated"));
        } catch (error) {
            setFormError(error.message);
        }
    };

    const handleClickSubmit = async () => {
        if (values.given_name === '') {
            setFormError(t("mustIncludeAFirstName"));
            return;
        }

        if (values.family_name === '') {
            setFormError(t("mustIncludeALastName"));
            return;
        }

        if (values.phone_number !== ''
            && /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.phone_number) === false) {
            setFormError(t("invalidPhoneNumber"));
            return;
        } else {
            setFormError('none');
        }

        try {
            let user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
                given_name: values.given_name,
                family_name: values.family_name,
                phone_number: (values.phone_number !== '' ? '+1' + values.phone_number : ''),
            });

            setLngAbbrvPrev(lngAbbrv);
            setUserLanguagePrev(userLanguage);
            setValuesOld(values);
            history.go(0);
        } catch (error) {
            setFormError(error.message);
        }
    };

    async function saveUserLanguageSetting(lng) {
        try {
            setLngAbbrv(lng);

            switch (lng) {
                case 'en':
                    setUserLanguage('English');
                    break;
                case 'es':
                    setUserLanguage('Español');
                    break;
                case 'fr':
                    setUserLanguage('Français');
                    break;
            }

            let user = await Auth.currentAuthenticatedUser();
            user.getSession((err, session) => {
                if (err) {
                    console.log(err);
                    return;
                }

                const sessionToken = session.getIdToken().jwtToken;
                var body = JSON.stringify(
                    {
                        "language": lng,
                    }
                );

                var requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': sessionToken
                    },
                    body: body
                };

                // fetch(host + '/user/settings', requestOptions)
                //     .then(async response => {
                //         // console.log(response);
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //     });
            });
        } catch (error) {
            console.log(error);
        }
    };

    async function fetchUserInfo() {
        try {
            let user = await Auth.currentUserInfo();
            setUserInfo(user.attributes);
        } catch (error) {
            setFormError(error.message);
        }
    };

    React.useEffect(() => {
        fetchUserInfo();
    }, []);

    React.useEffect(() => {
        if (userInfo !== undefined) {
            setValues({
                given_name: userInfo.given_name,
                family_name: userInfo.family_name,
                phone_number: (userInfo.phone_number === undefined ? '' : userInfo.phone_number.substring(2)),
                old_password: '',
                new_password: '',
                showPassword: false,
            });
            setValuesOld({
                given_name: userInfo.given_name,
                family_name: userInfo.family_name,
                phone_number: (userInfo.phone_number === undefined ? '' : userInfo.phone_number.substring(2)),
                old_password: '',
                new_password: '',
                showPassword: false,
            });
            setUserInfoFetched(true)
        }
    }, [userInfo]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}>
                {value === index && (
                    <Box component="div" div={3}>
                        <Typography component="div">{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    };

    const handleNotificationCancel = (event) => {
        setState(statePrev);
        setRecurringInput(statePrev.notification_recurr_weekday);
        setRecurrTime(statePrev.notification_recurr_time);
    };

    const handleClickNotificationSubmit = async (event) => {
        setStatePrev(state);

        let user = await Auth.currentAuthenticatedUser();
        user.getSession((err, session) => {
            if (err) {
                console.log(err);
                return;
            }

            // Adjust client's time to UTC
            var clientTimeZoneOffset = new Date().getTimezoneOffset() / 60;
            var adjustedReportTime = parseInt(recurrTime) + clientTimeZoneOffset;

            if (adjustedReportTime < 0) {
                adjustedReportTime = adjustedReportTime + 24;
            }

            if (adjustedReportTime > 23) {
                adjustedReportTime = adjustedReportTime - 24;
            }

            var now = new Date();
            var then = new Date();
            now.setHours(parseInt(recurrTime), 0, 0, 0);
            then.setHours(parseInt(recurrTime), 0, 0, 0);
            var n = now.getTimezoneOffset() / 60;
            then.setHours(then.getHours() + n);
            var timeOffset = 0;

            const sessionToken = session.getIdToken().jwtToken;
            var body = JSON.stringify(
                {
                    "daily_status_report_email": state.daily_status_report_email,
                    "daily_status_report_text": state.daily_status_report_text,
                    "critical_level_notification_email": state.critical_level_notification_email,
                    "critical_level_notification_text": state.critical_level_notification_text,
                    "notification_recurr_time": adjustedReportTime,
                    "notification_time_utc_shift": timeOffset,
                    "TimeZoneAdjust": clientTimeZoneOffset,
                }
            );

            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
                body: body
            };

            // fetch(host + '/user', requestOptions)
            //     .then(results => results.json())
            //     .then(data => {
            //         alert(t("notificationSettingsSuccessfullyUpdated"));
            //     }).catch((error) => {
            //         console.log(error);
            //     });
        });
    };

    useEffect(() => {
        fetchPageData();

        Auth.currentAuthenticatedUser()
            .then(response => {
                try {
                    response.getSession((err, session) => {
                        if (err) {
                            console.log(err);
                            return;
                        }


                    });
                } catch (e) {
                    console.log(e);
                }
            })
    }, []);

    function fetchPageData() {
        Auth.currentAuthenticatedUser()
            .then(response => {
                try {
                    response.getSession((err, session) => {
                        if (err) {
                            console.log(err);
                            return;
                        }

                        var requestOptions = {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': session.getIdToken().jwtToken
                            },
                        };

                        // fetch(host + '/user', requestOptions)
                        //     .then(results => results.json())
                        //     .then(data => {
                        //         if (data.notificationsSettings != {}) {
                        //             // Adjust UCT to client's timezone
                        //             var clientTimeZoneOffset = new Date().getTimezoneOffset() / 60;
                        //             var recurrTimeAdjusted = (parseInt(data.notificationsSettings['notification_recurr_time']) - clientTimeZoneOffset);
                        //             if (recurrTimeAdjusted > 23) {
                        //                 recurrTimeAdjusted = recurrTimeAdjusted - 24;
                        //             }

                        //             if (recurrTimeAdjusted < 0) {
                        //                 recurrTimeAdjusted = recurrTimeAdjusted + 24;
                        //             }

                        //             recurrTimeAdjusted = recurrTimeAdjusted.toString() + ':00';

                        //             setState({
                        //                 daily_status_report_email: !!parseInt(data.notificationsSettings['daily_status_report_email']),
                        //                 daily_status_report_text: !!parseInt(data.notificationsSettings['daily_status_report_text']),
                        //                 critical_level_notification_email: !!parseInt(data.notificationsSettings['critical_level_notification_email']),
                        //                 critical_level_notification_text: !!parseInt(data.notificationsSettings['critical_level_notification_text']),
                        //                 notification_recurr_time: recurrTimeAdjusted,
                        //             });

                        //             setStatePrev({
                        //                 daily_status_report_email: !!parseInt(data.notificationsSettings['daily_status_report_email']),
                        //                 daily_status_report_text: !!parseInt(data.notificationsSettings['daily_status_report_text']),
                        //                 critical_level_notification_email: !!parseInt(data.notificationsSettings['critical_level_notification_email']),
                        //                 critical_level_notification_text: !!parseInt(data.notificationsSettings['critical_level_notification_text']),
                        //                 notification_recurr_time: recurrTimeAdjusted,
                        //             });

                        //             setRecurrTime(recurrTimeAdjusted);
                        //         }
                        //     }).catch((error) => {
                        //         console.log(error)
                        //     });

                        console.log("Fetching user language setting...");

                        // fetch(host + '/user/settings', {
                        //     method: 'GET',
                        //     headers: {
                        //         'Content-Type': 'application/json',
                        //         'Authorization': session.getIdToken().jwtToken
                        //     }
                        // }).then(response => response.json())
                        //     .then(data => {
                        //         if (data['lang'].lang !== undefined) {
                        //             setLngAbbrv(data['lang'].lang);
                        //             setLngAbbrvPrev(data['lang'].lang);

                        //             switch (data['lang'].lang) {
                        //                 case 'en':
                        //                     setUserLanguage('English');
                        //                     setUserLanguagePrev('English');
                        //                     break;
                        //                 case 'es':
                        //                     setUserLanguage('Español');
                        //                     setUserLanguagePrev('Español');
                        //                     break;
                        //                 case 'fr':
                        //                     setUserLanguage('Français');
                        //                     setUserLanguagePrev('Français');
                        //                     break;
                        //             }
                        //         }
                        //         console.log("User language setting fetched.");
                        //     });
                    });
                } catch (e) {
                    console.log(e);
                }
            });

    };

    const handleNotificationChange = (event) => {
        switch (event.target.name) {
            case 'daily_status_report_email':
                setState({
                    daily_status_report_email: event.target.checked,
                    daily_status_report_text: state.daily_status_report_text,
                    critical_level_notification_email: state.critical_level_notification_email,
                    critical_level_notification_text: state.critical_level_notification_text,
                    notification_recurr_time: state.notification_recurr_time,
                });
                break;
            case 'daily_status_report_text':
                setState({
                    daily_status_report_email: state.daily_status_report_email,
                    daily_status_report_text: event.target.checked,
                    critical_level_notification_email: state.critical_level_notification_email,
                    critical_level_notification_text: state.critical_level_notification_text,
                    notification_recurr_time: state.notification_recurr_time,
                });
                break;
            case 'critical_level_notification_text':
                setState({
                    daily_status_report_email: state.daily_status_report_email,
                    daily_status_report_text: state.daily_status_report_text,
                    critical_level_notification_email: state.critical_level_notification_email,
                    critical_level_notification_text: event.target.checked,
                    notification_recurr_time: state.notification_recurr_time,
                });
                break;
            case 'critical_level_notification_email':
                setState({
                    daily_status_report_email: state.daily_status_report_email,
                    daily_status_report_text: state.daily_status_report_text,
                    critical_level_notification_email: event.target.checked,
                    critical_level_notification_text: state.critical_level_notification_text,
                    notification_recurr_time: state.notification_recurr_time,
                });
                break;
        };
    };

    const setCaretPosition = (elemId, caretPos) => {
        var elem = document.getElementById(elemId);

        if (elem != null) {
            if (elem.createTextRange) {
                var range = elem.createTextRange();
                range.move('character', caretPos);
                range.select();
            }
            else {
                if (elem.selectionStart) {
                    elem.focus();
                    elem.setSelectionRange(caretPos, caretPos);
                }
                else
                    elem.focus();
            }
        }
    };

    const handleChangeLanguage = (e) => {
        setLngAbbrv(e.target.value);

        switch (e.target.value) {
            case 'en':
                setUserLanguage('English');
                break;
            case 'es':
                setUserLanguage('Español');
                break;
            case 'fr':
                setUserLanguage('Français');
                break;
        }

        saveUserLanguageSetting(e.target.value);
        props.changeLanguage(e.target.value);
    };

    useEffect(() => {
        setCaretPosition('old-password-entry', position);
    }, [values.old_password]);

    useEffect(() => {
        setCaretPosition('new-password-entry', position);
    }, [values.new_password]);

    useEffect(() => {
        setCaretPosition('given-name-entry', position);
    }, [values.given_name]);

    useEffect(() => {
        setCaretPosition('family-name-entry', position);
    }, [values.family_name]);

    useEffect(() => {
        setCaretPosition('phone-number-entry', position);
    }, [values.phone_number]);

    return (
        <Grid container
            component="div"
            direction='column'
            justifyContent='flex-start'
            alignItems='stretch'
            className={classes.content}>

            <Grid item xs>
                <Toolbar>
                    <Tooltip title={t("back")}>
                        <IconButton color='primary' onClick={() => history.goBack()} size="large">
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                    <Typography variant='h5'
                        color='textPrimary'>
                        {t("userSettings")}
                    </Typography>
                </Toolbar>
            </Grid>

            <Grid item xs>
                {/* <Divider /> */}
            </Grid>

            <Tabs
                centered
                value={value}
                onChange={handleChange1}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
            >
                <Tab ref={tab1} label={t("profile")} {...a11yProps(0)} />
                <Tab ref={tab2} label={t("reports")} {...a11yProps(1)} />
                <Tab ref={tab3} label={t("accounts")} {...a11yProps(2)} />
                <Tab ref={tab4} label={t("notifications")} {...a11yProps(3)} />
            </Tabs>

            <TabPanel value={value} index={0} component="div">
                {userInfoFetched ? <UserInfoContent/>
                    : <Typography component="span">{t("fetchingUserInfo")}</Typography>
                }
            </TabPanel>

            <TabPanel value={value} index={1} component="div">
                {false && <UserReportScheduleContent fetchOrgInformation={props.fetchOrgInformation} />}
            </TabPanel>

            <TabPanel value={value} index={2} component="div">
                <UserOrganizationContent />
            </TabPanel>

            <TabPanel value={value} index={3} component="div">
                <Typography component="div" style={{ marginBottom: '20px' }} variant="h6">{t("notifications")}</Typography>
                <Typography component="div">{t("Coming soonish")}</Typography>

                {false && <FormGroup style={{ marginTop: '10px', marginLeft: '10px', marginBottom: '20px' }} component="div">
                    <div>
                        {criticalHelpOpen && <Dialog className={classes.infoDialog} disableEscapeKeyDown open={criticalHelpOpen} onClose={handleCriticalHelpClose}>
                            <DialogTitle>{t("criticalLevelNotification")}</DialogTitle>
                            <DialogContent className={classes.root}>
                                <Typography paragraph variant="body2">
                                    {t("pulseLevelWillSendYouNotifications")}
                                </Typography>
                                <Typography paragraph variant="body2">
                                    {t("criticalNotificationsAreSent")}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCriticalHelpClose} color="primary">
                                    {t("close")}
                                </Button>
                            </DialogActions>
                        </Dialog>}

                        {dailyStatusReportOpen && <Dialog className={classes.infoDialog} disableEscapeKeyDown open={dailyStatusReportOpen} onClose={handleDailyStatusReportHelpClose}>
                            <DialogTitle>{t("dailyStatusReport")}</DialogTitle>
                            <DialogContent className={classes.root}>
                                <Typography paragraph variant="body2">
                                    {t("pulseLevelWillSendYouASummary")}
                                </Typography>
                                <Typography paragraph variant="body2">
                                    {t("statusReportsAreSentOnce")}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleDailyStatusReportHelpClose} color="primary">
                                    {t("close")}
                                </Button>
                            </DialogActions>
                        </Dialog>}

                        <Grid container component="div">
                            <Grid item xs={4} component="div">
                                {/* Unused grid space */}
                            </Grid>
                            <Grid item xs={4} component="div">
                                {t("email")}
                            </Grid>
                            <Grid item xs={4} component="div">
                                {t("textMessage")}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4}>
                                {t("statusReport")}
                                <Tooltip title={t("help")}>
                                    <IconButton onClick={handleDailyStatusReportHelpOpen} size="large">
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <Checkbox
                                    checked={state.daily_status_report_email}
                                    onChange={handleNotificationChange}
                                    name="daily_status_report_email"
                                    color="primary"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Checkbox
                                    checked={state.daily_status_report_text}
                                    onChange={handleNotificationChange}
                                    name="daily_status_report_text"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={8}>
                                <p className={classes.recurrMenuText_1}>{t("sendStatusReportEverydayAt")} </p>
                                <Select
                                    native
                                    onChange={handleRecurrTimeSelect}
                                    value={recurrTime} >
                                    <option value={"1:00"}>1:00</option>
                                    <option value={"2:00"}>2:00</option>
                                    <option value={"3:00"}>3:00</option>
                                    <option value={"4:00"}>4:00</option>
                                    <option value={"5:00"}>5:00</option>
                                    <option value={"6:00"}>6:00</option>
                                    <option value={"7:00"}>7:00</option>
                                    <option value={"8:00"}>8:00</option>
                                    <option value={"9:00"}>9:00</option>
                                    <option value={"10:00"}>10:00</option>
                                    <option value={"11:00"}>11:00</option>
                                    <option value={"12:00"}>12:00</option>
                                    <option value={"13:00"}>13:00</option>
                                    <option value={"14:00"}>14:00</option>
                                    <option value={"15:00"}>15:00</option>
                                    <option value={"16:00"}>16:00</option>
                                    <option value={"17:00"}>17:00</option>
                                    <option value={"18:00"}>18:00</option>
                                    <option value={"19:00"}>19:00</option>
                                    <option value={"20:00"}>20:00</option>
                                    <option value={"21:00"}>21:00</option>
                                    <option value={"22:00"}>22:00</option>
                                    <option value={"23:00"}>23:00</option>
                                    <option value={"24:00"}>24:00</option>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: '50px' }}>
                            <Grid item xs={8}>
                                <Button
                                    className={classes.button}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={handleClickNotificationSubmit}>
                                    {t("save")}
                                </Button>
                                <Button className={classes.button}
                                    color="primary"
                                    size="small"
                                    onClick={handleNotificationCancel}>
                                    {t("cancel")}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </FormGroup>}
            </TabPanel>
        </Grid>
    );
}
