import React, { useState } from 'react';
import { FormControl, Select, MenuItem, Tooltip } from "@mui/material";
import { useTranslation } from 'react-i18next';

const RolesList = ({ id, access, disabled, setUserRole }) => {
  const { t } = useTranslation();
  const [role, setRole] = useState(access);
  const handleUserAccessChange = (e) => {
    const newRole = e.target.value;
    if (newRole !== "member") {
      setRole(e.target.value);
      setUserRole(id, newRole);
    }
    else {
      alert(t("warningCannotSetAsMember"));
    }
  };

  return (
    <form noValidate>
      <Tooltip placement='left' title={role === "edit" ? t("canMakeChanges") : role === "view" ? t("canViewData") : t("noDataAccess")}>
        <FormControl>
          <Select
            disabled={disabled}
            value={role}
            onChange={handleUserAccessChange}
          >
            <MenuItem disabled value="member">{t("member")}</MenuItem>
            <MenuItem value="edit">{t("editor")}</MenuItem>
            <MenuItem value="view">{t("viewer")}</MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </form>
  );
};

export default RolesList;
