import React, { useEffect, useState } from 'react';
import useSessionstorage from '@rooks/use-sessionstorage';
import { Link as RouterLink } from 'react-router-dom';
import {
    Breadcrumbs,
    Grid,
    IconButton,
    Link,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import AddDevice from '../../MainPage/Components/AddDevice';
import AccountDevices from './components/AccountDevices';
import devices_inf from '../../services/devices_inf';
import fluids_inf from '../../services/fluids_inf';
import { GrowDiv } from '../../styledComponentsCommon';


export default function AccountDevicesBilling(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [devices, setDevices] = useState([]);
    const [fluidsList, setFluidsList] = useState([]);
    const { value: accountInfo } = useSessionstorage('accountInfo', {});

    const fetchDevices = async () => {
        try {
            console.log("fetching devices...");
            const { status, error, deviceList } = await devices_inf.getDevices(accountInfo.accountId);

            if (status === 404) {
                return;
            }
            
            if (error !== 'OK' && status !== 200) {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            setDevices(deviceList);
            
        } catch (error) {
            console.error(error);
        }
    };

    const fetchFluids = async () => {
        try {
            console.log("fetching fluids...");
            const { status, data } = await fluids_inf.getFluids(accountInfo.accountId);
            
            const { error, fluidsList } = data;

            if (status === 404) {
                return;
            }
            
            if (error !== 'OK' && status !== 200) {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            setFluidsList(fluidsList);
            
        } catch (error) {
            console.error(error);
        }
    };

    useEffect (() => {
        fetchDevices();
        fetchFluids();
    },[])



    return (
        <Grid container
            direction='column'
            justifyContent='flex-start'
            alignItems='stretch'
            sx={{padding: theme.spacing(1),}}>
            <Grid item xs>
                <Toolbar>
                    <Tooltip title={t("goHome")}>
                        <IconButton color='primary' component={RouterLink} to={'/'} size="large">
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                    <Breadcrumbs>
                        <Link variant='h5'
                            component={RouterLink}
                            to={'/'}>
                            {t("settings")}
                        </Link>
                        <Typography variant="h5">
                            {t("devices")}
                        </Typography>
                    </Breadcrumbs>
                </Toolbar>
            </Grid>
            <Toolbar>
                <Typography>Under construction.</Typography>
                <GrowDiv/>
                <AddDevice accountInfo={accountInfo} updateDevices={fetchDevices}/>
            </Toolbar>
            <AccountDevices deviceData={devices} fluidsList={fluidsList} dataLoading={false} refreshDeviceData={fetchDevices}/>
        </Grid>
    );
}