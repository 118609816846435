import React, { useState, useRef } from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import makeStyles from '@mui/styles/makeStyles';
import { Typography, TextField } from "@mui/material";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    minWidth: 400,
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  confirmField: {
    width: 300
  }
}));

const ModalPrompt = ({ title, content, action, handleAction, handleCancel, openModal, useConfirm = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(openModal);
  const [deleteConfirm, setDeleteConfirm] = useState("");
  const rdeleteConfirm = useRef("");
  const [invalidConfirm, setInvalidConfirm] = useState(false);
  
  const handle = () => {
    if (!useConfirm || (useConfirm && deleteConfirm === t("delete_caps"))) {
      setOpen(false);
      handleAction();
    }
    else {
      setInvalidConfirm(true);
    }
  };
  const cancel = () => {
    setOpen(false);
    handleCancel();
  };
  return (
    <Dialog disableEscapeKeyDown open={open} onClose={cancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.root}>
        <Typography className={classes.content} variant="body2">
          {content}
        </Typography>
        {useConfirm && <TextField className={classes.confirmField}
          label={t("deleteConfirm1_Type") + t("delete_caps") + t("deleteConfirm2_toConfirm")}
          inputRef={rdeleteConfirm}
          value={deleteConfirm}
          onChange={(e) => {
            const regex = /^([ a-zA-Z0-9\-]){0,40}$/i;
            if (regex.test(e.target.value)) {
              setDeleteConfirm(e.target.value);
            }
          }}
          error={invalidConfirm}
          helperText={invalidConfirm ? t("deleteConfirm_error") : ""}
        />}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">
          {t("cancel")}
        </Button>
        <Button variant="contained" onClick={handle} color="primary">
          {action}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalPrompt;
