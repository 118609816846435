/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useParams
} from 'react-router-dom';
import useSessionstorage from '@rooks/use-sessionstorage';
import Cookies from 'universal-cookie';
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Hidden,
    Grid,
    IconButton,
    Link,
    Skeleton,
    Stack,
    TextField,
    Tab,
    Tabs,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useTranslation } from 'react-i18next';

import AssetTable from './Components/AssetTable.js';
import DeviceTable from './Components/DeviceTable.js';
import AccountInvite from './Components/AccountInvite.js';
import AddDevice from './Components/AddDevice.js'
import assets_inf from '../services/assets_inf.js';
import { isValidEmail } from '../helpers/isValidEmail';
import { COOKIE_NAMES } from "../data";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{
                  ml: 3,
                  mr: 3,
                }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Main page.
export default function MainPage({accountInfo}) {
  const { t } = useTranslation();
  const history = useHistory();
  const theme=useTheme();
  //const { value: accountInfo } = useSessionstorage('accountInfo', {});
  //const [accountInfo, setAccountInfo] = useState(accountInfo);
  const [tabValue, setTabValue] = useState(0);
  const [assetData, setAssetData] = useState([]);
  const [fluidsList, setFluidsList] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [dataReady, setDataReady] = useState(false);
  
  //To Do fetch data?

  const handleChangeTab = (event, newValue) => {
    var cookies = new Cookies()
    cookies.set(COOKIE_NAMES.mainPageTab.replace("{accountName}", accountInfo.accountName), newValue, { maxAge: 1000000 });
    setTabValue(newValue);
  };

  const handleAddAsset = () =>{
    let url="/assets/new";
    history.push(url);
  };

  const fetchAssets = async () => {
    setDataReady(false);
    try{
      const {status, data} = await assets_inf.getAssets(accountInfo.accountId);
      
      const error = data.error;
      
      if (status === 404) {
          return;
      }

      if (error !== 'OK' && status !== 200) {
        alert(t("sorryUnableToRetrieve"));
        throw new Error(error);
      }
      setFluidsList(data.fluidsList);
      setAssetData(data.assetData);
      setDeviceData(data.deviceData);
    }catch(error){
      alert(t("sorryUnableToRetrieve"));
      console.log(error);
    }
    setDataReady(true);
  };

  useEffect(() => {
    if (!dataReady){
      var cookies = new Cookies()
      const lastTabValue = cookies.get(COOKIE_NAMES.mainPageTab.replace("{accountName}", accountInfo.accountName));
      
      if (lastTabValue !== undefined){
        setTabValue(parseInt(lastTabValue));
      }
    }
    fetchAssets();
  },[accountInfo]);

  return (
    <>
    <Grid container item direction='column'>
      <Grid item container
          sx={{ 
              marginTop: '12px',
              flexDirection: {xs: 'column', sm: 'row-reverse', md: 'row-reverse'} 
            }}>
        <Grid item md>
          <Toolbar>
            <Hidden smDown>
              <Box flexGrow={1}/>
            </Hidden>
            <Button sx={{marginRight: theme.spacing(1)}} variant="contained" color="secondary" onClick={handleAddAsset}>
              {t("addAsset")}
            </Button>
            <AddDevice accountInfo={accountInfo} updateDevices={fetchAssets}/>
            <AccountInvite  accountId={accountInfo.accountId} 
                            access={accountInfo.isAccountEditor}/>
          </Toolbar>
        </Grid>
        <Grid item xs>
          <Tabs value={tabValue} centered
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}>
            <Tab label={t("assets")} />
            <Tab label={t("devices")} />
          </Tabs>
        </Grid>
      </Grid>
      <Grid item xs>
          <TabPanel value={tabValue} index={0}>
            {dataReady ? assetData.length ? <AssetTable assetData={assetData} fluidsList={fluidsList} refreshData={fetchAssets} /> 
              : <Typography>{t('noData')}</Typography> 
              : <Stack spacing={1}><Skeleton variant="rectangular" width={'100%'}/><Skeleton variant="rectangular" width={'100%'} height={500}/></Stack>}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            {dataReady ? deviceData.length && <DeviceTable deviceData={deviceData} refreshData={fetchAssets}/> 
            : <Stack spacing={1}><Skeleton variant="rectangular" width={'100%'}/><Skeleton variant="rectangular" width={'100%'} height={500}/></Stack>}
          </TabPanel>
      </Grid>
    </Grid>
    </>
  )
}
