import React, { Fragment, useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Tooltip } from "@mui/material/";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import RolesList from "./RolesList";
import { Typography } from "@mui/material";
import ModalPrompt from "../../../CommonComponents/ModalPrompt";
import { ACCESS } from "../../../data";
import { useTranslation } from 'react-i18next';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  modal: {
    width: '400px'
  }
});

const UserTable = ({ users, access, removeUser, setUserRole, currentUser }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [delUserId, setDelUserId] = useState(null);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setUserList(users);
  }, [users]);
  const handleClose = () => setOpenDialog(false);
  const handleDeleteUser = () => {
    setOpenDialog(false);
    removeUser(delUserId);
  };
  function handleOpen(id) {
    setOpenDialog(true);
    setDelUserId(id);
  }
  
  return (
    <Fragment>
      {userList && userList.length ? <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="user permissions table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("fullName")}</StyledTableCell>
              <StyledTableCell>{t("userEmail")}</StyledTableCell>
              <StyledTableCell>{t("access")}</StyledTableCell>
              <StyledTableCell>{t("delete")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((row) => (
              <StyledTableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.full_name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell>
                  <RolesList id={row.email} access={row.access} disabled={currentUser === row.email || access === ACCESS.READ_ONLY} setUserRole={setUserRole} />
                </TableCell>
                <TableCell>
                  <Tooltip title={t("delete")+ " " + row.email} placement="right">
                    <IconButton
                      disabled={currentUser === row.email}
                      onClick={() => handleOpen(row.email)}
                      size="large"><DeleteIcon /></IconButton>
                  </Tooltip>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> : <Typography variant="subtitle1" component="h4">{t("loading")}</Typography>}
      {openDialog && <ModalPrompt title={t("deleteUser")}
        content={t("areYouSure")}
        action={t("delete")}
        handleAction={handleDeleteUser}
        handleCancel={handleClose}
        openModal={openDialog} />}
    </Fragment>
  );
};

export default UserTable;
