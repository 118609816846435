import {Auth} from 'aws-amplify';
import {host} from '../data.js'

const LOCATION_ERROR_MESSAGE =
    'Error in account information service! Provide, correct data to the service!';

let urlConfig = {
    apiCreate: 'accounts/{accountId}/assets/',
    apiCreateDp: 'accounts/{accountId}/assets/{assetId}/dps/',
    api: 'accounts/{accountId}/assets/{assetId}',
    apilookup: 'accounts/{accountId}/assets/lookup/',
    apiDp: 'accounts/{accountId}/assets/{assetId}/dps/{dpId}',
    apiGet: 'accounts/{accountId}/assets/',
    apiGetDp: 'accounts/{accountId}/assets/{assetId}/dps/{dpId}',
    apiHistoryGet: 'accounts/{accountId}/assets/{assetId}/dps/{dpId}/history',
    apiGetDpId: 'accounts/{accountId}/dpid'
    //proxyPort: 5000
};

export const assets_inf = {
    createAsset: async (accountId, name, tags) => {
        try {
            if (!name || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            if (tags === undefined){
                tags=""
            }
            
            const url =
                host + urlConfig.apiCreate.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    name: name,
                    tags: tags
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateAsset: async (accountId, assetId, name, tags) => {
        try {
            if (!accountId || !name || !assetId ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            if (tags === undefined){
                tags=""
            }
            
            const url =
                host + urlConfig.api.replace('{accountId}', accountId).replace('{assetId}', assetId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    name: name,
                    tags: tags
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteAsset: async (accountId, assetId) => {
        try {
            if (!accountId || !assetId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.api.replace('{accountId}', accountId).replace('{assetId}', assetId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getAssetInfo: async (accountId, assetId) => {
        try {
            if (!accountId || !assetId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.api.replace('{accountId}', accountId).replace('{assetId}', assetId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    lookupAssetId: async (accountId, assetName) => {
        try {
            if (!accountId || !assetName) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apilookup.replace('{accountId}',accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    name: assetName
                })
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getAssets: async (accountId) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiGet.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    createDispensePoint: async (accountId, assetId, name, fluid_id, zerk_uid, volumeUnit, dispense, interval) => {
        var l_zerk_uid = ""
        try {
            if (!name || !accountId || !assetId || !volumeUnit || !dispense || !interval || !fluid_id) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            if (zerk_uid){
                l_zerk_uid = zerk_uid;
            }
            const url =
                host + urlConfig.apiCreateDp.replace("{accountId}",accountId).replace('{assetId}',assetId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    name: name,
                    fluid_id: fluid_id,
                    zerk_uid: l_zerk_uid,
                    data:{
                        volumeUnit: volumeUnit,
                        dispense: dispense,
                        interval: interval
                    }
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateDispensePoint: async (accountId, assetId, dpId, name, fluid_id, zerk_uid, volumeUnit, dispense, interval) => {
        try {
            if (!name || !accountId || !assetId || !dpId || !volumeUnit || !dispense || !interval || !fluid_id ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiDp.replace('{accountId}', accountId).replace('{assetId}', assetId).replace('{dpId}',dpId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    name: name,
                    fluid_id: fluid_id,
                    zerk_uid: zerk_uid,
                    data:{
                        volumeUnit: volumeUnit,
                        dispense: dispense,
                        interval: interval
                    }
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteDispensePoint: async (accountId, assetId, dpId) => {
        try {
            if (!accountId || !assetId || !dpId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiDp.replace('{accountId}', accountId).replace('{assetId}', assetId).replace('{dpId}', dpId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getDpHistory: async (accountId, assetId, dpId, monthInterval=0) => {
        try {
            if (!accountId || !assetId || !dpId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            let l_monthInterval = 1;
            if (monthInterval){
                l_monthInterval = monthInterval;
            }
            const url =
                host + urlConfig.apiHistoryGet.replace('{accountId}', accountId).replace('{assetId}', assetId).replace('{dpId}', dpId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    months: l_monthInterval,
                }),
            });
            const data = await response.json();
            //console.log(data);
            return {status: response.status, error: data.error, dpHistory: data.dpHistory};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getDpId: async (accountId, assetName, dpName) => {
        try {
            if (!accountId || !assetName || !dpName) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiGetDpId.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    assetName: assetName,
                    dpName: dpName,
                }),
            });
            const data = await response.json();
            
            return {status: response.status, error: data.error, asset_id: data.asset_id, dp_id: data.dp_id};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    }
};

export default assets_inf;
