import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    IconButton,
    Tooltip,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import EditDispensePointDialogue from './EditDispensePointDialog.js'

const EditDispensePoint = ({accountId, assetId, fluids, disabled, selectedData, fetchData = ()=>{} }) => {
    const { t, i18n } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDispensePoint = () => {
        setOpenDialog(true);
    };

    const handleClose = async () => {
        setOpenDialog(false);
    };

    return (
    <>
        <Tooltip title={t('editDispensePoint')}>
            <IconButton disabled={disabled} edge="end" aria-label="delete" onClick={() => handleOpenDispensePoint()}>
                <EditIcon />
            </IconButton>
        </Tooltip>
        {openDialog && <EditDispensePointDialogue 
            open={openDialog}
            create={false} 
            accountId={accountId} 
            assetId={assetId} 
            fluids={fluids} 
            selectedData={selectedData}
            handleClose={handleClose} 
            fetchData={fetchData}/>}
    </>
    );
}

export default EditDispensePoint;
