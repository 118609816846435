import { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useSessionstorage from '@rooks/use-sessionstorage';
// Graco dependencies
import AccountFluids from './AccountFluids/AccountFluids.jsx';
import AccountManage from './AccountManage/AccountManage.jsx';
import AccountCreate from './AccountCreate/AccountCreate.jsx';
import AccountNoAccess from './AccountNoAccess/AccountNoAccess.jsx';
import NoAccountPage from './NoAccountPage/NoAccountPage.jsx';
import AccountDevicesBilling from './AccountBilling/AccountDevicesBilling.jsx';

// Page routing for org management specific pages
const PageRouter = (props) => {
  let data = useLocation();
  
  return (
    <Switch>
      <Route exact path='/accounts/:accountName/billing'><AccountDevicesBilling/></Route>
      <Route exact path='/accounts/new'> <AccountCreate firstAccount={props.accountInfo.AccountId === "" ? true : false} accountEdit={false} 
                                                        fetchAccountInformation={props.fetchAccountInformation} /></Route>
      <Route exact path='/accounts/:acountName/edit'> <AccountCreate firstAccount={false} accountEdit={true} 
                                                        fetchAccountInformation={props.fetchAccountInformation}/> </Route>
      <Route exact path='/accounts/:accountName/manage'> <AccountManage fetchAccountInformation={props.fetchAccountInformation} /></Route>

      <Route path='/accounts'> <NoAccountPage orgInfo={props.orgInfo} /></Route>
    </Switch>
  );
}

// Org page component checks for editor privileges and routes to the appropriate page
export default function AccountPage({ fetchAccountInformation }) {
  const { value: accountInfo } = useSessionstorage('accountInfo', {});
  
  return (
    <Fragment>
      {accountInfo.isAccountEditor ? <PageRouter accountInfo={accountInfo} fetchAccountInformation={fetchAccountInformation} /> : <AccountNoAccess />}
    </Fragment>
  )
}
