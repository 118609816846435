import React, { useState, useRef, useEffect } from 'react';
import {
    useHistory,
    useParams
  } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    FormControl,
    Typography,
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';

import useSessionstorage from "@rooks/use-sessionstorage";
import DeviceTable from "../../../MainPage/Components/DeviceTable"
import devices_inf from '../../../services/devices_inf';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
        margin: theme.spacing(1),
        minWidth: 120
  }));

const AccountDevices = ({deviceData, fluidsList, dataLoading=false, refreshDeviceData=()=>{} }) => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const history = useHistory();

    const {value: accountInfo} = useSessionstorage("accountInfo",{})

    const [dataReady, setDataReady] = useState(false);
    const [openDeviceEdit, setOpenDeviceEdit] = useState(false);
    const [deviceName, setDeviceName] = useState();
    const [deviceFluid, setDeviceFluid] = useState();
    const rDeviceName = useRef();
    const [targetDevice, setTargetDevice] = useState({});
    
    const handleOpenEditDevice = (target) => {
        setOpenDeviceEdit(true);
        setDeviceName(target.friendly_name);
        setTargetDevice(target);
        if(target.fluid_id){
            setDeviceFluid(target.fluid_name);
        }
        else if(fluidsList.length){
            setDeviceFluid(fluidsList[0].name);
        }
        else{
            setDeviceFluid(undefined);
        }
    };

    const handleCloseEditDevice = () => {
        setOpenDeviceEdit(false);
        setDeviceName("");
    };

    const handleUpdateDeviceInfo = async () => {
        //var l_hasBillingInfo = hasBillingInfo ? 1 : 0;
        var l_hasBillingInfo = 0;
        let fluid_id = 0;
        for (var i=0; i < fluidsList.length; i++){
            if (deviceFluid === fluidsList[i].name){
                fluid_id = fluidsList[i].id;
            }
        }
        try{
            const { status, error } = await devices_inf.updateDevices(
                [{UID: targetDevice.uid,
                  friendly_name: deviceName,
                  fluid_id: fluid_id
                  }],
                accountInfo.accountId,
                l_hasBillingInfo
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("Device already assigned")){
                    alert(t("deviceIsAlreadyAssigned"));
                }
                else if (error.includes("Device not found")){
                    alert(t("deviceNotfound"));
                    //throw new Error(error)
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }

        }catch (error) {
                console.error(error);
            }
        handleCloseEditDevice();
        refreshDeviceData();
    }


    return (
    <Grid item container
        direction='column'>
        <Grid item xs>
            <DeviceTable deviceData={deviceData} dataLoading={false} useEdit={true} openEdit={handleOpenEditDevice}/>
        </Grid>
        <Dialog
            disableEscapeKeyDown
            open={openDeviceEdit}
            onClose={handleCloseEditDevice}>
            <DialogTitle>{t("editDevice")}</DialogTitle>
            <DialogContent>
                <Grid item container>
                    <Grid item>
                        <Typography gutterBottom variant="body2">
                            {t("edit")} {" " + t("device") + " "} {targetDevice.uid}?
                        </Typography>
                        <div />
                        <StyledFormControl>
                            <TextField label={t("name")}
                                value={deviceName}
                                inputRef={rDeviceName}
                                onChange={e => {
                                    const regex = /^([ a-zA-Z0-9\-]){0,40}$/i;
                                    if (regex.test(e.target.value)) { setDeviceName(e.target.value) }
                                }}
                                />
                            {fluidsList.length > 0 ? <TextField select
                                label={t("fluidName")}
                                value={deviceFluid}
                                onChange={e => setDeviceFluid(e.target.value)}>
                                {fluidsList.map((option) => (
                                    <MenuItem key={option.id} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            : <TextField
                                label={t("fluidName")}
                                value={t('noFluidsDefined')}
                                disabled>
                            </TextField>}
                    </StyledFormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseEditDevice} color="primary">
                    {t("cancel")}
                </Button>
                <Button variant="contained" onClick={handleUpdateDeviceInfo} color="primary">
                    {t("add")}
                </Button>
            </DialogActions>
        </Dialog>
    </Grid>);
}

export default AccountDevices;