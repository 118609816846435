import {Auth} from 'aws-amplify';
import {host} from '../data.js'

const LOCATION_ERROR_MESSAGE =
    'Error in account information service! Provide, correct data to the service!';

let urlConfig = {
    apiCreate: 'accounts/',
    apiGet: 'accounts/',
    api: 'accounts/{accountId}/',
    apiInvite: 'accounts/{accountId}/invite',
    apiRole: 'accounts/{accountId}/role',
    apiManage: 'accounts/{accountId}/manage',
    apiInvoices: 'orgs/{orgId}/invoices/{invoiceId}/pdf',
    apiPreview: 'orgs/{orgId}/billing/preview',
    //proxyPort: 5000
}

export const accounts = {
    createAccount: async (name, contactName, email, contactPhone, addressLine, country, city, region, postalCode, isDefault) => {
        try {
            if (!name || !contactName || !email || !contactPhone || !addressLine || !country || !city || !region || !postalCode ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiCreate;
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    Name: name,
                    ContactName: contactName,
                    Email: email,
                    ContactPhone: contactPhone,
                    AddressLine1: addressLine,
                    Country: country,
                    City: city,
                    State: region,
                    PostalCode: postalCode,
                    isDefault: isDefault
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateAccount: async ({accountId, name, contactName, email, contactPhone, addressLine, country, city, region, postalCode, isDefault}) => {
        try {
            if (!accountId || !name || !contactName || !email || !contactPhone || !addressLine || !country || !city || !region || !postalCode ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            
            const url =
                host + urlConfig.api.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    Name: name,
                    ContactName: contactName,
                    Email: email,
                    ContactPhone: contactPhone,
                    AddressLine1: addressLine,
                    Country: country,
                    City: city,
                    State: region,
                    PostalCode: postalCode,
                    isDefault: isDefault
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteAccount: async (accountId) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.api.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getAccounts: async () => {
        try {
            //if (!accountId) {
            //    return console.error(LOCATION_ERROR_MESSAGE);
            //}
            
            const url = host + urlConfig.apiGet;
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getAccountInfo: async (accountId) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.api.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    inviteUser: async (accountId, email, role) => {
        try {
            if (!accountId || !email || !role ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiInvite.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    Email: email,
                    Role: role                    
                }),
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateRole: async (accountId, email, role) => {
        try {
            if (!accountId || !email || !role ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiRole.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    user: email,
                    role: role                    
                }),
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteRole: async (accountId, email) => {
        try {
            if (!accountId || !email) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiRole.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    user: email,
                }),
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    manageAccount: async (accountId) => {
        try {
            if (!accountId ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.apiManage.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            
            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
};
