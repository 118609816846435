import { useEffect, useState, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    Link,
    Paper,
    TableContainer,
    Table,
    TableRow,
    Typography,
    TableHead,
    TableBody,
    TableCell,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { host } from "../data";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    indented: {
        marginLeft: theme.spacing(10),
    },
    content: {
        padding: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    formControlLabel: {
        marginLeft: theme.spacing(2),
    },
    rootpaper: {
        width: 1000,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        overflowX: "auto"
    },
    dropdown: {
        display: 'flex',
        marginLeft: 'auto',
    },
    orgpaper: {
        width: 500,
        margin: theme.spacing(1),
        overflowX: "auto"
    },
    orgtable: {
        width: 500,
        height: '40vh',
        zIndex: 0,
    },
    reportpaper: {
        width: 800,
        margin: theme.spacing(1),
        overflowX: "auto"
    },
    reporttable: {
        width: 800,
        height: '40vh',
        zIndex: 0,
    },
    table: {
        width: '100%',
        height: '72vh',
        zIndex: 0,
    },
    link: {
        color: theme.palette.info.dark,
    },
}));


const UserReportScheduleContent = ({ fetchOrgInformation }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [deleteOrgTarget, setDeleteOrgTarget] = useState("");
    const [openDeleteOrg, setOpenDeleteOrg] = useState(false);
    const [deleteReportTarget, setDeleteReportTarget] = useState({});
    const [openDeleteReport, setOpenDeleteReport] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [scheduledReportList, setScheduledReportList] = useState([]);

    const orgListColumns = [
        {
            headerName: t("orgName"),
            field: 'name',
            flex: 1,
            disableClickEventBubbling: true
        },
        {
            headerName: t("access1"),
            field: 'isEditor',
            renderCell: renderAccess,
            disableClickEventBubbling: true
        },
        {
            headerName: '',
            field: t("leaveQuestion"),
            renderCell: renderDeleteOrg,
            sortable: false,
            disableClickEventBubbling: true
        },
    ];

    function renderOrgLink(params) {
        //to={baseurl.concat('/branches/').concat(params.row.branchName).concat('/sites/').concat(params.row.branchName).concat('/tanks/').concat(params.row.tankName)}>
        if (params.value === undefined) {
            return;
        }
        return (
            <Link className={classes.link}
                component={RouterLink}
                to={"/orgs/" + params.value + "/"}>
                {params.value}
            </Link>
        );
    };

    function renderAccess(params) {
        return (
            <Typography> {params.value ? t("editor") : t("viewer")} </Typography>
        );
    };

    function handleReportLink(params) {
        let url = "/";
        url = params.module_type === "Branch" ? "/orgs/" + params.orgName + "/branches/" + params.branchName :
            "/orgs/" + params.orgName + "/branches/" + params.branchName + "/sites/" + params.siteName;
        fetchOrgInformation(params.orgName, true, url);
    };

    function renderReportLocation(params) {
        return (
            <Button onClick={() => handleReportLink(params)} color="secondary">
                {params.module_type === "Branch" ? params.branchName : params.siteName}
            </Button>
        );
    };

    useEffect(() => {
        fetchPageData();
    }, []);

    function fetchPageData() {
        Auth.currentAuthenticatedUser()
            .then(data => {

                var requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': data.signInUserSession.idToken.jwtToken
                    },
                };
                // fetch(host + '/user/', requestOptions)
                //     .then(results => results.json())
                //     .then(data => {
                //         setScheduledReportList(data.scheduleReportList);
                //     }).catch((error) => {
                //         console.log(error)
                //     })
            });
    };

    const handleOpenDeleteOrg = (target) => {
        setDeleteOrgTarget(target);
        setOpenDeleteOrg(true);
    };

    const handleCloseDeleteOrg = () => {
        setOpenDeleteOrg(false);
    };

    function renderDeleteOrg(params) {
        return (
            <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleOpenDeleteOrg(params.row)}
                size="large">
                <RemoveCircleIcon />
            </IconButton>
        );
    };

    const handleOpenDeleteReport = (target) => {
        setDeleteReportTarget(target);
        setOpenDeleteReport(true);
    };
    const handleCloseDeleteReport = () => {
        setOpenDeleteReport(false);
    };
    function renderDeleteReport(report) {
        return (
            <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleOpenDeleteReport(report)}
                size="large">
                <DeleteIcon />
            </IconButton>
        );
    };
    const handleReportDelete = () => {
        var delete_id = deleteReportTarget.id;

        handleCloseDeleteReport(false);

        Auth.currentAuthenticatedUser()
            .then(data => {

                var body = JSON.stringify(
                    {
                        "id": delete_id
                    }
                );

                var requestOptions = {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': data.signInUserSession.idToken.jwtToken
                    },
                    body: body
                };

                let url = "/user/reports/";

                // fetch(host + url, requestOptions)
                //     .then(results => results.json())
                //     .then(data => {
                //         if (data.error === "OK") {
                //             var lscheduledReportList = scheduledReportList;
                //             for (var i = 0; i < lscheduledReportList.length; i++) {
                //                 if (lscheduledReportList[i].id === delete_id) { break; }
                //             }
                //             lscheduledReportList.splice(i, 1);

                //             setScheduledReportList(lscheduledReportList);
                //             setDeleteReportTarget({});
                //         }
                //         else {
                //             alert(data.error);
                //         }
                //     }).catch((error) => {
                //         console.log(error)
                //     })
            });
    };

    function createScheduleReportLine(report) {
        var infoText = "";
        var changeDay = 0;
        const reportFrequency = report.monthly ? t("monthly") + " " : t("weekly") + " ";
        const reportType = report.module_type;
        var clientTimeZoneOffset = new Date().getTimezoneOffset() / 60;
        var reportTime = (parseInt(report.recurr_time) - clientTimeZoneOffset);

        if (reportTime > 23) {
            reportTime = reportTime - 24;
            changeDay = 1;
        }

        if (reportTime < 0) {
            reportTime = reportTime + 24;
            changeDay = -1;
        }

        reportTime = reportTime.toString() + ':00';

        var reportDaysList = [];
        if (report.sunday) { changeDay === 1 ? reportDaysList.push(t("monday")) : changeDay === -1 ? reportDaysList.push(t("saturday")) : reportDaysList.push(t("sunday")) };
        if (report.monday) { changeDay === 1 ? reportDaysList.push(t("tuesday")) : changeDay === -1 ? reportDaysList.push(t("sunday")) : reportDaysList.push(t("monday")) };
        if (report.tuesday) { changeDay === 1 ? reportDaysList.push(t("wednesday")) : changeDay === -1 ? reportDaysList.push(t("monday")) : reportDaysList.push(t("tuesday")) };
        if (report.wednesday) { changeDay === 1 ? reportDaysList.push(t("thursday")) : changeDay === -1 ? reportDaysList.push(t("tuesday")) : reportDaysList.push(t("wednesday")) };
        if (report.thursday) { changeDay === 1 ? reportDaysList.push(t("friday")) : changeDay === -1 ? reportDaysList.push(t("wednesday")) : reportDaysList.push(t("thursday")) };
        if (report.friday) { changeDay === 1 ? reportDaysList.push(t("saturday")) : changeDay === -1 ? reportDaysList.push(t("thursday")) : reportDaysList.push(t("friday")) };
        if (report.saturday) { changeDay === 1 ? reportDaysList.push(t("sunday")) : changeDay === -1 ? reportDaysList.push(t("friday")) : reportDaysList.push(t("saturday")) };
        
        var reportDays = reportDaysList.join(", ");
        
        if (report.monthly) {
            if (report.monthly_day == "FirstOfMonth" || report.monthly_day == "SecondOfMonth" || report.monthly_day == "LastOfMonth") {
                reportDays = t("theFirstOfTheMonth");
            } else {
                reportDays = t("the") + " " + t(report.monthly_week.toLowerCase()) + " " + t(report.monthly_day.toLowerCase());
            }
        }

        infoText = reportFrequency + t(reportType.toLowerCase()).toLowerCase() + " " + t("reportScheduledFor") + " " + reportTime + " " + t("on") + " ";

        return (
            <Fragment>
                <Typography>
                    {infoText}
                </Typography>
                <Typography>
                    {reportDays}
                </Typography>
            </Fragment>
        );

    };

    return (
        <Fragment>
            {scheduledReportList.length > 0 && <Grid item xs>
                <Typography variant="h6">{t("scheduledReports")}</Typography>
                <TableContainer className={classes.reportpaper} component={Paper}>
                    <Table className={classes.reportstable} aria-label="reports-table">
                        <TableHead />
                        <TableBody>
                            {scheduledReportList.map((report) => (
                                <TableRow key={report.id}>
                                    <TableCell component="th" scope="row">
                                        {renderReportLocation(report)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {createScheduleReportLine(report)}
                                    </TableCell>
                                    <TableCell align="right">{renderDeleteReport(report)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    disableEscapeKeyDown
                    open={openDeleteReport}
                    onClose={handleCloseDeleteReport}>
                    <DialogTitle>{t("deleteReport")}</DialogTitle>
                    <DialogContent>
                        <Typography>{t("areYouSure")}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteReport} variant='contained' color="secondary">
                            {t("cancel")}
                        </Button>
                        <Button onClick={handleReportDelete} color="primary">
                            {t("delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>}
            {scheduledReportList.length == 0 && <Grid container item direction='column' className={classes.content} component="span">
                <Grid item component="span">
                    <Typography component="span">
                        {t("welcomeYouCurrentlyHaveNo")}
                    </Typography>
                </Grid>
                <Grid item component="span">
                    <Typography component="span">
                        {t("toConfigureAScheduledReport")} {<AssessmentIcon padding={0} />}.
                    </Typography>
                </Grid>
            </Grid>}
        </Fragment>
    );
};

export default UserReportScheduleContent;