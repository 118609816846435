import React, { useEffect, useState, useRef } from 'react';
import {
    Link as RouterLink
} from 'react-router-dom';
import { useHistory } from 'react-router'
import { Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Toolbar,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    grow_2x: {
        flexGrow: 55,
    },
    HeaderIcon: {
        marginLeft: '5px'
    },
    Buttons: {
        margin: theme.spacing(2)
    }
}));

// Branch page component. Display the branch view.
export default function NoAccountPage() {
    const { t, i18n } = useTranslation();
    const recipient = useRef("");
    const classes = useStyles();
    const [inviteInstructionsOpen, setInviteInstructionsOpen] = useState(false);
    let history = useHistory();

    const handleInviteInstructionOpen = () => {
        setInviteInstructionsOpen(true);
    }
    const handleInviteInstructionsClose = () => {
        setInviteInstructionsOpen(false);
    }
    const handleCreateNewAccount = () => {
        history.push('/accounts/' + 'new/');
    }

    return (
        <Grid container>
            <Grid item xs>
                <Toolbar>
                    <Typography>{t("welcome")}!</Typography>
                </Toolbar>
                <Divider />
            </Grid>
            <Grid   container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    className={classes.Buttons}
                    >
                <Grid item xs>
                    <Button variant="contained" color="primary" size='large' onClick={handleCreateNewAccount}>
                        {t("createNewAccount")}
                    </Button>
                </Grid>
                <Grid item xs>
                    <Button variant="contained" color="primary" size='large' onClick={handleInviteInstructionOpen}>
                        {t("Invite")}
                    </Button>
                </Grid>
            </Grid>
            <Dialog open={inviteInstructionsOpen} onClose={handleInviteInstructionsClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("invite")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t("toInviteAUserInstructions")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleInviteInstructionsClose} color="primary">
                        {t("close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}