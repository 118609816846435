import React, { useState, useRef, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    FormControl,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

//import WiFiActivationCodeImg from '../../../OrgPage/OrgBilling/Components/Assets/WiFi_ActivationCode_ti40040.png';
import { useTranslation } from 'react-i18next';
import { devices_inf } from "../../services/devices_inf";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        marginBottom: 20
    },
    clickButtons: {
        marginRight: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    HubInfoImg: {
        maxHeight: '200px',
        marginRight: theme.spacing(2),
    },
}));

const AddDevice = ({ accountInfo, updateDevices=()=>{}, hasBillingInfo=true }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [deviceFamily, setDeviceFamily] = useState();
    const [deviceUID, setDeviceUID] = useState();
    const [targetUID, setTargetUID] = useState();
    const rdeviceUID = useRef();
    const [activationCode, setActivationCode] = useState();
    const ractivationCode = useRef();
    const [addDeviceDialogue, setAddDeviceDialogue] = useState(false);
    const [openEnterSerial, setOpenEnterSerial] = useState(false);

    const handleOpenAddDevice = async () => {
        var tDeviceUID = "";
        if (deviceUID) {
            var tDeviceUID = deviceUID.replace(/\b0+/g, "");
        }
        try{
            const { status, error, deviceData } = await devices_inf.getDevice(
                tDeviceUID,
                accountInfo.accountId                
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("Device already assigned")){
                    alert(t("deviceIsAlreadyAssigned"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }
            else{
                setDeviceFamily(deviceData.type);
                if (tDeviceUID !== "") {
                    handleCloseEnterSerial();
                    //future work Check for type/family
                    setAddDeviceDialogue(true);
                    setTargetUID(tDeviceUID.toUpperCase());
                }
                else {
                    setOpenEnterSerial(true);
                }
            }
        }catch (error) {
                console.error(error);
            }
        
    };

    const handleCloseAddDevice = () => {
        setAddDeviceDialogue(false);
    };
    const handleOpenEnterSerial = () => {
        setOpenEnterSerial(true);
    };
    const handleCloseEnterSerial = () => {
        setOpenEnterSerial(false);
        setDeviceUID("");
    };

    const handleAddDevice = async () => {
        var code = activationCode;
        if (deviceFamily === "cellular") {
            code = "";
        }
        var l_hasBillingInfo = hasBillingInfo ? 1 : 0;

        setAddDeviceDialogue(false);
        try{
            const { status, error } = await devices_inf.addDevices(
                [{UID: targetUID}],
                accountInfo.accountId,
                l_hasBillingInfo
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("Device already assigned")){
                    alert(t("deviceIsAlreadyAssigned"));
                }
                else if (error.includes("Device not found")){
                    alert(t("deviceNotfound"));
                    //throw new Error(error)
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }

        }catch (error) {
                console.error(error);
            }
        updateDevices();
    };

    return <>
        {accountInfo.isAccountEditor === true && <Button
            onClick={handleOpenEnterSerial}
            variant='contained'
            color='secondary'>
            {t("addDevice")}
        </Button>}
        {openEnterSerial && <Dialog
            disableEscapeKeyDown
            open={openEnterSerial}
            onClose={handleCloseEnterSerial}>
            <DialogTitle>{t("enterSerialNumber")}</DialogTitle>
            <DialogContent className={classes.root}>
                <FormControl className={classes.formControl}>
                    <TextField type="test" label={t("serialNumber")}
                        inputRef={rdeviceUID}
                        value={deviceUID}
                        onChange={(e) => {
                            const regex = /^([ a-zA-Z0-9]){0,30}$/i;
                            if (regex.test(e.target.value)) {
                                setDeviceUID(e.target.value.toUpperCase());
                            }
                        }
                        }
                        helperText={t("device")} />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseEnterSerial} color="primary">
                    {t("cancel")}
                </Button>
                <Button variant="contained" onClick={handleOpenAddDevice} color="primary" disabled={!deviceUID}>
                    {t("add")}
                </Button>
            </DialogActions>
        </Dialog>}
        {addDeviceDialogue && <Dialog
            disableEscapeKeyDown
            open={addDeviceDialogue}
            onClose={handleCloseAddDevice}>
            <DialogTitle>{t("addDevice")}</DialogTitle>
            <DialogContent className={classes.root}>
                <Grid item container>
                    <Grid item>
                        <Typography gutterBottom variant="body2">
                            {t("add")} {deviceFamily + " device "} {targetUID}?
                        </Typography>
                        <div />
                        {false && <Typography variant="body2">
                            {t("noteThisDeviceWillNotTake")}
                        </Typography>}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAddDevice} color="primary">
                    {t("cancel")}
                </Button>
                <Button disabled={(deviceFamily === "cellular" || deviceFamily === "pressure") && !hasBillingInfo}
                    variant="contained" onClick={handleAddDevice} color="primary">
                    {t("add")}
                </Button>
            </DialogActions>
        </Dialog>}
    </>;
}

export default AddDevice;