import React, { useEffect, useState, useRef } from 'react';
import { Auth } from 'aws-amplify';
import {
    Select,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Typography, TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

// Inline CSS
const useStyles = makeStyles((theme) => ({
    errorBox: { minHeight: theme.spacing(3) },
    text: { padding: theme.spacing(1) },
    textField: {
        marginBottom: theme.spacing(3),
    },
    grow: { flexGrow: 1 }
}));

export default function GSignIn({ authState, onStateChange, changeLanguage }) {
    const classes = useStyles();
    const cookies = new Cookies();
    const { t, i18n } = useTranslation();
    // Set state
    const [values, setValues] = React.useState({
        username: '',
        password: '',
        showPassword: false,
    });
    const [formError, setFormError] = React.useState('');
    const [loginLang, setLoginLang] = React.useState("en");

    //Handle UI interactions
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleForgotPassword = () => {
        onStateChange('forgotPassword');
    };
    const handleCreateAccount = () => {
        onStateChange('signUp');
    };
    const handleSignIn = async () => {
        // Validate fields
        if (values.username === '') {
            setFormError(t("emailCannotBeEmpty"));
            return;
        }
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.username) === false) {
            setFormError(t("invalidEmailAddress"));
            return;
        }
        if (values.password === '') {
            setFormError(t("passwordCannotBeEmpty"));
            return;
        }

        Auth.configure({
            // 'USER_SRP_AUTH' is default and ideal. 'USER_PASSWORD_AUTH' is required for account migration.
            // Todo: This should be set back when migration is complete.
            authenticationFlowType: 'USER_PASSWORD_AUTH',
        })

        // Attempt to sign in
        try {
            await Auth.signIn(values.username, values.password);
        } catch (error) {
            if (error.code === 'UserNotConfirmedException')
                onStateChange('confirmSignUp');
            else setFormError(error.message);
        }
    };

    const handleChangeLanguage = (e) => {
        cookies.set('login-lang', e.target.value, { path: '/' });
        changeLanguage(e.target.value);
        setLoginLang(e.target.value);
    };

    useEffect(() => {
        var lang = cookies.get('login-lang');
        changeLanguage(lang);
        setLoginLang(lang);
    }, [cookies.get('login-lang')]);

    return <>
        {authState === 'signIn' &&
            <>
                <Typography className={classes.text} variant="h6">{t("logInToYourAccount")}</Typography>

                <Grid item xs style={{ 'marginLeft': 325 }} >
                    <Select
                        value={loginLang}
                        native
                        onChange={handleChangeLanguage} >
                        <option value={"en"}>English</option>
                        <option value={"es"}>Español</option>
                        <option value={"fr"}>Français</option>
                    </Select>
                </Grid>

                <TextField
                    className={classes.textField} label={t("email")} type="email"
                    fullWidth value={values.username}
                    onChange={handleChange('username')}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter')
                            handleSignIn();
                    }}
                />
                <TextField
                    className={classes.textField}
                    label={t("password")}
                    type={values.showPassword ? 'text' : 'password'}
                    fullWidth
                    value={values.password}
                    onChange={handleChange('password')}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter')
                            handleSignIn();
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                size="large">
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                />
                <Grid container
                    direction='row'
                    justifyContent='flex-end'
                    alignContent='center'
                    alignItems='center'>
                    <Grid item xs={9}>
                        <div className={classes.errorBox}>
                            <Typography variant="subtitle2" color='error'>{formError}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <Button className={classes.textField} color='secondary'
                            variant='contained'
                            onClick={() => handleSignIn()}>
                            {t("logIn")}
                        </Button>
                    </Grid>
                </Grid>
                <div className={classes.text} />
                <Grid container
                    direction='row'
                    alignItems='flex-start'>
                    <Typography className={classes.text}
                        variant='body2'
                        color='textSecondary'>
                        {t("forgotYourPassword")}
                    </Typography>
                    <Button className={classes.text}
                        color='secondary'
                        component={Link}
                        onClick={() => handleForgotPassword()}>
                        {t("resetPassword")}
                    </Button>
                </Grid>
                <Grid container
                    direction='row'
                    alignItems='flex-start'>
                    <Typography className={classes.text}
                        variant='body2'
                        color='textSecondary'>
                        {t("dontHaveAnAccount")}
                    </Typography>
                    <Button className={classes.text}
                        color='secondary'
                        component={Link}
                        onClick={() => handleCreateAccount()}>
                        {t("createAccount")}
                    </Button>
                </Grid>
                {false && <Grid container
                    direction='row'
                    alignItems='flex-start'>
                    <Typography className={classes.text}
                        variant='body2'
                        color='textSecondary'>
                        {t("needSomeHelp")}
                    </Typography>
                    <Button className={classes.text}
                        target="_blank"
                        color='secondary'
                        href={"http://setup.gracopulse.com/level"}>
                        {t("setup")}
                    </Button>
                </Grid>}
            </>
        }
    </>;
}