import React, { useState, useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem,
  Toolbar,
  TextField,
  Typography,
  Tooltip
} from "@mui/material";
import { ACCESS } from "../../../data";
import { PersonAdd } from '@mui/icons-material';
import ModalPrompt from "../../../CommonComponents/ModalPrompt";
import { Auth } from 'aws-amplify';
import { host } from "../../../data";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '38px',
    marginBottom: theme.spacing(2),
  },
  selectedLabel: {
    width: '100',
    fontSize: 20,
    color: theme.palette.primary.light,
  },
  selectedName: {
    width: '100',
    fontSize: 20,
    marginLeft: theme.spacing(2),

  },
  deleteButton: {
    marginLeft: theme.spacing(2),
  },
  Button: {
    margin: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  }
}));

const ItemDelete = ({ access, selected = {}, action, inviteUser, allowItemDelete }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = () => setOpenDialog(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const deleteObj = () => {
    setOpenDialog(true);
  };
  const handleAction = () => {
    console.log(selected);
    if (selected.type === 'ACCOUNT') {
      action(selected);
    }
    setOpenDialog(false);
  };

  // Create state for the invites
  const [inviteOpen, setInviteOpen] = useState(false);
  const recipient = useRef("");

  return (
    <div className={classes.root}>
      {(access === ACCESS.OWNER) &&
        <Toolbar >
          <Typography className={classes.selectedLabel}>
            {selected.type === 'ACCOUNT' ? t("accountName")+": " 
              : selected.type === 'ASSET' ? t('assetName')+": " 
              : ''}
          </Typography>
          <Typography className={classes.selectedName}>
            {selected.name}
          </Typography>
          {(selected.type === 'ASSET' || selected.type === 'ACCOUNT') && allowItemDelete && <Tooltip title={t("delete") + " " + selected.name}>
            <IconButton
              className={classes.deleteButton}
              aria-label="delete"
              onClick={deleteObj}
              size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>}
        </Toolbar>
      }
      {openDialog && <ModalPrompt title={t('delete') + ' ' + (selected.type ? t(selected.type.toLowerCase()).toLowerCase() : '') + ' ' + selected.name + '?'}
        content={t("deleteWarning")}
        action={t("delete")}
        handleAction={handleAction}
        handleCancel={handleClose}
        openModal={openDialog}
        useConfirm={true} />}
    </div>
  );
};

export default ItemDelete;
