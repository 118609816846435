import React, { Fragment, useState } from 'react';
import { SvgIcon } from '@mui/material';
import { TreeItem, TreeView } from '@mui/lab';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';
import ModalPrompt from '../../../CommonComponents/ModalPrompt';
import { ACCESS } from '../../../data';
import { useTranslation } from 'react-i18next';

const MinusSquare = props => (
  <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
    {/* tslint:disable-next-line: max-line-length */}
    <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
  </SvgIcon>
);

const PlusSquare = props => (
  <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
    {/* tslint:disable-next-line: max-line-length */}
    <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
  </SvgIcon>
);

const useStyles = makeStyles({
  root: {
    minHeight: 110,
    flexGrow: 1,
    maxWidth: 400,
    minWidth: 200,
    marginRight: 20
  },
  treeitem: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export default function OrganizationTree({ list, selectItem, action, access }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [delAssetId, setDelAssetId] = useState(null);
  const handleClose = () => setOpenDialog(false);
  const handleDeleteAsset = () => {
    setOpenDialog(false);
    action(delAssetId);
  };
  const deleteAsset = (id) => {
    setOpenDialog(true);
    setDelAssetId(id);
  };
  
  //onClick={() => selectItem({ ...node })}
  function RenderTree({ nodes }) {
    return (
      <Fragment>
        {Array.isArray(nodes)
          ? nodes.map((node) =>
            <TreeItem key={node.id}
              nodeId={String(node.id)}
              label={node.name}
              onClick={() => { node.type === 'ASSET' && deleteAsset(node.id) }}
              icon={node.type === 'ASSET' && access === ACCESS.OWNER && <DeleteIcon />}
              onIconClick={() => { node.type === 'ASSET' && deleteAsset(node.id) }}>
              {false && Array.isArray(node.children)
                ? <RenderTree nodes={node.children} />
                : null}
            </TreeItem>)
          : null}
      </Fragment>)
  };
  return (
    <Fragment>
      <TreeView className={classes.root}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}>
        <RenderTree nodes={list} />
      </TreeView>
      {openDialog && <ModalPrompt title={t("deleteAsset")}
        content={t("areYouSure")}
        action={t("delete")}
        handleAction={handleDeleteAsset}
        handleCancel={handleClose}
        openModal={openDialog}
        useConfirm={true} />}
    </Fragment>
  );
};