import React, { useState } from 'react';
import {
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import useSessionstorage from '@rooks/use-sessionstorage';
import {
  Breadcrumbs,
  Divider,
  Grid,
  IconButton,
  Link,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack } from '@mui/icons-material';
import ItemDelete from './Components/ItemDelete';
import OrganizationTree from './Components/OrganizationTree';
import UserTable from './Components/UserTable';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { accounts } from '../../services/accounts';
import { assets_inf } from '../../services/assets_inf';
import { ACCESS, host } from "../../data";
import { isValidEmail } from '../../helpers/isValidEmail';


const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  dividerSpacing: { marginBottom: theme.spacing(2) },
}));

export default function AccountManage({ fetchAccountInformation }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [selected, setSelected] = useState({});
  const [orgTree, setOrgTree] = useState([]);
  const [allowItemDelete, setAllowItemDelete] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [moveDestinations, setMoveDestinations] = useState([]);
  const { value: accountInfo } = useSessionstorage('accountInfo', {});
  //console.log(orgTree);

  React.useEffect(() => {
    var allowDelete = false;
    var lMoveDestinations = [];
    var parentId = 0;
    //console.log(orgTree);
    if (selected.type !== undefined) {
      if (selected.type === "ACCOUNT") {
        allowDelete = (orgTree.children.length == 0 ? true : false);
      }
    }
    setAllowItemDelete(allowDelete);
  }, [selected])

  React.useEffect(() => {
    setSelected(orgTree);
  }, [orgTree]);

  React.useEffect(() => {
    //Fetch User management information
    getCurrentUser();
    fetchAccountTree();
  }, []);
  

  const getCurrentUser = async () => {
    const response = await Auth.currentAuthenticatedUser();
    setCurrentUser(response.username);
  }

  const fetchAccountTree = async () => {
    try {
      const { status, data } = await accounts.manageAccount(accountInfo.accountId);

      const { error, accountTree } = data;

      if (status === 404) {
          return;
      }
      
      if (error !== 'OK' && status !== 200) {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
      }
      setOrgTree(accountTree);

    } catch (error) {
      console.error(error);
    }
  }

  

  function isValidEnteredEmail(value) {
    if (isValidEmail(value))
      return (true);

    alert("You have entered an invalid email address!");
    return (false);
  };

  async function inviteUser(selected, recipient, permissions) {
    var selectedId = selected.id === 'root' ? accountInfo.accountId : selected.id;
    var type = selected.type;

    recipient = recipient.trim();
    recipient = recipient.trim().replace(/,{1,}$/, '');
    var recipientArr = recipient.split(',');
    var sent = false;

  }

  const removeUser = async (user_id) => {
    console.log("Delete Role");
    try {
      const { status, data } = await accounts.deleteRole(accountInfo.accountId, user_id);

      const { error } = data;

      if (status === 404) {
          return;
      }
      
      if (error !== 'OK' && status !== 200) {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
      }
      fetchAccountTree();

    } catch (error) {
      console.error(error);
    }
  }

  const setUserRole = async (user_id, newRole) => {
    console.log("Update user to " + newRole);
    //console.log(user_id);
    try {
      const { status, data } = await accounts.updateRole(accountInfo.accountId, user_id, newRole);

      const { error } = data;

      if (status === 404) {
          return;
      }
      
      if (error !== 'OK' && status !== 200) {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
      }
      

    } catch (error) {
      console.error(error);
    }
    fetchAccountTree();
  }

  const deleteModule = async deleted => {
    //setOrgTree(removeFromTree(orgTree, deleted.id, deleted.type));
    if (selected.type === "ACCOUNT"){
      setSelected({});
      try{
        const {status, data} = await accounts.deleteAccount(accountInfo.accountId);

        const { error } = data;

        if (status === 404){
          return;
        }

        if (error !== "OK" && status !==200){
          alert(t('sorryUnableToRetrieve'));
          throw new Error(error);
        }

        fetchAccountInformation("default",true);

      } catch(error){
        console.log(error);
      }
    }
    else {
      console.log(selected);      
    }
  };

  const deleteAsset = async (deleteId) => {
    const l_deleteId = deleteId;
    try {
      
      const { status, data } = await assets_inf.deleteAsset(accountInfo.accountId, l_deleteId);

      const { error } = data;

      if (status === 404) {
          return;
      }
      
      if (error !== 'OK' && status !== 200) {
          alert(t("sorryUnableToRetrieve"));
          throw new Error(error);
      }
      fetchAccountTree();

    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Grid container
      alignItems='stretch'
      direction='column'
      justifyContent='flex-start'
      className={classes.content}>
      <Grid item xs>
        <Toolbar>
          <Tooltip title={t("goHome")}>
            <IconButton color='primary' component={RouterLink} to={'/'} size="large">
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Breadcrumbs>
            <Link variant='h5'
              component={RouterLink}
              to={'/'}>
              {t("settings")}
            </Link>
            <Typography variant='h5'>
              {t("manageAccount")}
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </Grid>
      <Divider className={classes.dividerSpacing} />
      {true && <Grid item xs>
        <Grid container
          alignItems='stretch'
          direction='row'
          justifyContent='flex-start'
          spacing={1}>
          <Grid item xs>
            <ItemDelete access={ACCESS.OWNER}
              selected={orgTree}
              action={deleteModule}
              inviteUser={inviteUser}
              allowItemDelete={allowItemDelete}
              />
            {
              <UserTable access={ACCESS.OWNER}
                users={orgTree.users}
                removeUser={removeUser}
                setUserRole={setUserRole}
                currentUser={currentUser} />
            }
          </Grid>
          <Grid item xs={4}>
            <Typography>{t('deleteAsset')}</Typography>
            <OrganizationTree list={orgTree.children}
              selectItem={setSelected}
              action={deleteAsset}
              access={ACCESS.OWNER} />
          </Grid>
        </Grid>
      </Grid>}
    </Grid>
  );
};