import React, { useEffect, useState } from 'react';
import useSessionstorage from '@rooks/use-sessionstorage';
import { Link as RouterLink } from 'react-router-dom';
import {
    Breadcrumbs,
    Grid,
    IconButton,
    Link,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import FluidManagement from './Components/FluidManagement';
import fluids_inf from '../../services/fluids_inf';



export default function AccountFluids(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [fluids, setFluids] = useState([]);
    const { value: accountInfo } = useSessionstorage('accountInfo', {});

    const fetchFluids = async () => {
        try {
            console.log("fetching fluids...");
            const { status, data } = await fluids_inf.getFluids(accountInfo.accountId);
            
            const { error, fluidsList } = data;

            if (status === 404) {
                return;
            }
            
            if (error !== 'OK' && status !== 200) {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            setFluids(fluidsList);
            
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
      fetchFluids();  
    }, [accountInfo]);

    return (
        <Grid container
            direction='column'
            justifyContent='flex-start'
            alignItems='stretch'
            sx={{padding: theme.spacing(2),}}>
            <Grid item xs>
                <Toolbar>
                    <Tooltip title={t("goHome")}>
                        <IconButton color='primary' component={RouterLink} to={'/'} size="large">
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                    <Breadcrumbs>
                        <Link variant='h5'
                            component={RouterLink}
                            to={'/'}>
                            {t("settings")}
                        </Link>
                        <Typography variant="h5">
                            {t("fluids")}
                        </Typography>
                    </Breadcrumbs>
                </Toolbar>
            </Grid>
            <FluidManagement
                accountInfo={accountInfo}
                fluids={fluids}
                setFluids={setFluids}
            />
        </Grid>
    );
}