import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import ToU from './ToU.pdf'
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const useStyles = makeStyles(() => ({
    paper: { minWidth: "675px" },
}));

const TermsOfService = ({ openTerms, handleCloseTerms }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <Dialog classes={{ paper: classes.paper }} disableEscapeKeyDown open={openTerms} onClose={handleCloseTerms}>
            <DialogTitle>{t("pulseLevelTermsOfService")}</DialogTitle>
            <DialogContent>
                <div>
                    <Document
                        file={ToU}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map(page => <Page pageNumber={page} />)}
                    </Document>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseTerms} color="primary">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TermsOfService;