import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    FormControl,
} from "@mui/material";

import assets_inf from '../../../services/assets_inf.js'
import { UNIT_SETTINGS } from '../../../data.js';


const EditDispensePointDialogue = ({ open, create=false, accountId, assetId, fluids, selectedData, handleClose = ()=>{}, fetchData = ()=>{} }) => {
    const { t, i18n } = useTranslation();
    
    const [fluidName, setFluidName] = useState(selectedData.fluid_name | fluids[0].name);
    const [dpName, setDpName] = useState(selectedData.name);
    const rDpName = useRef();
    const [dpZerk, setDpZerk] = useState(selectedData.zerk_uid);
    const rDpZerk = useRef();
    const [dpDispense, setDpDispense] = useState(selectedData.dispense);
    const rDpDispense = useRef();
    const [dpInterval, setDpInterval] = useState(selectedData.interval);
    const rDpInterval = useRef();
    const [volumeUnits, setVolumeUnits] = useState(UNIT_SETTINGS.cc);
    
    React.useEffect(() => {
        var i=0;
        console.log("load selected");
        if (selectedData.volumeUnit) {
            console.log("if volumeUnits")
            for (const unit in UNIT_SETTINGS){
                if (UNIT_SETTINGS[unit].name === selectedData.volumeUnit){
                    setVolumeUnits(UNIT_SETTINGS[unit]);
                    break;
                }
            }
        }
        if (selectedData.fluid_name){
            setFluidName(selectedData.fluid_name);
        }
        if (selectedData.name){
            setDpName(selectedData.name);
        }
        if (selectedData.zerk_uid){
            setDpZerk(selectedData.zerk_uid);
        }
    },[selectedData]);

    const handleSetVolumeUnits = (newValue) => {
        for (const unit in UNIT_SETTINGS){
            if (UNIT_SETTINGS[unit].name === newValue){
                setVolumeUnits(UNIT_SETTINGS[unit]);
                break;
            }
        }
    };

    const handleAction = async () => {
        if(create){
            handleAddDispensePoint();
        }
        else{
            handleUpdateDispensePoint();
        }
    }

    const handleAddDispensePoint = async () => {
        var fluidId=fluids[0].id;
        try{
            for (var i=0; i < fluids.length; i++){
                if(fluidName===fluids[i].name){
                    fluidId = fluids[i].id;
                }
            }
            const { status, data } = await assets_inf.createDispensePoint(
                accountId,
                assetId,
                dpName,
                fluidId,
                dpZerk,
                volumeUnits.name,
                dpDispense,
                dpInterval
            );
            
            const { error } = data;

            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("name already exists")){
                    alert(t("dpNameAlreadyExists"))
                    throw new Error(error);
                }
                else if (error.includes('Zerk UID already exists')){
                    alert(t("zerkAlreadyAssigned"))
                }
                else{
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }
            fetchData();
            handleClose();
        }catch (error) {
                console.error(error);
        }

        //Fetch Asset and reload Asset Info
    };

    const handleUpdateDispensePoint = async () => {
        var fluidId=fluids[0].id;
        try{
            for (var i=0; i < fluids.length; i++){
                if(fluidName===fluids[i].name){
                    fluidId = fluids[i].id;
                }
            }
            const { status, data } = await assets_inf.updateDispensePoint(
                accountId,
                assetId,
                selectedData.id,
                dpName,
                fluidId,
                dpZerk,
                volumeUnits.name,
                dpDispense,
                dpInterval
            );
            
            const { error } = data;

            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            fetchData();
            handleClose();
        }catch (error) {
            console.error(error);
        }
    };

    return (
    <>
      {open && <Dialog
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}>
            <DialogTitle>{t("dispensePoint")}</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, minWidth: 300 },
                      width: 350,
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField type="test" label={t("name")}
                        inputRef={rDpName}
                        value={dpName}
                        onChange={(e) => {
                            const regex = /^([ a-zA-Z0-9]){0,30}$/i;
                            if (regex.test(e.target.value)) {
                                setDpName(e.target.value);
                            }
                        }}
                        />
                    <TextField type="test" label={t("dpZerkUid")}
                        inputRef={rDpZerk}
                        value={dpZerk}
                        onChange={(e) => {
                            //const regex = /^([TZ0-9]){0,14}$/i;
                            const regex = /^([ a-zA-Z0-9]){0,14}$/i;
                            if (regex.test(e.target.value)) {
                                setDpZerk(e.target.value);
                            }
                        }}
                         />
                    <FormControl>
                        {fluids.length > 0 ? <TextField select
                            label={t("fluidName")}
                            value={fluidName}
                            onChange={e => setFluidName(e.target.value)}>
                            {fluids.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                {option.name}
                                </MenuItem>
                            ))}
                        </TextField>:
                        <TextField
                            label={t("fluidName")}
                            value={t('noFluidsDefined')}
                            disabled>
                        </TextField>}
                    </FormControl>

                    <TextField select
                        label={t("volumeUnits")}
                        value={volumeUnits.name}
                        onChange={e => handleSetVolumeUnits(e.target.value)}>
                        {Object.entries(UNIT_SETTINGS).map(([key, value]) => (<MenuItem key={value.id} value={value.name}>{t(value.label)}</MenuItem>))}
                    </TextField>
                    <TextField type="test" label={t("dpDispense")}
                        inputRef={rDpDispense}
                        value={dpDispense}
                        onChange={(e) => {
                            const regex = /^([.0-9]){0,5}$/i;
                            if (regex.test(e.target.value)) {
                                setDpDispense(e.target.value);
                            }
                        }}
                        helperText={t("volume")} />
                    <TextField type="test" label={t("dpInterval")}
                        inputRef={rDpInterval}
                        value={dpInterval}
                        onChange={(e) => {
                            const regex = /^([0-9]){0,5}$/i;
                            if (regex.test(e.target.value)) {
                                setDpInterval(e.target.value);
                            }
                        }}
                        helperText={t("hours")} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("cancel")}
                </Button>
                <Button variant="contained" onClick={handleAction} color="primary" disabled={false}>
                    {create ? t("add") : t("save")}
                </Button>
            </DialogActions>
        </Dialog>}  
    </>
    );
}

export default EditDispensePointDialogue;
