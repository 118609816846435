import {Auth} from 'aws-amplify';
import {host} from '../data.js'

const LOCATION_ERROR_MESSAGE =
    'Error in account information service! Provide, correct data to the service!';

let urlConfig = {
    apiCreate: 'accounts/{accountId}/fluids/',
    api: 'accounts/{accountId}/fluids/{fluidId}',
    apiGet: 'accounts/{accountId}/fluids/'
    //proxyPort: 5000
};

export const fluids_inf = {
    createFluid: async (name, accountId) => {
        try {
            if (!name || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiCreate.replace("{accountId}", accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    name: name,
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateFluid: async (accountId, fluidId, name) => {
        try {
            if (!accountId || !name || !fluidId ) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            
            const url =
                host + urlConfig.api.replace('{accountId}', accountId).replace('{fluidId}', fluidId);
            const user = await Auth.currentAuthenticatedUser();
            
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    name: name,
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    deleteFluid: async (accountId, fluidId) => {
        try {
            if (!accountId || !fluidId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url =
                host + urlConfig.api.replace('{accountId}', accountId).replace('{fluidId}', fluidId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getFluids: async (accountId) => {
        try {
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.apiGet.replace('{accountId}', accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    }
};

export default fluids_inf;
