import React, { useState, useRef, useEffect } from 'react';
import {
    useHistory,
    useParams
  } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


import styled from '@emotion/styled';
import makeStyles from '@mui/styles/makeStyles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Toolbar,
    Tooltip,
    FormControl,
    Typography,
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid } from '@mui/x-data-grid';

import useSessionstorage from "@rooks/use-sessionstorage";

import {units_settings} from "../../data";
import { GrowDiv } from "../../styledComponentsCommon";
import AssetTableBasic from "./AssetTableBasic";
import AssetTableCollapsible from './AssetTableCollapsible';


const AssetTable = ({assetData, fluidsList, refreshData=()=>{}}) => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const history = useHistory();

    const {value: accountInfo} = useSessionstorage("accountInfo",{})

    const [dataReady, setDataReady] = useState(false);
    const [rowData,setRowData] = useState([]);
    const [filters, setFilters] = useState({});
    
    function filterData(){
        var i;
        var l_data = assetData;
        var l_rows = []
        //console.log(l_data);
        //console.log("Filter data...");
        //Filter Data
        console.log(assetData);
        //Format rows
        for (i=0; i<l_data.length; i++){
            l_data[i].key=l_data[i].asset_id;
            for (var j=0; j<l_data[i].dispensePoints.length; j++){
                l_data[i].dispensePoints[j].asset_id = l_data[i].asset_id;
                l_data[i].dispensePoints[j].key = l_data[i].dispensePoints[j].dp_id;
                l_rows.push(l_data[i].dispensePoints[j])
            }
        }
        //Calculations
        for (i=0; i<l_rows.length; i++){
            if (l_rows[i].reading_time){
                l_rows[i].ReadingTime_UTC = new Date(l_rows[i].reading_time);

                l_rows[i].daysSince = (new Date).getTime() - l_rows[i].ReadingTime_UTC.getTime();
                l_rows[i].daysSince = l_rows[i].daysSince / ( 1000 * 60 * 60 * 24 ); //Milliseconds to Days
                l_rows[i].daysSince = parseFloat(l_rows[i].daysSince.toFixed(2));

            }
            
        }
        //console.log(l_rows);
        setRowData(l_rows);
        setDataReady(true);
    };
    

    useEffect(() => {
        if (assetData.length){
            filterData()
        }
    },[assetData, filters]);

    return <>
        <Grid item container 
            direction='column'
            justifyContent='flex-start'
            alignItems='stretch'
            >
            <Grid item xs>
                <Toolbar>
                    <GrowDiv/>
                    <Tooltip title={'refresh'}>
                        <IconButton color='primary' onClick={refreshData}>
                            <RefreshIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'filter'}>
                        <IconButton color='primary' onClick={() => {}}>
                            <FilterListIcon/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </Grid>
            <Grid item xs>
                {false && <AssetTableBasic rowData={rowData}/>}
                {dataReady && <AssetTableCollapsible assetData={assetData}/>}
            </Grid>
        </Grid>
    </>;
}

export default AssetTable;