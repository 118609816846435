import React, { useState, useRef, useEffect } from 'react';
import {
    useHistory,
    useParams
  } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Skeleton,
    Stack,
    TextField,
    Toolbar,
    Tooltip,
    FormControl,
    Typography,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid } from '@mui/x-data-grid';

import useSessionstorage from "@rooks/use-sessionstorage";

import { devices_inf } from "../../services/devices_inf";
import { UNIT_SETTINGS } from '../../data';
import { StyledPageTitle, StyledPageLabel, GrowDiv } from '../../styledComponentsCommon';


const DeviceHistory = (props) => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const history = useHistory();

    const paramDeviceId = params.deviceId === undefined ? null : params.deviceId;
    const {value: accountInfo} = useSessionstorage("accountInfo",{})

    const [dataReady, setDataReady] = useState(false);
    
    const [deviceId, setDeviceId] = useState(paramDeviceId);
    
    const [deviceHistoryData, setDeviceHistoryData] = useState({})
    const [rowData, setRowData] = useState([]);
    const [displayUnits, setDisplayUnits] = useState('cu_in');
    
    const deviceHistoryColumns = [
        {
            field: 'ReadingTime_UTC', 
            headerName: t('lastReading'),
            type: 'dateTime', 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 190
        },
        {
            field: 'ServerTime_UTC', 
            headerName: t('reportTime'),
            type: 'dateTime', 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 190
        },
        {
            field: 'amount_disp', 
            headerName: t('dispensed'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'fluid_name', 
            headerName: t('fluid'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 180
        },
        {
            field: 'zerk_uid', 
            headerName: t('zerk'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 180
        },
        {
            field: 'asset_name', 
            headerName: t('asset'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'dp_name', 
            headerName: t('dispensePoint'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            headerName: t('edit'),
            field: 'editField',
            renderCell: renderEdit,
            sortable: false,
            headerClassName: 'default-table-theme--header',
            disableClickEventBubbling: true
        },
    ];
    const [sortModel, setSortModel] = useState([
        {
          field: 'ReadingTime_UTC',
          sort: 'desc',
        },
      ]);

    function renderEdit(params) {
        return (
            <>
            <IconButton disabled>
                <EditIcon/>
            </IconButton>
            </>
        );
    };


    const getDeviceHistory = async () => {
        console.log(deviceId);
        try{
            const { status, error, deviceHistory } = await devices_inf.getDeviceHistory(
                deviceId,
                accountInfo.accountId,
            );
            
            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("Device already assigned")){
                    alert(t("deviceIsAlreadyAssigned"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }
            setDeviceHistoryData(deviceHistory);
            console.log("data fetched!");
        }catch (error) {
                console.error(error);
        }
        
    };

    const handleExit = () => {
        console.log("handleExit");
        //let url = { pathname: ('/accounts/').concat(accountInfo.accountName)};    
        let url = { pathname: '/' };    
        history.push(url);
    };

    useEffect(() => {
        if (deviceId) {
          getDeviceHistory();
        }
    }, [deviceId]);

    useEffect (() => {
        var i;
        var l_rows = deviceHistoryData;
        for (i=0; i<l_rows.length; i++){
            if (l_rows[i].reading_time){
                l_rows[i].ReadingTime_UTC = new Date(l_rows[i].reading_time);
            }
            if (l_rows[i].server_time){
                l_rows[i].ServerTime_UTC = new Date(l_rows[i].server_time);
            }
            if (l_rows[i].amount){
                l_rows[i].amount_disp = l_rows[i].amount + ' ' + t(UNIT_SETTINGS[displayUnits].label);
            }
        }
        setRowData(l_rows);
        setDataReady(true);
    }, [deviceHistoryData]);

    return <>
        <Grid item container 
            direction='column'
            justifyContent='flex-start'
            //alignItems='stretch'
            >
            <Grid item xs>
                <Toolbar>
                    <Tooltip title={t("goBack")}>
                        <IconButton color='primary' onClick={handleExit} size="large">
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>

                    <Button onClick={handleExit} color="primary">
                        {t("goBack")}
                    </Button>
                    <StyledPageTitle variant="h5"> {t('deviceHistory')}</StyledPageTitle>
                    <Tooltip title={'refresh'}>
                        <IconButton color='primary' onClick={getDeviceHistory}>
                            <RefreshIcon/>
                        </IconButton>
                    </Tooltip>
                    <GrowDiv/>
                </Toolbar>
            </Grid>
            <Grid item xs>
                
            </Grid>
            <Divider />
            <Toolbar>
                <StyledPageLabel>{"{label}:".replace("{label}",t("deviceUidLabel"))}</StyledPageLabel>
                <Typography>{deviceId}</Typography>
            </Toolbar>
            <Grid item xs >
                <Box 
                    sx={{
                            padding: "10px",
                            width: '100%',
                            margin: '0 auto',
                            maxWidth: '1200px',
                            height: '75vh',
                            '& .default-table-theme--header': {
                                backgroundColor: '#555555',
                                color: '#FFFFFF'
                            },
                        }}
                    >
                    {(dataReady) ? <DataGrid  sx={{
                            '& .MuiDataGrid-sortIcon':{
                                color: '#fff'
                            },}}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            console.log("model change?");
                            setSortModel(model);
                        }}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        pageSize={100}
                        rows={rowData}
                        columns={deviceHistoryColumns} />
                    :
                    <Stack spacing={1}><Skeleton variant="rectangular" width={'100%'}/><Skeleton variant="rectangular" width={'100%'} height={500}/></Stack>}
                </Box>
            </Grid>
        </Grid>
    </>;
}

export default DeviceHistory;