import { useEffect, useState, useRef, Fragment } from 'react';
import Cookies from 'universal-cookie';
import {
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    TextField,
    useMediaQuery,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Edit } from '@mui/icons-material/';
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'

import { useTranslation } from 'react-i18next';
import fluids_inf from '../../../services/fluids_inf';


const FluidManagement = ({ accountInfo, fluids, setFluids }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery('(max-width:600px)');
    const [openAddFluid, setOpenAddFluid] = useState(false);
    const [deleteFluidTarget, setDeleteFluidTarget] = useState({ 'id': 0, 'name': '' });
    const [openDeleteFluid, setOpenDeleteFluid] = useState(false);
    const [editFluidTarget, setEditFluidTarget] = useState({ 'id': 0, 'name': '' });
    const [editFluidName, setEditFluidName] = useState("");
    const [openFluidEdit, setOpenFluidEdit] = useState(false);
    const rnewFluidName = useRef("");
    const [fluidCol, setFluidCol] = useState([]);
    
    const fluidColumns = [
        {
            headerName: t("fluidName"),
            field: 'name',
            minWidth: 200,
            flex: 1,
            headerClassName: 'default-table-theme--header',
            disableClickEventBubbling: true
        },
        {
            headerName: t("fluidUID"),
            field: 'id',
            minWidth: 100,
            headerClassName: 'default-table-theme--header',
            disableClickEventBubbling: true
        },
        {
            headerName: t('edit'),
            field: t('edit'),
            renderCell: renderFluidEdit,
            width: 100,
            sortable: false,
            headerClassName: 'default-table-theme--header',
            disableClickEventBubbling: true
        },
        {
            headerName: t('delete'),
            field: t('delete'),
            renderCell: renderDeleteFluid,
            width: 100,
            sortable: false,
            headerClassName: 'default-table-theme--header',
            disableClickEventBubbling: true
        },
    ];


    useEffect(() => {
        setFluidCol(fluidColumns);
    }, []);

    const mapFunctionSetWidth = (width) => (el, index) => {
        const { flex, ...rest } = el;
        if (index !== 0) {
            return { ...rest, width }
        }
        return el;
    };

    useEffect(() => {
        if (matches) {
            setFluidCol(prevState => prevState.map(mapFunctionSetWidth(140)));
        }
    }, [matches]);

    useEffect(() => {
        var maxFluidNameLength = 13;
        if (fluids !== undefined) {
            for (var i = 0; i < fluids.length; i++) {
                try {
                    if (fluids[i].name.length > maxFluidNameLength) {
                        maxFluidNameLength = fluids[i].name.length;
                    }
                } catch (error) {
                }
            }
        }
        setFluidCol(prevState => prevState.map((el, index) => {
            if (index === 0) {
                return { ...el, width: maxFluidNameLength * 9 + 9 }
            }
            return el;
        }));
    }, [fluids])


    const handleOpenDeleteFluid = (target) => {
        setDeleteFluidTarget(target);
        setOpenDeleteFluid(true);
    };

    const handleCloseDeleteFluid = () => {
        setOpenDeleteFluid(false);
    };

    function renderDeleteFluid(params) {
        var bdisable = params.row.tankCount > 0 ? true : false;
        return (
            <IconButton
                edge="end"
                aria-label="delete"
                disabled={bdisable}
                onClick={() => handleOpenDeleteFluid(params.row)}
                size="large">
                <DeleteIcon />
            </IconButton>
        );
    };

    const handleFluidDelete = async () => {
        var delete_id = deleteFluidTarget.id;
        handleCloseDeleteFluid(false);

        try {
            const { status, data } = await fluids_inf.deleteFluid(accountInfo.accountId, delete_id);

            const { error, fluidsList } = data;

            if (status === 404) {
                return;
            }
            
            if (error !== 'OK' && status !== 200) {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            setFluids(fluidsList);

        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenEditFluid = (target) => {
        setOpenFluidEdit(true);
        setEditFluidName(target.name);
        setEditFluidTarget(target);
    };

    const handleCloseFluidEdit = () => {
        setOpenFluidEdit(false);
        setEditFluidName("");
    };

    function renderFluidEdit(params) {
        return (
            <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => handleOpenEditFluid(params.row)}
                size="large">
                <Edit />
            </IconButton>
        );
    };

    const handleFluidEdit = async () => {
        var newName = editFluidName;
        setOpenFluidEdit(false);

        try {
            const { status, data } = await fluids_inf.updateFluid(accountInfo.accountId, editFluidTarget.id, newName );

            const { error, fluidsList } = data;

            if (status === 404) {
                return;
            }
            
            if (error !== 'OK' && status !== 200) {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            setFluids(data.fluidsList);            
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenAddFluid = () => {
        setOpenAddFluid(true);
        setEditFluidTarget("");
    };

    const handleCloseAddFluid = () => {
        setOpenAddFluid(false);
    };

    const handleAddFluid = async () => {
        var newName = editFluidName;
        setOpenAddFluid(false);
        try {
            const { status, data } = await fluids_inf.createFluid(newName, accountInfo.accountId);

            const { error, fluidsList } = data;

            if (status === 404) {
                return;
            }
            
            if (error !== 'OK' && status !== 200) {
                alert(t("sorryUnableToRetrieve"));
                throw new Error(error);
            }
            setFluids(data.fluidsList);
            
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Grid item container
            direction='column'
            justifyContent="flex-start"
            >
            <Grid item xs>
                <Toolbar>
                    <Typography variant='h6'>
                        {t("fluidTable")}
                    </Typography>
                    <Box flexGrow={1}/>
                    <Button onClick={handleOpenAddFluid} variant='contained' color='secondary'>
                        {t('addFluid')}
                    </Button>
                </Toolbar>
            </Grid>

            <Grid item xs>
                <Box 
                    sx={{
                        //width: '100%',
                        margin: '0 auto',
                        maxWidth: '640px',
                        height: '55vh',
                        '& .default-table-theme--header': {
                            backgroundColor: '#555555',
                            color: '#FFFFFF'
                        },
                    }}>
                    <DataGrid 
                        sx={{
                            '& .MuiDataGrid-sortIcon':{
                                //color: 'primary.contrastText'
                                color: '#fff'
                            },
                        }}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        pageSize={100}
                        rows={fluids}
                        columns={fluidCol} />
                </Box>
            </Grid>

            <Dialog disableEscapeKeyDown open={openFluidEdit} onClose={handleCloseFluidEdit}>
                <DialogTitle>{t("editFluid") + ' '}{editFluidTarget.name}</DialogTitle>
                <DialogContent>
                    <form>
                        <FormControl 
                            sx={{   margin: theme.spacing(1),
                                    minWidth: 120}} >
                            <TextField label={t("fluidName")}
                                value={editFluidName}
                                onChange={e => {
                                    const regex = /^([ a-zA-Z0-9\-]){0,40}$/i;
                                    if (regex.test(e.target.value)) { setEditFluidName(e.target.value) }
                                }}
                                inputRef={rnewFluidName} />
                        </FormControl>
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseFluidEdit} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleFluidEdit} variant="contained" color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                disableEscapeKeyDown
                open={openDeleteFluid}
                onClose={handleCloseDeleteFluid}>
                <DialogTitle>{t("deleteFluid") + ' '}{deleteFluidTarget.name}</DialogTitle>
                <DialogContent>
                    <Typography>{t("areYouSure")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteFluid} variant="contained" color="secondary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleFluidDelete} color="primary">
                        {t("delete")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog disableEscapeKeyDown open={openAddFluid} onClose={handleCloseAddFluid}>
                <DialogTitle>{t('newFluid')}</DialogTitle>
                <DialogContent>
                    <form>
                        <FormControl  
                            sx={{   
                                    margin: theme.spacing(1),
                                    minWidth: 120}} >
                            <TextField label={t("fluidName")}
                                value={editFluidName}
                                onChange={e => {
                                    const regex = /^([ a-zA-Z0-9\-]){0,40}$/i;
                                    if (regex.test(e.target.value)) { setEditFluidName(e.target.value) }
                                }}
                                inputRef={rnewFluidName} />
                        </FormControl>
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseAddFluid} color="primary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleAddFluid} variant="contained" color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default FluidManagement;