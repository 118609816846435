import React, { useRef } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import useSessionstorage from '@rooks/use-sessionstorage';
import {
    AppBar,
    Box,
    Button,
    ClickAwayListener,
    Divider,
    Grow,
    Hidden,
    IconButton,
    Link,
    ListItemIcon,
    MenuItem, MenuList,
    Paper,
    Popper,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import {
    AccountCircle,
    ArrowDropDown,
    Settings,
    Edit,
} from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import Badge from '@mui/material/Badge';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HelpIcon from '@mui/icons-material/Help';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ListIcon from '@mui/icons-material/List';
import OpacityIcon from '@mui/icons-material/Opacity';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import logoPulse from './assets/Graco_Logo_Horiz_White.png';
import logoPulseMobile from './assets/Graco_Logo_Horiz_White.png';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import DialogContentText from '@mui/material/DialogContentText';
import Rating from '@mui/material/Rating';
import Slide from '@mui/material/Slide';
import { useTranslation } from 'react-i18next';
import { GrowDiv } from '../styledComponentsCommon';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const logo = logoPulse;
const logoMobile = logoPulseMobile;

const useStyles = makeStyles((theme) => ({
    logo: {
        maxHeight: '50px',
        marginRight: theme.spacing(2),
    },
    logoMobile: {
        width: '30vw',
        maxWidth: '145px',
        marginRight: theme.spacing(1) / 2,
    },
    title: {
        marginLeft: theme.spacing(2),
        display: 'none',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
            //maxWidth: 177
        },
    },
    title_small: {
        marginLeft: theme.spacing(2),
        display: 'none',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
            maxWidth: 177
        },
    },
    icon_link: {
        display: 'none',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
        },
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    HeaderIcon: {
        marginLeft: '5px'
    },
}));

export default function Header({ accountInfo, accounts, accountNavigationHandler }) {
    const classes = useStyles();
    const userFeedback = useRef("");
    const [accountOpen, setAccountOpen] = React.useState(false);
    const [accountAnchor, setAccountAnchor] = React.useState(null);
    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const [settingsAnchor, setSettingsAnchor] = React.useState(null);
    const [openThankYou, setOpenThankYou] = React.useState(false);
    const [feedbackMenuOpen, setFeedbackMenuOpen] = React.useState(false);
    const [feedbackSubjectRadio, setFeedbackSubjectRadio] = React.useState("");
    const [userRating, setUserRating] = React.useState(null);
    const { t, i18n } = useTranslation();
    //const { value: accountInfo } = useSessionstorage('accountInfo', {});

    const handleAccountOpen = (event) => {
        setAccountAnchor(event.currentTarget);
        setAccountOpen(true);
    };

    const handleAccountClose = () => {
        setAccountOpen(false);
    };

    const handleAccountClick = (selected) => {
        handleAccountClose();
        accountNavigationHandler(selected);
    };

    const handleSettingsOpen = (event) => {
        setSettingsAnchor(event.currentTarget);
        setSettingsOpen(true);
    };

    const handleSettingsClose = () => {
        setSettingsOpen(false);
    };

    const handleSignOut = () => {
        Auth.signOut();
    };

    const handleClickOpen = () => {
        setFeedbackSubjectRadio(null);
        setUserRating(null);
        setFeedbackMenuOpen(true);
    };

    const handleFeedbackClose = () => {
        setFeedbackMenuOpen(false);
    };

    const handleFeedbackSend = async () => {
        if (userFeedback.current.value != "") {
            let user = await Auth.currentAuthenticatedUser();
            user.getSession((err, session) => {
                if (err) {
                    console.log(err);
                    return;
                }

                const sessionToken = session.getIdToken().jwtToken;

                var body = JSON.stringify(
                    {
                        'Subject': feedbackSubjectRadio,
                        'FeedbackText': userFeedback.current.value,
                        'Rating': userRating,
                    }
                );

                var requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': sessionToken
                    },
                    body: body
                };

            //     fetch(host + '/user/feedback', requestOptions)
            //         .then(results => results.json())
            //         .then(data => {
            //         });
            });

            setFeedbackMenuOpen(false);
            setOpenThankYou(true);
        } else {
            userFeedback.current.focus();
        }
    };

    const handleThankYouClose = () => {
        setOpenThankYou(false);
    };

    return (
        <Box sx={{height: '64px'}} flexGrow={1}>
            <AppBar position='static'>
                <Toolbar >
                    <Hidden mdDown>
                        <Button
                            component={RouterLink}
                            to={"/"}
                        >
                            <img edge='start'
                                alt='Graco'
                                className={classes.logo}
                                src={logo} />
                        </Button>
                    </Hidden>

                    <Hidden mdUp>
                        <Button
                            component={RouterLink}
                            to={"/"}
                        >
                            <img edge='start'
                                alt='Graco'
                                className={classes.logoMobile}
                                src={logoMobile} />
                        </Button>
                    </Hidden>
                    <Hidden lgUp>
                        <Tooltip title={t("selectAccount")}>
                            <IconButton
                                aria-controls={accountOpen ? 'org-list-grow' : undefined}
                                edge='start'
                                aria-haspopup='true'
                                color='inherit'
                                onClick={handleAccountOpen}
                                size="large">
                                <Typography className={classes.title_small} variant='h6' noWrap>
                                    {accountInfo.accountName}
                                </Typography>
                                <ArrowDropDown />
                            </IconButton>
                        </Tooltip>
                    </Hidden>
                    <Hidden lgDown>
                        <Tooltip title={t("selectAccount")}>
                            <IconButton
                                aria-controls={accountOpen ? 'org-list-grow' : undefined}
                                edge='start'
                                aria-haspopup='true'
                                color='inherit'
                                onClick={handleAccountOpen}
                                size="large">
                                <Typography className={classes.title} variant='h6' noWrap>
                                    {accountInfo.accountName}
                                </Typography>
                                <ArrowDropDown />
                            </IconButton>
                        </Tooltip>
                    </Hidden>

                    <Popper open={accountOpen}
                        anchorEl={accountAnchor}
                        placement='bottom-start'
                        transition>

                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleAccountClose}>
                                        <MenuList autoFocusItem={accountOpen} id='org-list-grow'>
                                            {accountInfo.isAccountEditor && accountInfo.accountName !== "" && <MenuItem onClick={() => { setAccountOpen(false) }} component={RouterLink} to={{ pathname: '/accounts/new', state: { accountInfo: { accountInfo }, accountEdit: true } }}>
                                                <ListItemIcon style={{ minWidth: '30px' }}>
                                                    <Edit />
                                                </ListItemIcon>
                                                {accountInfo.accountName}
                                            </MenuItem>}
                                            {accountInfo.isAccountEditor && <Divider />}
                                            {accounts !== undefined
                                                ? accounts.map((name, index) =>
                                                    <MenuItem key={index} value={name} onClick={() => handleAccountClick(accounts[index])}>
                                                        {name}
                                                    </MenuItem>)
                                                : null}
                                            <Divider />
                                            <MenuItem component={RouterLink} to='/accounts/new'>
                                                + {t("createNewAccount")}
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>)}
                    </Popper>
                    <GrowDiv />
                    {false && <Hidden mdUp>
                        <Tooltip title={accountInfo.accountName}>
                            <IconButton
                                className={classes.icon_link}
                                aria-controls={accountOpen ? 'org-list-grow' : undefined}
                                edge='start'
                                aria-haspopup='true'
                                color='inherit'
                                onClick={handleAccountOpen}
                                size="large">
                                <BusinessIcon />
                            </IconButton>
                        </Tooltip>
                        <GrowDiv />
                    </Hidden>}

                    {false && accountInfo.isAccountEditor && <Hidden mdDown>
                        <IconButton
                            color='inherit'
                            className={classes.icon_link}
                            component={RouterLink}
                            to={{
                                pathname: '/accounts/' + accountInfo.accountName + '/details',
                                state: {
                                    accountInfo: { accountInfo },
                                    accountEdit: true
                                }
                            }}
                            size="large">
                            <DashboardIcon />
                            <Typography className={classes.HeaderIcon}>
                                {t("dashboard")}
                            </Typography>
                        </IconButton>
                    </Hidden>}
                    {false && accountInfo.isAccountEditor && <Hidden mdUp>
                        <Tooltip title={t("dashboard")}>
                            <IconButton
                                color='inherit'
                                className={classes.icon_link}
                                component={RouterLink}
                                to={{
                                    pathname: '/accounts/' + accountInfo.accountName + '/details',
                                    state: {
                                        accountInfo: { accountInfo },
                                        accountEdit: true
                                    }
                                }}
                                size="large">
                                <DashboardIcon />
                            </IconButton>
                        </Tooltip>
                    </Hidden>}
                    {false && accountInfo.isAccountEditor && <GrowDiv/>}
                    {accountInfo.isAccountEditor && <Hidden mdDown>
                        <IconButton
                            component={RouterLink}
                            to={"/accounts/" + accountInfo.accountName + "/billing"}
                            size='small'
                            color='inherit'>
                            <PlaylistAddIcon />
                            <Typography className={classes.HeaderIcon}>
                                {t("devices")}
                            </Typography>
                        </IconButton>
                    </Hidden>}
                    {false && accountInfo.isAccountEditor && <Hidden mdUp>
                        <Tooltip title={t("devices")}>
                            <IconButton
                                component={RouterLink}
                                to={"/accounts/" + accountInfo.accountName + "/billing"}
                                size='small'
                                color='inherit'>
                                <PlaylistAddIcon />
                            </IconButton>
                        </Tooltip>
                    </Hidden>}

                    <GrowDiv/>
                    {false && <Hidden mdDown>
                        <Tooltip title={t("sendUsFeedback")}>
                            <Button sx={{
                                marginRight: '20px',
                                color: 'white',
                                borderColor: 'white',}} 
                                variant="outlined" onClick={handleClickOpen}>
                                {t("sendUsFeedback")}
                            </Button>
                        </Tooltip>
                    </Hidden>}

                    <Dialog open={feedbackMenuOpen} onClose={handleFeedbackClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{t("sendUsFeedback")}</DialogTitle>
                        <DialogContent>

                            <DialogContentText >
                                {t("feedbackStr1")}
                            </DialogContentText>

                            <Rating
                                defaultValue={0}
                                precision={0.5}
                                size="large"
                                style={{ marginBottom: '20px' }}
                                onChange={e => setUserRating(e.target.value)}
                            />

                            <DialogContentText>
                                {t("feedbackStr2")}
                            </DialogContentText>

                            <RadioGroup
                                style={{ marginBottom: '20px' }}
                                value={feedbackSubjectRadio}
                                onChange={e => setFeedbackSubjectRadio(e.target.value)}
                            >
                                <FormControlLabel value="devices" control={<Radio />} label={t("setup")} />
                                <FormControlLabel value="charting" control={<Radio />} label={t("mapping")} />
                                <FormControlLabel value="data" control={<Radio />} label={t("dataVisualization")} />
                                <FormControlLabel value="analysis" control={<Radio />} label={t("dataAnalysis")} />
                                <FormControlLabel value="reporting" control={<Radio />} label={t("reports")} />
                                <FormControlLabel value="other" control={<Radio />} label={t("other")} />
                            </RadioGroup>

                            <DialogContentText>
                                {t("feedbackStr3")}
                            </DialogContentText>

                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                // autoFocus
                                variant="filled"
                                inputRef={userFeedback}
                                inputProps={{ maxLength: 250 }}
                            />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleFeedbackClose} color="primary">
                                {t("cancel")}
                            </Button>
                            <Button onClick={handleFeedbackSend} color="secondary">
                                {t("send")}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={openThankYou}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleThankYouClose}
                    >
                        <DialogTitle>{t("thankYou")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t("feedbackStr4")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleThankYouClose} color="secondary">
                                {t("close")}
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Tooltip title={t("settings")}>
                        <IconButton
                            edge='end'
                            aria-controls={settingsOpen ? 'settings-list-grow' : undefined}
                            ria-haspopup='true'
                            onClick={handleSettingsOpen}
                            color='inherit'
                            size="large">
                            <Settings />
                        </IconButton>
                    </Tooltip>

                    <Popper open={settingsOpen}
                        anchorEl={settingsAnchor}
                        placement='bottom-end'
                        transition>
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleSettingsClose}>
                                        <MenuList autoFocusItem={settingsOpen} id='settings-list-grow'>
                                            <Hidden mdUp>
                                                {accountInfo.isAccountEditor && <MenuItem component={RouterLink}
                                                    to={{
                                                        pathname: '/accounts/' + accountInfo.accountName + '/details',
                                                        state: {
                                                            accountInfo: { accountInfo },
                                                            accountEdit: true
                                                        }
                                                    }} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <DashboardIcon />
                                                    </ListItemIcon>
                                                    {t("dashboard")}
                                                </MenuItem>}
                                                <Divider />
                                            </Hidden>
                                            {false
                                                ? <MenuItem component={RouterLink} to={'/accounts/'.concat(accountInfo.accountName)} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <SettingsApplicationsIcon />
                                                    </ListItemIcon>
                                                    {t("settings")}
                                                </MenuItem>
                                                : null}
                                            <MenuItem component={RouterLink} to='/profile' onClick={handleSettingsClose}>
                                                <ListItemIcon>
                                                    <AccountCircle fontSize="small" />
                                                </ListItemIcon>
                                                {t("userSettings")}
                                            </MenuItem>
                                            <Divider />
                                            {accountInfo.isAccountEditor
                                                ? <MenuItem component={RouterLink} to={'/accounts/'.concat(accountInfo.accountName).concat('/Manage')} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <AccountTreeIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    {t("permissionManagement")}
                                                </MenuItem>
                                                : null}
                                            {accountInfo.isAccountEditor
                                                ? <MenuItem component={RouterLink} to={'/accounts/'.concat(accountInfo.accountName).concat('/Billing')} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <ListIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    {t("deviceBilling")}
                                                </MenuItem>
                                                : null}
                                            {accountInfo.isAccountEditor
                                                ? <MenuItem component={RouterLink} to={'/accounts/'.concat(accountInfo.accountName).concat('/Fluids')} onClick={handleSettingsClose}>
                                                    <ListItemIcon>
                                                        <OpacityIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    {t("fluids")}
                                                </MenuItem>
                                                : null}

                                            <MenuItem component={RouterLink} to='/help' onClick={handleSettingsClose}>
                                                <ListItemIcon>
                                                    <HelpIcon fontSize="small" />
                                                </ListItemIcon>
                                                {t("help")}
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem component={Link} href='/' onClick={handleSignOut}>
                                                <ListItemIcon>
                                                    <ExitToAppIcon fontSize="small" />
                                                </ListItemIcon>
                                                {t("logOut")}
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>)}
                    </Popper>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
