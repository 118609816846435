
import React, { useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import {
    Button,
    Input,
    InputAdornment,
    InputLabel,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: "400px",
}));

export default function ChangePasswordButton({setFormError}){
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    
    const [ref1Focus, setRef1Focus] = React.useState(false);
    const [ref2Focus, setRef2Focus] = React.useState(false);
    const [position, setPosition] = React.useState(0);
    const [changePWord, setChangePWord] = useState(false);
    const [values, setValues] = useState({
        given_name: '',
        family_name: '',
        phone_number: '',
        old_password: '',
        new_password: '',
        showPassword: false,
    });
    const [valuesOld, setValuesOld] = useState({
        given_name: '',
        family_name: '',
        phone_number: '',
        old_password: '',
        new_password: '',
        showPassword: false,
    });
    const handleChange = (prop) => (event) => {
        setPosition(event.target.value.slice(0, event.target.selectionStart).length);
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickChangePWord = () => {
        if (changePWord) { setChangePWord(false) }
        else { setChangePWord(true) };
    };

    const handleClickCancelChangePWord = () => {
        setChangePWord(false);
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const handleClickSubmitChangePWord = async () => {
        if (values.old_password === ''
            || values.new_password === '') {
            setFormError(t("youCannotChangeYourPassword"));
            return;
        }
        try {
            let user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(user, values.old_password, values.new_password);
            setChangePWord(false);
            setFormError(t("passwordUpdated"));
        } catch (error) {
            setFormError(error.message);
        }
    };
    return(
        <Grid item xs>
            {!changePWord ? 
                <Button 
                    sx={{
                        width: '30ch',
                        marginLeft: theme.spacing(6),
                        marginTop: theme.spacing(2),
                    }}
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={handleClickChangePWord}>
                    {t("changePassword")}
                </Button> 
            : <React.Fragment>
                            <Grid container
                                direction='row'
                                alignItems='center'>
                                <Grid item xs>
                                    <StyledFormControl>
                                        <InputLabel htmlFor='old-password-entry'>
                                            {t("currentPassword_ast")}
                                        </InputLabel>
                                        <Input id='old-password-entry'
                                            autoFocus={ref1Focus ? true : false}
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.old_password}
                                            onChange={handleChange('old_password')}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        size="large">
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>} />
                                    </StyledFormControl>
                                </Grid>
                                <Grid item xs component="span"><span sx={{flexGrow: 1}} /></Grid>
                            </Grid>
                            <Grid container
                                direction='row'
                                alignItems='center'
                                >
                                <Grid item xs></Grid>
                                <Grid item xs>
                                    <StyledFormControl >
                                        <InputLabel htmlFor='new-password-entry'>
                                            {t("newPassword_ast")}
                                        </InputLabel>
                                        <Input id='new-password-entry'
                                            autoFocus={ref2Focus ? true : false}
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.new_password}
                                            onChange={handleChange('new_password')}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        size="large">
                                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>} />
                                    </StyledFormControl>
                                </Grid>
                                <Grid item xs component="span"><span sx={{flexGrow:1}} /></Grid>
                            </Grid>
                            <Grid container
                                direction='row'
                                alignItems='center'>
                                <Grid item xs>
                                <Button sx={{marginTop: '10px',
                                                marginBottom: '10px',
                                                width: '25ch',}}
                                        color="primary"
                                        size="small"
                                        variant="default"
                                        onClick={handleClickCancelChangePWord}>
                                        {t("cancel")}
                                    </Button>
                                </Grid>
                                <Grid item xs>
                                    <Button sx={{marginTop: '10px',
                                                marginBottom: '10px',
                                                width: '25ch',}}
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClickSubmitChangePWord}>
                                        {t("submit")}
                                    </Button>
                                </Grid>
                                <Grid item xs component="span"><span sx={{flexGrow:1}} /></Grid>
                            </Grid>
                        </React.Fragment>
                    }
        </Grid>
    );
}