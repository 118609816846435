import {STATUSES, DISPENSE_TOLERANCE} from '../data';

export function calculateIntervalError (maxInterval,daysSince){
    var status = STATUSES.ok;
    if (maxInterval < daysSince) {
      status=STATUSES.overdue;
    }
    return status;
  }

export function calculateGreaseError (fluid_id, dispense_fluid_id){
    return fluid_id === dispense_fluid_id ? STATUSES.ok : STATUSES.wrongGrease;
  }

export function calculateDispenseAmountWarning (target, dispensed){
    var status = STATUSES.ok;
    if (Math.abs((target - dispensed))/target > DISPENSE_TOLERANCE){
      status = STATUSES.flow;
    }
    return status;
  }