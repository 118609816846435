import {Auth} from 'aws-amplify';
import {host} from '../data.js'

const LOCATION_ERROR_MESSAGE =
    'Error in account information service! Provide, correct data to the service!';

let urlConfig = {
    api: 'accounts/{accountId}/devices/',
    apiUidGet: 'accounts/{accountId}/devices/uid/{deviceId}',
    apiHistoryGet: 'accounts/{accountId}/devices/{deviceId}/history/'
    //proxyPort: 5000
};

export const devices_inf = {
    addDevices: async (deviceList, accountId, hasBillingInfo) => {
        try {
            if (deviceList.length == 0 || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            for (var i; i<deviceList.length; i++){
                if(deviceList[i].uid == undefined){
                    return console.error(LOCATION_ERROR_MESSAGE);
                }
            }
            const url =
                host + urlConfig.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    devices: deviceList,
                    Action: "ADD",
                    hasBillingInfo: hasBillingInfo
                }),
            });

            const data = await response.json();
            const { error } = data;
            return {status: response.status, error};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    
    removeDevices: async (deviceList, accountId, hasBillingInfo) => {
        try {
            if (deviceList.length == 0 || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            for (var i; i<deviceList.length; i++){
                if(deviceList[i].uid == undefined){
                    return console.error(LOCATION_ERROR_MESSAGE);
                }
            }
            const url =
                host + urlConfig.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    devices: deviceList,
                    Action: "REMOVE",
                    hasBillingInfo: hasBillingInfo
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    updateDevices: async (deviceList, accountId, hasBillingInfo) => {
        try {
            if (deviceList.length == 0 || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            for (var i; i<deviceList.length; i++){
                if(deviceList[i].uid == undefined){
                    return console.error(LOCATION_ERROR_MESSAGE);
                }
            }
            const url =
                host + urlConfig.api.replace("{accountId}",accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    devices: deviceList,
                    Action: "UPDATE",
                    hasBillingInfo: hasBillingInfo
                }),
            });

            const data = await response.json();

            return {status: response.status, data};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getDeviceHistory: async (deviceId, accountId, monthInterval=0) => {
        try {
            if (!deviceId || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            let l_monthInterval = 1;
            if (monthInterval){
                l_monthInterval = monthInterval;
            }
            const url =
                host + urlConfig.apiHistoryGet.replace("{accountId}",accountId).replace("{deviceId}",deviceId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
                body: JSON.stringify({
                    months: l_monthInterval,
                }),
            });
            //{"error":ret_status, "deviceHistory":deviceHistory}
            const data = await response.json();

            return {status: response.status, error: data.error, deviceHistory: data.deviceHistory};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },

    getDevice: async (deviceId, accountId) => {
        try {
            if (!deviceId || !accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }
            
            const url =
                host + urlConfig.apiUidGet.replace("{accountId}",accountId).replace("{deviceId}",deviceId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, deviceData } = data;
            return {status: response.status, error, deviceData};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    },
    getDevices: async (accountId) => {
        try{
            if (!accountId) {
                return console.error(LOCATION_ERROR_MESSAGE);
            }

            const url = host + urlConfig.api.replace("{accountId}", accountId);
            const user = await Auth.currentAuthenticatedUser();

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: user?.signInUserSession?.idToken?.jwtToken,
                },
            });

            const data = await response.json();
            const { error, deviceList } = data;
            return {status: response.status, error: error, deviceList: deviceList};
        } catch (error) {
            console.error(LOCATION_ERROR_MESSAGE, error);
        }
    }
};

export default devices_inf;
