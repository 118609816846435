import { useEffect, useState, Fragment } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Grid,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import { host } from "../data";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    indented: {
        marginLeft: theme.spacing(10),
    },
    content: {
        padding: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    formControlLabel: {
        marginLeft: theme.spacing(2),
    },
    rootpaper: {
        width: 1000,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        overflowX: "auto"
    },
    orgpaper: {
        width: 500,
        margin: theme.spacing(1),
        overflowX: "auto"
    },
    orgtable: {
        width: 750,
        height: '40vh',
        zIndex: 0,
    },
    reportpaper: {
        width: 800,
        margin: theme.spacing(1),
        overflowX: "auto"
    },
    reporttable: {
        width: 800,
        height: '40vh',
        zIndex: 0,
    },
    table: {
        width: '100%',
        height: '72vh',
        zIndex: 0,
    },
    link: {
        color: theme.palette.info.dark,
    },
    dropdown: {
        display: 'flex',
        marginRight: 'auto',
    },
}));


const UserOrganizationContent = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [deleteOrgTarget, setDeleteOrgTarget] = useState("");
    const [openDeleteOrg, setOpenDeleteOrg] = useState(false);
    const [deleteReportTarget, setDeleteReportTarget] = useState({});
    const [openDeleteReport, setOpenDeleteReport] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [scheduledReportList, setScheduledReportList] = useState([]);
    
    const orgListColumns = [
        {
            headerName: t('orgName'),
            field: 'name',
            flex: 1,
            disableClickEventBubbling: true
        },
        {
            headerName: t('access'),
            field: 'isEditor',
            renderCell: renderAccess,
            disableClickEventBubbling: true
        },
        {
            headerName: '',
            field: t('leaveQuestion'),
            renderCell: renderDeleteOrg,
            sortable: false,
            disableClickEventBubbling: true
        },
        {
            headerName: '',
            field: t('default'),
            renderCell: renderDefaultCheck,
            sortable: false,
            disableClickEventBubbling: true
        },
        {
            headerName: '',
            field: t('defaultBranch'),
            renderCell: renderDefaultBranch,
            sortable: false,
            width: 200,
            disableClickEventBubbling: true
        },
    ];

    function renderAccess(params) {
        return (
            <Typography component="span"> {params.value ? t('editor') : t('viewer') } </Typography>
        );
    };

    useEffect(() => {
        fetchPageData();
    }, []);

    function fetchPageData() {
        Auth.currentAuthenticatedUser()
            .then(data => {

                var requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': data.signInUserSession.idToken.jwtToken
                    },
                };
                // fetch(host + '/user/', requestOptions)
                //     .then(results => results.json())
                //     .then(data => {
                //         setOrgList(data.orgList);
                //     }).catch((error) => {
                //         console.log(error)
                //     })
            });
    };


    const handleOpenDeleteOrg = (target) => {
        setDeleteOrgTarget(target);
        setOpenDeleteOrg(true);
    };
    const handleCloseDeleteOrg = () => {
        setOpenDeleteOrg(false);
    };
    function renderDeleteOrg(params) {
        return (
            <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleOpenDeleteOrg(params.row)}
                size="large">
                <RemoveCircleIcon />
            </IconButton>
        );
    };
    
    function renderDefaultCheck(params) {
        var defCheck = false;
        if (params.row.isDefault){
            defCheck = true;
        }
        return (
            <Checkbox className={classes.clickButtons}
                    checked={defCheck}
                    onChange={e => handleCheck(params.row)}
                    name="defIndicator"
                    color="primary"
                />
        );
    };
    const handleCheck = (target) => {
        //console.log(target);
        if (target.isDefault !== 1){
            //Make call to update default org
            const orgId = target.id;
            Auth.currentAuthenticatedUser()
            .then(data => {
                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': data.signInUserSession.idToken.jwtToken
                    },
                    body: "{}"
                };
                // fetch(host + '/orgs/' + orgId + '/default/', requestOptions)
                //     .then(results => results.json())
                //     .then(data => {
                //         if(data.error === "OK"){
                //             fetchPageData();
                //         }
                //         else{
                //             console.log(data);
                //             alert(t("communicationError"));
                //         }
                //     }).catch((error) => {
                //         console.log(error)
                //     })
            });   
        }
    }

    const handleBranchDefaultChange = (branch_id, org_id) => {
        const branchId = branch_id;
        const orgId = org_id;
        Auth.currentAuthenticatedUser()
            .then(data => {
                var requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': data.signInUserSession.idToken.jwtToken
                    },
                    body: "{}"
                };
                // fetch(host + '/orgs/' + orgId + '/branches/' + branchId + '/default/', requestOptions)
                //     .then(results => results.json())
                //     .then(data => {
                //         if(data.error === "OK"){
                //             fetchPageData();
                //         }
                //         else{
                //             console.log(data);
                //             alert(t("communicationError"));
                //         }
                //     }).catch((error) => {
                //         console.log(error)
                //     })
            });
    };
    function renderDefaultBranch(params) {
        const org_id = params.row.id;
        var orgBranches = params.row.branches;
        var defaultBranch = {id: 0, name: t("no") + " " + t("branches_caps").toLowerCase()}
        if (orgBranches.length){
            defaultBranch = orgBranches[0];
        }
        else{
            orgBranches =[defaultBranch];
        }
        for (var i=0; i<orgBranches.length; i++){
            if(orgBranches[i].isDefault){
                defaultBranch = orgBranches[i]
            }
        }
        return(
            <Tooltip title={defaultBranch.name}>
                <Select className={classes.dropdown}
                            disabled={defaultBranch.id ? false : true}
                            id="branches-dropdown"
                            value={defaultBranch.id}
                            onChange={(event) => handleBranchDefaultChange(event.target.value, org_id)}
                        >
                            {orgBranches.map((branch) => (
                                <MenuItem key={branch.id} value={branch.id}>
                                    {branch.name}
                                </MenuItem>
                            ))}
                </Select>
            </Tooltip>
        );
    };

    const handleOrgDelete = () => {
        var delete_id = deleteOrgTarget.id;
        handleCloseDeleteOrg(false);

        Auth.currentAuthenticatedUser()
            .then(data => {

                var body = JSON.stringify(
                    {
                        "id": delete_id
                    }
                );

                var requestOptions = {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': data.signInUserSession.idToken.jwtToken
                    },
                    body: body
                };

                // fetch(host + '/orgs/' + delete_id + '/user', requestOptions)
                //     .then(results => results.json())
                //     .then(data => {
                //         if (data.error === "OK") {
                //             setOrgList(data.orgList);
                //             setDeleteOrgTarget({});
                //         }
                //         else {
                //             alert(data.error);
                //         }
                //     }).catch((error) => {
                //         console.log(error)
                //     })
            });
    };

    const handleOpenDeleteReport = (target) => {
        setDeleteReportTarget(target);
        setOpenDeleteReport(true);
    };
    const handleCloseDeleteReport = () => {
        setOpenDeleteReport(false);
    };

    const handleReportDelete = () => {
        var delete_id = deleteReportTarget.id;

        handleCloseDeleteReport(false);

        Auth.currentAuthenticatedUser()
            .then(data => {

                var body = JSON.stringify(
                    {
                        "id": delete_id
                    }
                );

                var requestOptions = {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': data.signInUserSession.idToken.jwtToken
                    },
                    body: body
                };

                let url = "";

                if (deleteReportTarget.type === "Branch") {
                    url = "/orgs/" + deleteReportTarget.orgId + "/branches/" + deleteReportTarget.branchId + "/reports";
                }
                else {
                    url = "/orgs/" + deleteReportTarget.orgId + "/branches/" + deleteReportTarget.branchId + "/sites/" + deleteReportTarget.siteId + "/reports";
                }

                fetch(host + url, requestOptions)
                    .then(results => results.json())
                    .then(data => {
                        if (data.error === "OK") {
                            var lscheduledReportList = scheduledReportList;
                            for (var i = 0; i < lscheduledReportList.length; i++) {
                                if (lscheduledReportList[i].id === delete_id) { break; }
                            }
                            lscheduledReportList.splice(i, 1);

                            setScheduledReportList(lscheduledReportList);
                            setDeleteReportTarget({});
                        }
                        else {
                            alert(data.error);
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
            });
    };

    return (
        <Fragment>
            {orgList.length > 0 && <Grid item xs component="div">
                <Typography variant="h6" component="div">
                    {t("organizations")}
                </Typography>
                <DataGrid hideFooterSelectedRowCount
                    className={classes.orgtable}
                    component="span"
                    disableColumnMenu={true}
                    rowsPerPageOptions={[10, 25, 50]}
                    pagination
                    pageSize={10}
                    rows={orgList}
                    columns={orgListColumns} />
            </Grid>
            }

            <Dialog disableEscapeKeyDown open={openDeleteOrg} onClose={handleCloseDeleteOrg}>
                <DialogTitle>{t("leaveOrganization")} {deleteOrgTarget.name}</DialogTitle>
                <DialogContent>
                    <Typography>{t("areYouSure")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteOrg} variant='contained' color="secondary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleOrgDelete} color="primary">
                        {t("leave")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                disableEscapeKeyDown
                open={openDeleteReport}
                onClose={handleCloseDeleteReport}>
                <DialogTitle>{t("deleteReport")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("areYouSure")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteReport} variant='contained' color="secondary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleReportDelete} color="primary">
                        {t("delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default UserOrganizationContent;