import React, { useEffect, useState, useRef } from 'react';
import { Auth } from 'aws-amplify';
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    Input,
    InputLabel,
    Link,
    Typography,
    Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import TermsOfService from '../TermsOfService';
import { privacy_url } from '../data';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

// Inline CSS
const useStyles = makeStyles((theme) => ({
    errorBox: {
        minHeight: theme.spacing(3),
        marginTop: '10px',
    }
    ,
    text: { padding: theme.spacing(1) },
    textField: {
        padding: theme.spacing(1),
        width: '50ch',
    },
    grow: { flexGrow: 2 }
}));

export default function GSignUp({ authState, onStateChange, changeLanguage }) {
    const classes = useStyles();
    const cookies = new Cookies();
    const { t, i18n } = useTranslation();
    // Set state
    const [values, setValues] = React.useState({
        username: '',
        password: '',
        showPassword: false,
        given_name: '',
        family_name: '',
        phone_number: ''
    });
    const [formError, setFormError] = React.useState('');
    const [errorStr, setErrorStr] = React.useState(t("invalidPassword"));
    const [openTerms, setOpenTerms] = React.useState(false);
    const [loginLang, setLoginLang] = React.useState("en");

    // Handle UI interactions
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleBackToSignIn = () => {
        onStateChange('signIn');
    };
    const handleSignUp = async () => {
        // Validate fields
        if (values.username === '') {
            setFormError(t("emailCannotBeEmpty"));
            return;
        }

        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.username) === false) {
            setFormError(t("invalidEmailAddress"));
            return;
        }

        if (values.password === '') {
            setFormError(errorStr);
            return;
        }

        if (values.password.length < 8) {
            setFormError(errorStr);
            return;
        }

        if (values.given_name === '') {
            setFormError(t("mustIncludeFirst"));
            return;
        }

        if (values.family_name === '') {
            setFormError(t("mustIncludeLast"));
            return;
        }

        if (values.phone_number !== ''
            && /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.phone_number) === false) {
            setFormError(t("invalidPhoneNumber"));
            return;
        }
        else setFormError('');

        // Attempt to create Cognito account
        try {
            await Auth.signUp({
                username: values.username,
                password: values.password,
                attributes: {
                    email: values.username,
                    given_name: values.given_name,
                    family_name: values.family_name,
                    phone_number: (values.phone_number !== '' ? '+1' + values.phone_number : '')
                }
            });
            onStateChange('confirmSignUp');
        } catch (error) {
            console.log(error);

            if (error.code === 'InvalidPasswordException') {
                setFormError(errorStr);
            } else {
                setFormError(error.message);
            }

        }
    };

    const handleOpenTerms = () => {
        setOpenTerms(true);
    };

    const handleCloseTerms = () => {
        setOpenTerms(false);
    };

    const handleChangeLanguage = (e) => {
        cookies.set('login-lang', e.target.value, { path: '/' });
        changeLanguage(e.target.value);
        setLoginLang(e.target.value);
    };

    useEffect(() => {
        var lang = cookies.get('login-lang');
        changeLanguage(lang);
        setLoginLang(lang);
    }, [cookies.get('login-lang')]);

    return (
        <React.Fragment>
            {authState === 'signUp'
                ? <Grid container
                    direction='column'
                    alignItems='stretch'
                    spacing={0}>
                    <Grid item xs>
                        <Typography className={classes.text} variant="h6">{t("createAccount")}</Typography>
                    </Grid>

                    <Grid item xs style={{ 'marginLeft': 294 }} >
                        <Select
                            value={loginLang}
                            native
                            onChange={handleChangeLanguage} >
                            <option value={"en"}>English</option>
                            <option value={"es"}>Español</option>
                            <option value={"fr"}>Français</option>
                        </Select>
                    </Grid>

                    <Grid item xs>
                        <FormControl className={classes.textField}>
                            <InputLabel htmlFor='username-entry'>
                                {t("email")}
                            </InputLabel>
                            <Input id='username-entry'
                                value={values.username}
                                onChange={handleChange('username')} />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl className={classes.textField}>
                            <InputLabel htmlFor='password-entry'>
                                {t("password")}
                            </InputLabel>
                            <Input id='password-entry'
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            size="large">
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>} />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl className={classes.textField}>
                            <InputLabel htmlFor='given-name-entry'>
                                {t("firstName")}
                            </InputLabel>
                            <Input id='given-name-entry'
                                value={values.given_name}
                                onChange={handleChange('given_name')} />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl className={classes.textField}>
                            <InputLabel htmlFor='family-name-entry'>
                                {t("lastName")}
                            </InputLabel>
                            <Input id='family-name-entry'
                                value={values.family_name}
                                onChange={handleChange('family_name')} />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl className={classes.textField}>
                            <InputLabel htmlFor='phone-number-entry'>
                                {t("phoneNumber2")}
                            </InputLabel>
                            <Input id='phone-number-entry'
                                value={values.phone_number}
                                onChange={handleChange('phone_number')}
                                startAdornment={
                                    <InputAdornment position="start">+1</InputAdornment>} />

                            <div className={classes.errorBox} >
                                <Typography variant="subtitle2" color='error'>{formError}</Typography>
                            </div>

                        </FormControl>
                    </Grid>
                    <Grid direction='column' item container justifyContent="center" style={{ 'marginBottm': 12 }}>
                        <Grid item xs >
                            <Typography style={{ 'fontSize': 12 }}>
                                {t("byClickingOnCreate")}
                            </Typography>

                        </Grid>
                        <Grid item xs style={{ 'margin': 'auto' }}>
                            <Button onClick={handleOpenTerms}
                                style={{ 'float': 'center', 'font-size': 12 }}>
                                {t("reviewTermsAndConditions")}
                            </Button>
                        </Grid>
                        <Grid item xs style={{ 'margin': 'auto' }}>
                            <a href={privacy_url} target="_blank">{t("privacy")}</a>
                        </Grid>
                    </Grid>
                    <Grid item xs style={{ 'float': 'right' }}>
                        <Button color='secondary'
                            style={{ 'float': 'right', 'marginRight': '25px', 'marginTop': '25px' }}
                            variant='contained'
                            onClick={() => handleSignUp()}>
                            {t("create")}
                        </Button>
                    </Grid>

                    <Grid item xs>
                        <Grid container
                            direction='row'
                            alignItems='flex-start'
                            spacing={0}>
                            <Typography className={classes.text}
                                variant='body2'
                                color='textSecondary'>
                                {t("haveAnAccount")}
                            </Typography>
                            <Button className={classes.text}
                                color='secondary'
                                component={Link}
                                onClick={() => handleBackToSignIn()}>
                                {t("signIn")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                :
                null
            }
            {openTerms && <TermsOfService openTerms={openTerms} handleCloseTerms={handleCloseTerms} />}
        </React.Fragment>
    );
}