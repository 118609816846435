import React, { useState, useRef, useEffect } from 'react';
import {
    useHistory,
    useParams
  } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import makeStyles from '@mui/styles/makeStyles';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Toolbar,
    Tooltip,
    FormControl,
    Typography,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';

import useSessionstorage from "@rooks/use-sessionstorage";

import { assets_inf } from "../../services/assets_inf";
import { fluids_inf } from "../../services/fluids_inf";
import DeleteDispensePoint from "./Components/DeleteDispensePoint";
import EditDispensePoint from "./Components/EditDispensePoint";
import EditDispensePointDialogue from "./Components/EditDispensePointDialog";
import { UNIT_SETTINGS } from '../../data';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 300,
    },
    clickButtons: {
        marginRight: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    HubInfoImg: {
        maxHeight: '200px',
        marginRight: theme.spacing(2),
    },
    table: {
        width: '100%',
        margin: '0 auto',
        maxWidth: '1200px',
        height: '55vh',
    },
}));

const ManageAsset = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const params = useParams();
    const history = useHistory();

    const paramAssetName = params.assetName === undefined ? null : params.assetName;
    const {value: accountInfo} = useSessionstorage("accountInfo",{})

    const [dataReady, setDataReady] = useState(false);
    const [openAddDispensePoint, setOpenAddDispensePoint] = useState(false);
    const [assetId, setAssetId] = useState();
    
    const [assetName, setAssetName] = useState(paramAssetName);
    const rassetName = useRef();
    
    const [fluidName, setFluidName] = useState();
    const [newFluidName, setNewFluidName] = useState();
    const rNewFluidName = useRef();
    const [fluidPartNumber, setFluidPartNumber] = useState();
    const rFluidPartNumber = useRef();
    const [openFluid, setOpenFluid] = useState(false);

    const [assetData, setAssetData] = useState({})
    const [dispensePoints, setDispensePoints] = useState([])
    const [fluids, setFluids] = useState([]);
    const blankDispensePoint = {
        name:"",
        zerk_uid:"",
        dispense:"",
        interval:"",
    };
    
    const [tags, setTags] = useState("");
    const rTags = useRef();

    const [editAsset, setEditAsset] = useState(paramAssetName ? false : true);

    const dpColumns = [
        {
            field: 'name', 
            headerName: t('name'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'zerk_uid', 
            headerName: t('zerk'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'fluid_name', 
            headerName: t('fluid'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'dispense', 
            headerName: t('dpDispense'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'volumeUnit', 
            headerName: t('volumeUnits'), 
            renderCell: renderUnits,
            disableClickEventBubbling: true,
            sortable: false,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'interval', 
            headerName: t('dpInterval'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            headerName: t('edit'),
            field: 'editField',
            renderCell: renderEdit,
            sortable: false,
            headerClassName: 'default-table-theme--header',
            disableClickEventBubbling: true
        },
        {
            headerName: t('delete'),
            field: 'deleteField',
            renderCell: renderDelete,
            sortable: false,
            headerClassName: 'default-table-theme--header',
            disableClickEventBubbling: true
        },
    ];

    function renderUnits(params) {
        return (
            <Typography>{t(UNIT_SETTINGS[params.row.volumeUnit].label)}</Typography>
        );
    }

    function renderEdit(params) {
        return (
            <>
            <EditDispensePoint 
                accountId={accountInfo.accountId}
                assetId={assetId}
                fluids={fluids}
                disabled={!editAsset}
                selectedData={params.row} 
                fetchData={getAssetInformation}/>
            </>
        );
    };
    function renderDelete(params) {
        return (
            <>
            <DeleteDispensePoint 
                accountId={accountInfo.accountId} 
                assetId={assetId}
                disabled={!editAsset}
                selectedData={params.row}
                fetchData={getAssetInformation}/>
            </>
        );
    };


    const lookupAssetId = async () => {
        console.log("lookup {assetName}".replace("{assetName}",paramAssetName));
        if (paramAssetName && !assetId){
            try{
                const { status, data } = await assets_inf.lookupAssetId(
                    accountInfo.accountId,
                    paramAssetName
                );
                
                const { error } = data;

                if (status === 404) {
                    return;
                }

                if (error !== 'OK' && status !== 200) {
                    if (error.includes("Device already assigned")){
                        alert(t("deviceIsAlreadyAssigned"));
                    }
                    else {
                        alert(t("sorryUnableToRetrieve"));
                        throw new Error(error);
                    }
                }
                setAssetId(data.assetId);
                setAssetName(paramAssetName);
            }catch (error) {
                    console.error(error);
            }
        }
        setDataReady(true);
    }

    const getAssetInformation = async () => {
        try{
            const { status, data } = await assets_inf.getAssetInfo(
                accountInfo.accountId,
                assetId
            );
            
            const { error } = data;

            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("Device already assigned")){
                    alert(t("deviceIsAlreadyAssigned"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }
            setFluids(data.fluidsList);
            setAssetData(data.assetData);
            setTags(data.assetData.tags);
            setAssetName(data.assetData.name);
            if (!fluidName){
                setFluidName(data.fluidsList[0].name);
            }
            setDispensePoints(data.dispensePoints);


        }catch (error) {
                console.error(error);
        }
    };

    const handleCloseDispensePoint = () => {
        setOpenAddDispensePoint(false);
    };
    const handleOpenAddDispensePoint = () => {
        setOpenAddDispensePoint(true);
    };

    const handleCreateAsset = async (exit) => {
        //Create Asset
        if (assetId){
            try{
                const { status, data } = await assets_inf.updateAsset(
                    accountInfo.accountId,
                    assetId,
                    assetName,
                    tags
                );
                
                const { error } = data;

                if (status === 404) {
                    return;
                }

                if (error !== 'OK' && status !== 200) {
                    if (error.includes("Asset name already exists")){
                        alert(t("assetNameAlreadyUsed"));
                    }
                    else {
                        alert(t("sorryUnableToRetrieve"));
                        throw new Error(error);
                    }
                }
                
                if (paramAssetName !== assetName){
                    let url = '/assets/{assetName}/edit'.replace("{assetName}",assetName);
                    history.push(url);
                }
            }catch (error) {
                console.error(error);
            }
        }
        else{
            try{
                const { status, data } = await assets_inf.createAsset(
                    accountInfo.accountId,
                    assetName,
                    tags
                );
                
                const { error } = data;

                if (status === 404) {
                    return;
                }

                if (error !== 'OK' && status !== 200) {
                    if (error.includes("Asset name already exists")){
                        alert(t("assetNameAlreadyUsed"));
                    }
                    else {
                        alert(t("sorryUnableToRetrieve"));
                        throw new Error(error);
                    }
                }
                setAssetId(data.assetId);
                let url = '/assets/{assetName}/edit'.replace("{assetName}",assetName);
                history.push(url);
            }catch (error) {
                console.error(error);
            }
        }
        if (exit){
            handleExit();
        }
    };

    const handleClickOpenFluid = () => {
        setOpenFluid(true);
    };
    const handleCloseFluid = () => {
        setOpenFluid(false);
    };
    const handleAddFluid = async () => {
        try{
            const { status, data } = await fluids_inf.createFluid(
                newFluidName,
                accountInfo.accountId,
            );
            
            const { error } = data;

            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("Device already assigned")){
                    alert(t("deviceIsAlreadyAssigned"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }
            setFluids(data.fluidsList);
            setNewFluidName("");
            setOpenFluid(false);
        }catch (error) {
            console.error(error);
        }
    };

    const handleExit = () => {
        console.log("handleExit");
        //let url = { pathname: ('/accounts/').concat(accountInfo.accountName)};    
        let url = { pathname: '/' };    
        history.push(url);
    };

    useEffect(() => {
        lookupAssetId()
    },[]);

    useEffect(() => {
        if (assetId) {
          getAssetInformation();
        }
    }, [assetId]);

    return <>
        <Grid item container 
            direction='column'
            justifyContent='flex-start'
            alignItems='stretch'
            className={classes.content}>
            <Grid item xs>
                <Toolbar>
                    <Tooltip title={t("goBack")}>
                        <IconButton color='primary' onClick={handleExit} size="large">
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>

                    <Button onClick={handleExit} color="primary">
                        {t("goBack")}
                    </Button>
                    <Typography className={classes.content} variant="h4"> {t('assetSettings')}</Typography>
                    <div className={classes.grow}/>
                    {accountInfo.isAccountEditor && <Tooltip title={t("edit")}>
                        <IconButton disabled={editAsset} color='primary' onClick={() => {setEditAsset(true)}}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>}
                </Toolbar>
            </Grid>
            <Grid item xs>
                <FormControl className={classes.formControl}>
                    <TextField label={t("assetName")}
                    disabled={!editAsset}
                    inputRef={rassetName}
                    value={assetName}
                    onChange={(e) => {
                        const regex = /^([\_ a-zA-Z0-9\-]){0,40}$/i;
                        if (regex.test(e.target.value)) {
                        setAssetName(e.target.value);
                        }
                    }}
                    />
                    <TextField label={t("tags")}
                        disabled={!editAsset}
                        inputRef={rTags}
                        value={tags}
                        onChange={(e) => {
                        const regex = /^([, a-zA-Z0-9\-]){0,200}$/i;
                        if (regex.test(e.target.value)) {
                            setTags(e.target.value);
                        }
                        }}
                        helperText={t("commaSeperated")} />
                </FormControl>
            </Grid>
            <Grid item container direction="row">
                <Button onClick={handleExit} className={classes.clickButtons} variant="contained" color="secondary">
                    {t("exit")}
                </Button>
                {assetId && <Button disabled={!editAsset} className={classes.clickButtons} variant="contained" color="primary" onClick={() => handleCreateAsset(true)}>
                    {t("saveAndExit")}
                </Button>}
                <Button disabled={!editAsset} variant="contained" onClick={() => handleCreateAsset(false)} color="primary" >
                    {t("save")}
                </Button>
            </Grid>
            <Divider className={classes.content}/>
            {assetId && editAsset && <Grid item xs>
                <Tooltip title={t("addFluid")}>
                        <IconButton
                            className={classes.inputButton}
                            aria-label={t("add")}
                            onClick={handleClickOpenFluid}
                            size="large">
                            <AddIcon />
                            <Typography>{t("addFluid")}</Typography>
                        </IconButton>
                    </Tooltip>
                </Grid>}
            {assetId && <Grid item xs>
                <Toolbar>
                    <Typography variant="h6">{t('dispensePointTable')}</Typography>
                    <div className={classes.grow}/>
                    <Button
                        disabled={!editAsset}
                        onClick={handleOpenAddDispensePoint}
                        variant='contained'
                        color='primary'>
                    {t("addDispensePoint")}
                    </Button>
                </Toolbar>
                
            </Grid>}
            <Grid item xs >
                <Box 
                    sx={{
                            width: '100%',
                            margin: '0 auto',
                            maxWidth: '1200px',
                            height: '55vh',
                            '& .default-table-theme--header': {
                                backgroundColor: '#555555',
                                color: '#FFFFFF'
                            },
                        }}
                    >
                    {dispensePoints.length ? <DataGrid  sx={{
                            '& .MuiDataGrid-sortIcon':{
                                color: '#fff'
                            },}}
                        hideFooterSelectedRowCount
                        rowsPerPageOptions={[25, 50, 100]}
                        pagination
                        pageSize={100}
                        rows={dispensePoints}
                        columns={dpColumns} />
                    :
                    <Typography>{t("noData")}</Typography>}
                </Box>
            </Grid>
        </Grid>
    {openAddDispensePoint && <EditDispensePointDialogue 
        open={openAddDispensePoint}
        create={true} 
        accountId={accountInfo.accountId} 
        assetId={assetId} 
        fluids={fluids} 
        selectedData={blankDispensePoint}
        handleClose={handleCloseDispensePoint} 
        fetchData={getAssetInformation}/>}
    <Dialog disableEscapeKeyDown open={openFluid} onClose={handleCloseFluid}>
        <DialogTitle>{t("addAFluid")}</DialogTitle>
        <DialogContent>
        <form className={classes.container}>
            <FormControl className={classes.formControl}>
            <TextField label={t("fluidName")}
                value={newFluidName}
                onChange={e => {
                const regex = /^([ a-zA-Z0-9\-]){0,40}$/i;
                if (regex.test(e.target.value)) { setNewFluidName(e.target.value) }
                }}
                inputRef={rNewFluidName} />
            {false && <TextField label={t("fluidPartNumber")}
                value={fluidPartNumber}
                onChange={e => {
                const regex = /^([ a-zA-Z0-9\-]){0,40}$/i;
                if (regex.test(e.target.value)) { setFluidPartNumber(e.target.value) }
                }}
                inputRef={rFluidPartNumber} />}
            </FormControl>
        </form>
        </DialogContent>

        <DialogActions>
        <Button onClick={handleCloseFluid} color="secondary">
            {t("cancel")}
        </Button>
        <Button onClick={handleAddFluid} color="primary">
            {t("add")}
        </Button>
        </DialogActions>
    </Dialog>
    </>;
}

export default ManageAsset;