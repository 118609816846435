import styled from '@emotion/styled';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Link,
    MenuItem,
    TextField,
    Toolbar,
    Tooltip,
    FormControl,
    Typography,
} from "@mui/material";

export const GrowDiv = styled.div(props => ({
    flexGrow: 1,
    //height: '64px'
  }));

//export const GrowDivHt = styled.div(props => ({
//    flexGrow: 1,
//    height: '64px'
//  }));

export const StyledPageTitle = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
  }));

export const StyledPageLabel = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(2),
  }));

export const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.info.dark,
  }));