import React, { useState, useRef, useEffect } from 'react';
import {
    Link as RouterLink,
    useHistory,
    useParams
  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Link,
    MenuItem,
    TextField,
    Toolbar,
    Tooltip,
    FormControl,
    Typography,
} from "@mui/material";
import { Edit } from '@mui/icons-material/';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid } from '@mui/x-data-grid';

import useSessionstorage from "@rooks/use-sessionstorage";

import {units_settings} from "../../data";
import { StyledLink , GrowDiv } from "../../styledComponentsCommon";


const DeviceTable = ({ deviceData, dataLoading=false, useEdit=false, openEdit=(deviceTarget)=>{}, refreshData=()=>{} }) => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const history = useHistory();

    const {value: accountInfo} = useSessionstorage("accountInfo",{})

    const [dataReady, setDataReady] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [columnData, setColumnData] = useState([]);
    
    const deviceColumns = [
        {
            field: 'friendly_name', 
            headerName: t('name'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 250
        },
        {
            field: 'uid', 
            headerName: t('deviceUID'), 
            disableClickEventBubbling: true,
            renderCell: renderDeviceUid,
            headerClassName: 'default-table-theme--header',
            width: 160
        },
        {
            field: 'ServerTime_UTC', 
            headerName: t('lastReported'), 
            type: 'dateTime', 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 200
        },
        {
            field: 'fluid_name', 
            headerName: t('fluid'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'batt', 
            headerName: t('batteryPercentage'),
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'rssi', 
            headerName: t('rssi'),
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 100
        },
    ];

    const editColumn = {
        headerName: t('edit'),
        field: t('edit'),
        renderCell: renderDeviceEdit,
        width: 80,
        sortable: false,
        headerClassName: 'default-table-theme--header',
        disableClickEventBubbling: true
    };

    function renderDeviceEdit(params) {
        return (
            <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => openEdit(params.row)}
                size="large">
                <Edit />
            </IconButton>
        );
    };

    function renderDeviceUid(params) {
        return (
            <StyledLink
                component={RouterLink}
                to={'/devices/' + params.row.uid}>
                {params.row.uid}
            </StyledLink>
        );
    };

    useEffect(() => {
        var i;
        var l_rows = deviceData;
        var l_col = deviceColumns;
        if(useEdit){
            l_col.push(editColumn);
        }
        for (i=0; i<l_rows.length; i++){
            l_rows[i].ServerTime_UTC = new Date(l_rows[i].server_time);
        }
        //console.log(l_rows);
        setRowData(l_rows);
        setColumnData(l_col);
    },[deviceData]);


    return <>
        {true && <Toolbar>
            <GrowDiv/>
            <Tooltip title={'refresh'}>
                <IconButton color='primary' onClick={refreshData}>
                    <RefreshIcon/>
                </IconButton>
            </Tooltip>
        </Toolbar>}
        <Box 
            sx={{
                    width: '100%',
                    margin: '0 auto',
                    maxWidth: '1202px',
                    height: '55vh',
                    '& .default-table-theme--header': {
                        backgroundColor: '#555555',
                        color: '#FFFFFF'
                      },
                }}
            >
        <DataGrid 
                sx={{
                    '& .MuiDataGrid-sortIcon':{
                        //color: 'primary.contrastText'
                        color: '#fff'
                    },
                }}
                loading={dataLoading}
                hideFooterSelectedRowCount
                rowsPerPageOptions={[25, 50, 100]}
                pagination
                pageSize={100}
                rows={rowData}
                columns={columnData} />
        
        </Box>
        
    </>;
}

export default DeviceTable;