import React from 'react'
import { Auth } from 'aws-amplify';
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Link,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    errorBox: { minHeight: theme.spacing(3) },
    text: { padding: theme.spacing(1) },
    textField: {
        padding: theme.spacing(1),
        width: '50ch',
    },
    grow: { flexGrow: 2 }
}));

export default function GForgotPassword({ authState, onStateChange }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useState({
        username: '',
        code: '',
        new_password: '',
        codeSent: false,
        showPassword: false
    });
    const [formError, setFormError] = React.useState('');

    // Handle UI interactions
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleBackToSignIn = () => {
        onStateChange('signIn');
    };
    const handleSendCode = async () => {
        // Validate fields
        if (values.username === '') {
            setFormError(t("emailCannotBeEmpty"));
            return;
        }
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.username) === false) {
            setFormError(t("invalidEmailAddress"));
            return;
        }
        else setFormError('');

        // Submit for code email
        try {
            await Auth.forgotPassword(values.username);
            setValues({ ...values, codeSent: true });
        } catch (error) {
            setFormError(error.message);
        }
    };
    const handleValidateCode = async () => {
        // Validate fields
        if (values.code === '') {
            setFormError(t("codeCannotBeEmpty"));
            return;
        }
        if (values.new_password === '') {
            setFormError(t("newPasswordCannotBeEmpty"));
            return;
        }

        // Attempt to set new password
        try {
            await Auth.forgotPasswordSubmit(values.username, values.code, values.new_password)
            onStateChange('signIn');
        } catch (error) {
            setFormError(error.message);
        }
    };

    return (
        <React.Fragment>
            {authState === 'forgotPassword'
                ? <Grid container
                    direction='column'
                    alignItems='stretch'
                    spacing={0}>
                    {values.codeSent
                        ? <React.Fragment>
                            <Grid item xs>
                                <Typography className={classes.text} variant="h6">{t("resetYourPassword")}</Typography>
                            </Grid>
                            <Grid item xs>
                                <FormControl className={classes.textField}>
                                    <InputLabel htmlFor='code-entry'>
                                        {t("verificationCode")}
                                    </InputLabel>
                                    <Input id='code-entry'
                                        value={values.code}
                                        onChange={handleChange('code')} />
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <FormControl className={classes.textField}>
                                    <InputLabel htmlFor='password-entry'>
                                        {t("newPassword")}
                                    </InputLabel>
                                    <Input id='password-entry'
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.new_password}
                                        onChange={handleChange('new_password')}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    size="large">
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>} />
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <Grid container
                                    direction='row'
                                    justifyContent='flex-end'
                                    alignContent='center'
                                    alignItems='center'
                                    spacing={0}>
                                    <Grid item xs={9}>
                                        <div className={classes.errorBox}>
                                            <Typography variant="subtitle2" color='error'>{formError}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <Button color='secondary'
                                            variant='contained'
                                            onClick={() => handleValidateCode()}>
                                            {t("submit")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                        : <React.Fragment>
                            <Grid item xs>
                                <Typography className={classes.text} variant="h6">{t("resetYourPassword")}</Typography>
                            </Grid>
                            <Grid item xs>
                                <FormControl className={classes.textField}>
                                    <InputLabel htmlFor='username-entry'>
                                        {t("email")}
                                    </InputLabel>
                                    <Input id='username-entry'
                                        value={values.username}
                                        onChange={handleChange('username')} />
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <Grid container
                                    direction='row'
                                    justifyContent='flex-end'
                                    alignContent='center'
                                    alignItems='center'
                                    spacing={0}>
                                    <Grid item xs={8}>
                                        <div className={classes.errorBox}>
                                            <Typography variant="subtitle2" color='error'>{formError}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <Button color='secondary'
                                            variant='contained'
                                            onClick={() => handleSendCode()}>
                                            {t("sendCode")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>}
                    <Grid item xs>
                        <Button className={classes.text}
                            color='secondary'
                            component={Link}
                            onClick={() => handleBackToSignIn()}>
                            {t("backToSignIn")}
                        </Button>
                    </Grid>
                </Grid>
                : null}
        </React.Fragment>
    );
}