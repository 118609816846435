import React from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBack } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { privacy_url } from '../data';
import TermsOfService from '../TermsOfService';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    indented: {
        marginLeft: theme.spacing(10),
    },
    content: {
        padding: theme.spacing(2),
    },
    table: {
        minWidth: 650,
        border: "none",
        boxShadow: "none"
    }
}));

export default function HelpPage() {
    const classes = useStyles();
    const history = useHistory();
    const [openTerms, setOpenTerms] = React.useState(false);
    const { t } = useTranslation();

    const handleCloseTerms = () => {
        setOpenTerms(false);
    };

    const handleOpenTerms = () => {
        setOpenTerms(true);
    };

    return (
        <Grid container
            direction='column'
            justifyContent='flex-start'
            alignItems='stretch'
            className={classes.content}>
            <Grid item xs>
                <Toolbar>
                    <Tooltip title={t("goHome")}>
                        <IconButton
                            color='primary'
                            component={RouterLink}
                            onClick={() => history.goBack()}
                            size="large">
                            <ArrowBack />
                        </IconButton>
                    </Tooltip>
                    <Typography variant='h5'
                        color='textPrimary'>
                        {t("help")}
                    </Typography>
                </Toolbar>
            </Grid>
            <Grid item xs><Divider />
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            {false && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <a href={"http://setup.gracopulse.com/level"} target="_blank">{t("setupGuide")}</a>
                                </TableCell>
                            </TableRow>}
                            {false && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <a href={"http://operation.gracopulse.com/level"} target="_blank">{t("operationGuide")}</a>
                                </TableCell>
                            </TableRow>}
                            {false && <TableRow>
                                <TableCell colSpan={2} align="center" style={{ borderBottom: "none" }}>
                                    {t("forHelpEmail_colon")} <a href="mailto:ledtech@graco.com" target="_top">ledtech@graco.com</a>
                                </TableCell>
                            </TableRow>}
                            {false && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    {t("techAssistancePhone")}
                                </TableCell>
                            </TableRow>}
                            {false && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <Button onClick={handleOpenTerms} variant="outlined" style={{ 'float': 'center', 'font-size': 12 }}>
                                        {t("termsAndConitions")}
                                    </Button>
                                </TableCell>
                            </TableRow>}
                            {false && <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <a href={privacy_url} target="_blank">{t("privacy")}</a>
                                </TableCell>
                            </TableRow>}
                            <TableRow >
                                <TableCell colSpan={2} align="center" component="th" scope="row" style={{ borderBottom: "none" }}>
                                    <a href={"http://www.graco.com"} target="_blank">www.graco.com</a>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                {openTerms && <TermsOfService openTerms={openTerms} handleCloseTerms={handleCloseTerms} />}
            </Grid>
        </Grid>
    );
}
