import React, { useState, useRef, useEffect } from 'react';
import {
    useHistory,
    useParams,
  } from 'react-router-dom'
import {
    Link as RouterLink
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@emotion/react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Link,
    MenuItem,
    TextField,
    Toolbar,
    Tooltip,
    FormControl,
    Typography,
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import { DataGrid } from '@mui/x-data-grid';

import useSessionstorage from "@rooks/use-sessionstorage";

import {units_settings} from "../../data";

const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.info.dark,
    },
}));

const AssetTableBasic = ({rowData, dataLoading=false}) => {
    const theme=useTheme();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const params = useParams();
    const history = useHistory();

    const {value: accountInfo} = useSessionstorage("accountInfo",{})

    const [dataReady, setDataReady] = useState(false);
    
    const dpColumns = [
        {
            field: 'asset_name', 
            headerName: t('asset'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            renderCell: renderAssetLink,
            width: 200
        },
        {
            field: 'dp_name', 
            headerName: t('dispensePoint'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 200
        },
        {
            field: 'daysSince', 
            headerName: t('daysSince'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 100
        },
        {
            field: 'interval', 
            headerName: t('dpInterval'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'dispense', 
            headerName: t('targetDispense'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'amount', 
            headerName: t('dispensed'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'fluid_name', 
            headerName: t('fluid'), 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 120
        },
        {
            field: 'ReadingTime_UTC', 
            headerName: t('lastReading'),
            type: 'dateTime', 
            disableClickEventBubbling: true,
            headerClassName: 'default-table-theme--header',
            width: 190
        },
    ];

    function renderAssetLink(params) {
        const assetName = (params.row.asset_name);
        return (
            <Link 
                sx={{color: theme.palette.info.dark}}
                component={RouterLink}
                to={'/assets/' + assetName + '/edit/'}>
                {assetName}
            </Link>
        );
    }

    return <>
        <Box 
            sx={{
                    width: '100%',
                    margin: '0 auto',
                    maxWidth: '1200px',
                    height: '55vh',
                    '& .default-table-theme--header': {
                        backgroundColor: '#555555',
                        color: '#FFFFFF'
                      },
                }}
            >
        <DataGrid 
                loading={dataLoading}
                sx={{
                    '& .MuiDataGrid-sortIcon':{
                        //color: 'primary.contrastText'
                        color: '#fff'
                    },
                }}
                hideFooterSelectedRowCount
                rowsPerPageOptions={[25, 50, 100]}
                pagination
                pageSize={100}
                rows={rowData}
                columns={dpColumns} />
        </Box>
        
    </>;
}

export default AssetTableBasic;