export function convertDispenseUnits (volume, units, new_units){
  var ret_volume = parseFloat(volume);
  var decimalPlaces = 2;
  switch(units){
    case "cu_in":
      switch(new_units){
        case "oz":
          ret_volume = ret_volume / 1.9886;
          break;
        case "grams":
          ret_volume = ret_volume * 14.2561;
          break;
        case "cc":
          ret_volume = ret_volume * 16.3871;
          break;
        default:
          break;
      }
      break;
    case "oz":
      switch(new_units){
        case "cu_in":
          ret_volume = ret_volume * 1.9886;
          break;
        case "grams":
          ret_volume = ret_volume * 1.9886 * 14.2561;
          break;
        case "cc":
          ret_volume = ret_volume * 1.9886 * 16.3871;
          break;
        default:
          break;
      }
      break;
    case "grams":
      switch(new_units){
        case "cu_in":
          ret_volume = ret_volume / (14.2561);
          break;
        case "oz":
          ret_volume = (ret_volume / 14.2561) / 1.9886;
          break;
        case "cc":
          ret_volume = (ret_volume / 14.2561) * 16.3871;
          break;
        default:
          break;
      }
      break;
    case "cc":
      switch(new_units){
        case "cu_in":
            ret_volume = ret_volume / 16.3871;
            break;
          case "oz":
            ret_volume = (ret_volume / 16.3871) / 1.9886;
            break;
          case "grams":
            ret_volume = (ret_volume / 16.3871) * 14.2561;
            break;
          default:
            break;
      }
      break;
    default:
      break;
  }
  switch(new_units){
    case "cu_in":
      decimalPlaces = 2;
      break;
    case "cc":
      decimalPlaces = 1;
      break;
    case "oz":
      decimalPlaces = 2;
      break;
    case "grams":
      decimalPlaces = 1;
      break;
    default:
      break;
  }
  return ret_volume.toFixed(decimalPlaces);
}