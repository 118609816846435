import React, { useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import {
    Button,
    Input,
    InputAdornment,
    InputLabel,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
    IconButton,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    ArrowBack,
    Edit
} from '@mui/icons-material';
import { useTheme } from '@emotion/react';

import ChangePasswordButton from './ChangePasswordButton';

const InfoTypography = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
}));

const TitleGrid = styled(Grid)(({ theme }) => ({
    width: "200px",
}));
const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: "400px",
}));


export default function UserInfocontent(props){
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const history = useHistory();
    const [editActive, setEditActive] = useState(false);
    const [userInfoFetched, setUserInfoFetched] = useState(false);
    const [userInfo, setUserInfo] = useState(undefined);
    const [givenName, setGivenName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [oldGivenName, setOldGivenName] = useState('');
    const [oldFamilyName, setOldFamilyName] = useState('');
    const [oldPhoneNumber, setOldPhoneNumber] = useState('');

    const [formError, setFormError] = React.useState('none');

    const handleClickEdit = () => {
        if (editActive) {
            setEditActive(false)
        } else {
            setEditActive(true)
        };
        //saveUserLanguageSetting(lngAbbrvPrev);
        //props.changeLanguage(lngAbbrvPrev);
    };

    const handleClickSubmit = async () => {
        if (givenName === '') {
            setFormError(t("mustIncludeAFirstName"));
            return;
        }

        if (familyName === '') {
            setFormError(t("mustIncludeALastName"));
            return;
        }

        if (phoneNumber !== ''
            && /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phoneNumber) === false) {
            setFormError(t("invalidPhoneNumber"));
            return;
        } else {
            setFormError('none');
        }

        try {
            let user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
                given_name: givenName,
                family_name: familyName,
                phone_number: (phoneNumber !== '' ? '+1' + phoneNumber : ''),
            });

            //setLngAbbrvPrev(lngAbbrv);
            //setUserLanguagePrev(userLanguage);
            setOldFamilyName(familyName);
            setOldGivenName(givenName);
            setOldPhoneNumber(oldPhoneNumber);
            history.go(0);
        } catch (error) {
            setFormError(error.message);
        }
    };

    async function fetchUserInfo() {
        try {
            let user = await Auth.currentUserInfo();
            setUserInfo(user.attributes);
        } catch (error) {
            setFormError(error.message);
        }
    };

    React.useEffect(() => {
        fetchUserInfo();
    }, []);

    React.useEffect(() => {
        if (userInfo !== undefined) {
            setGivenName(userInfo.given_name);
            setFamilyName(userInfo.family_name);
            setPhoneNumber(userInfo.phone_number);
            
            setOldGivenName(userInfo.given_name);
            setOldFamilyName(userInfo.family_name);
            setOldPhoneNumber(userInfo.phone_number);
            
            setUserInfoFetched(true)
        }
    }, [userInfo]);

    return (
        <>
        {!userInfoFetched ? <Typography component="span">{t("fetchingUserInfo")}</Typography>
        : <Grid container item xs
            direction='column'
            alignItems='flex-start'
            >
            <Grid item xs>
                <Typography variant="h6" component="span">{t("userProfile")}
                    {editActive
                        ? null
                        : <Tooltip title={t("editProfile")}>
                            <IconButton color='primary' onClick={handleClickEdit} size="large">
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    }
                </Typography>
            </Grid>
            {!editActive && <Grid item xs>
                <Grid item container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                >
                    <TitleGrid item xs>
                        <InfoTypography>
                            {t('email')}
                        </InfoTypography>
                    </TitleGrid>
                    <Grid item xs>
                        <InfoTypography>{userInfo.email}</InfoTypography>
                    </Grid>    
                </Grid>
                <Grid item container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                >
                    <TitleGrid item xs>
                        <InfoTypography>
                            {t('password')}
                        </InfoTypography>
                    </TitleGrid>
                    <Grid item xs>
                        <ChangePasswordButton setFormError={setFormError}/>
                    </Grid>    
                </Grid>
                <Grid item container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                >
                    <TitleGrid item xs>
                        <InfoTypography>
                            {t('firstName')}
                        </InfoTypography>
                    </TitleGrid>
                    <Grid item xs>
                        <InfoTypography>{userInfo.given_name}</InfoTypography>
                    </Grid>    
                </Grid>
                <Grid item container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                >
                    <TitleGrid item xs>
                        <InfoTypography>
                            {t('lastName')}
                        </InfoTypography>
                    </TitleGrid>
                    <Grid item xs>
                        <InfoTypography>{userInfo.family_name}</InfoTypography>
                    </Grid>    
                </Grid>
                <Grid item container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='flex-start'
                >
                    <TitleGrid item xs>
                        <InfoTypography>
                            {t('phoneNumber')}
                        </InfoTypography>
                    </TitleGrid>
                    <Grid item xs>
                        <InfoTypography>{userInfo.phone_number}</InfoTypography>
                    </Grid>    
                </Grid>
            </Grid>}
            {editActive && <Grid container direction='column' item xs>
                <StyledFormControl>
                    <TextField disabled label={t("email")}
                        value={userInfo.email}
                        onChange={()=>{}}/>
                    <TextField label={t("firstName")}
                        value={givenName}
                        onChange={(event) => {setGivenName(event.target.value)}}
                        />
                    <TextField label={t("lastName")}
                        value={familyName}
                        onChange={(event) => {setFamilyName(event.target.value)}}
                        />
                    <TextField label={t("phoneNumber")}
                        value={phoneNumber}
                        onChange={(event) => {setPhoneNumber(event.target.value)}}
                        />
                    {formError !== 'none'
                            ? <Typography component="span" color='error'>{formError}</Typography>
                            : null}
                <Button
                    sx={{margin:theme.spacing(1)}}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={handleClickSubmit}>
                    {t("save")}
                </Button>
                <Button 
                    sx={{margin:theme.spacing(1)}}
                    color="primary"
                    size="small"
                    onClick={handleClickEdit}>
                    {t("cancel")}
                </Button>
                </StyledFormControl>
            </Grid>}
        </Grid>}
        </>
    );
}