import React from 'react'
import { Auth } from 'aws-amplify';
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    Link,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    errorBox: { minHeight: theme.spacing(3) },
    text: { padding: theme.spacing(1) },
    textField: {
        padding: theme.spacing(1),
        width: '50ch',
    },
    grow: { flexGrow: 2 }
}));

export default function GConfirmSignUp({ authState, onStateChange }) {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [values, setValues] = React.useState({
        username: '',
        code: ''
    });
    const [formError, setFormError] = React.useState('');

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    
    const handleBackToSignIn = () => {
        onStateChange('signIn');
    };

    const handleResendCode = async () => {
        // Validate the fields.
        if (values.username === '') {
            setFormError(t("emailCannotBeEmpty"));
            return;
        }
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.username) === false) {
            setFormError(t("invalidEmailAddress"));
            return;
        }

        try {
            await Auth.resendSignUp(values.username);
            setFormError(t("codeResent"));
        } catch (error) {
            setFormError(error.message);
        }
    };

    const handleConfirm = async () => {
        // Validate the fields.
        if (values.username === '') {
            setFormError(t("emailCannotBeEmpty"));
            return;
        }
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.username) === false) {
            setFormError(t("invalidEmailAddress"));
            return;
        }
        if (values.code === '') {
            setFormError(t("codeCannotBeEmpty"));
            return;
        }

        try {
            await Auth.confirmSignUp(values.username, values.code);
            onStateChange('signIn');
        } catch (error) {
            setFormError(error.message);
        }
    };

    return (
        <React.Fragment>
            {authState === 'confirmSignUp'
                ? <Grid container
                    direction='column'
                    alignItems='stretch'
                    spacing={0}>
                    <Grid item xs>
                        <Typography className={classes.text} variant="h6">{t("confirmEmail")}</Typography>
                        <Typography className={classes.text} variant="p">{t("pleaseCheckYourJunkFolder")}</Typography>
                    </Grid>
                    <Grid item xs>
                        <FormControl className={classes.textField}>
                            <InputLabel htmlFor='username-entry'>
                                {t("email")}
                            </InputLabel>
                            <Input id='username-entry'
                                value={values.username}
                                onChange={handleChange('username')} />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <FormControl className={classes.textField}>
                            <InputLabel htmlFor='code-entry'>
                                {t("confirmationCode")}
                            </InputLabel>
                            <Input id='code-entry'
                                value={values.code}
                                onChange={handleChange('code')} />
                        </FormControl>
                    </Grid>
                    <Grid item xs>
                        <Grid container
                            direction='row'
                            justifyContent='flex-end'
                            alignContent='flex-end'
                            alignItems='center'
                            spacing={0}>
                            <Grid item xs={9}>
                                <div className={classes.errorBox}>
                                    <Typography variant="subtitle2" color='error'>{formError}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs>
                                <Button color='secondary'
                                    variant='contained'
                                    onClick={() => handleConfirm()}>
                                    {t("confirm")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid container
                            direction='row'
                            alignItems='flex-start'
                            spacing={0}>
                            <Typography className={classes.text}
                                variant='body2'
                                color='textSecondary'>
                                {t("lostYourCode")}
                            </Typography>
                            <Button className={classes.text}
                                color='secondary'
                                component={Link}
                                onClick={() => handleResendCode()}>
                                {t("resendCode")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Button className={classes.text}
                            color='secondary'
                            component={Link}
                            onClick={() => handleBackToSignIn()}>
                            {t("backToSignIn")}
                        </Button>
                    </Grid>
                </Grid>
                : null}
        </React.Fragment>
    );
}