import { createTheme } from '@mui/material/styles';

export const Theme = createTheme({
    components: {
      MuiSelect: {
        defaultProps: {
          variant: 'standard'
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root:{
            "&.MuiDataGrid-columnsContainer": {
              backgroundColor: '#555555',
              color: '#FFFFFF'
            },
            "&.MuiDataGrid-iconSeparator": {
                //display: 'none',
            },
            "&.MuiDataGrid-sortIcon":{
              color: '#fff'
            } 
          }
        },
        defaultProps: {
          disableColumnMenu: true,
        }
      }
    },
    overrides: {
      MuiInput: {
        input: {
          '&::-ms-reveal': {
            display: 'none'
          },
        },
      },
      MuiDataGrid: {
        root:{
          '& .MuiDataGrid-columnsContainer': {
              backgroundColor: '#555555',
              color: '#FFFFFF'
          },
          '& .MuiDataGrid-iconSeparator': {
              //display: 'none',
          },
          '& .MuiDataGrid-sortIcon':{
            color: '#fff'
          }
        },
      },
    },
    palette: {
      background: {
        default: "#ffffff",
        dark: "#fafafa",
        light: "#ffffff"
      },
      primary: {
        light: '#555555',
        main: '#2c2c2c',
        dark: '#000000',
        contrastText: '#fff',
      },
      secondary: {
        light: '#67daff',
        main: '#03A9F4',
        dark: '#007ac1',
        contrastText: '#fff',
      },
      error: {
        main: '#e4002b', // color for critically low
        dark: '#a6093d', // color for comm errors
        none: '#555555', // no status
      },
      warning: {
        main: '#e87722', // color for low level
      },
      //info: {
      //},
      success: {
        main: '#319b42', // color for OK
      },
    },
  });

export default Theme;
