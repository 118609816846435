import React, { useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
  Tooltip
} from "@mui/material";
import { accounts } from '../../services/accounts';
import { ACCESS } from "../../data.js";
import { PersonAdd } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '38px',
    marginBottom: theme.spacing(2),
  },
  Button: {
    margin: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  HeaderIcon: {
    marginLeft: '5px'
    },
}));

const AccountInvite = ({ accountId, access, inviteComplete=()=>{} }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // Create state for the invites
  const [inviteOpen, setInviteOpen] = useState(false);
  const recipient = useRef("");

  const handleInviteOpen = () => {
    setInviteOpen(true);
  };

  const handleInviteClose = () => {
    setInviteOpen(false);
  };

  // Functions to handle invitations
  const handleInvite = async (permissions) => {
    const l_recipient = recipient.current.value;
    inviteUser(l_recipient, permissions);
    setInviteOpen(false);
  };

  const inviteUser = async (recipient, role) => {
    const l_recipient = recipient.replace(" ","").split(",");
    console.log(l_recipient);

    for (var i=0; i < l_recipient.length; i++){
        try {
            //throw new Error("test - no call");
            const { status, data } = await accounts.inviteUser(
                accountId,
                l_recipient[i],
                role
            );
            
            const { error } = data;

            if (status === 404) {
                return;
            }

            if (error !== 'OK' && status !== 200) {
                if (error.includes("User already has access to this account")){
                    alert(t("userAlreadyHasAccess"));
                }
                else {
                    alert(t("sorryUnableToRetrieve"));
                    throw new Error(error);
                }
            }

            //Any Action?
        } catch (error) {
            console.error(error);
        }
    }
    inviteComplete();
  }
  
  return (
    <div className={classes.root}>
      {(access) &&
          <Tooltip title={t('invite')}>
            <IconButton
              aria-haspopup='true'
              color='inherit'
              className={classes.iconButtons}
              onClick={handleInviteOpen}
              size="large">
              <PersonAdd />
              <Typography className={classes.HeaderIcon}>{t("invite")}</Typography>
            </IconButton>
          </Tooltip>
      }
      <Dialog open={inviteOpen} onClose={handleInviteClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t("invite")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("toInviteAUser")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            inputRef={recipient}
            label={t("emailAddress")}
            type="email"
            fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInviteClose} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={() => handleInvite("Viewer")} variant="contained" color="primary">
            {t("asViewer")}
          </Button>
          <Button onClick={() => handleInvite("Editor")} variant="contained" color="primary">
            {t("asEditor")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AccountInvite;
