import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import fr from './locales/fr/translation.json';
import de from './locales/de/translation.json';

// https://react.i18next.com/legacy-v9/step-by-step-guide
const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  },
  es: {
    translation: es
  },
  de: {
    translation: de
  }
};

i18n
  .use(detector)
  // .debug(true)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available
    keySeparator: false, 
    interpolation: {
      escapeValue: false // prevent xss
    }
  });

export default i18n;