import React from 'react';
import { Authenticator } from 'aws-amplify-react';
import { Typography, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GSignIn from './GSignIn.jsx'
import GSignUp from './GSignUp.jsx'
import GConfirmSignUp from './GConfirmSignUp.jsx'
import GForgotPassword from './GForgotPassword.jsx'

const useStyles = makeStyles((theme) => ({
    wrap: {
        height: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    text: { padding: theme.spacing(1) },
    content: { padding: theme.spacing(3) },
    logo: {
        display: "block",
        // width: '100%',
        maxHeight: '145px',
        // marginRight: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(2)
    }
}));

export default function GAuth({ appTitle, onAuthStateChange, logo, changeLanguage }) {
    const classes = useStyles();

    return (
        <div className={classes.wrap}>
            <Container maxWidth="xs" className={classes.container}>
                {logo && <img edge='start' alt='Graco' className={classes.logo} src={logo} />}
                {!logo && <Typography className={classes.text} color='textPrimary' variant='h4'>
                    {appTitle}
                </Typography>}
                <Authenticator hideDefault={true}
                    onStateChange={onAuthStateChange}>
                    <GSignIn changeLanguage={changeLanguage} />
                    <GSignUp changeLanguage={changeLanguage} />
                    <GForgotPassword />
                    <GConfirmSignUp />
                </Authenticator>
            </Container>
        </div>
    );
}